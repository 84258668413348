import './App.less';
import './assets/font/iconfont.css';
import {PublicContext} from './context';
import React, {useEffect, useReducer} from 'react';
import {HashRouter as Router, Route} from 'react-router-dom';
import {createHashHistory} from 'history';
import {Layout} from 'antd';
import Login from './pages/login/login';
import Home from './pages/home/home';
import MainMenu from './components/menu/menu';
import Header from './components/header/header';
import OrderList from './pages/order/list/list';
import OrderListDetail from './pages/order/list/detail/detail';
import RefundList from './pages/order/refund/refund';
import RefundListDetail from './pages/order/refund/detail/detail';
import MarketAds from './pages/market/ads/ads';
import NewAdsBanner from './pages/market/ads/new_banner/new_banner';
import NewScreenBanner from './pages/market/ads/new_screen/new_screen';
import NewMarketCoupon from './pages/market/coupon/new/new';
import MarketCouponDetail from './pages/market/coupon/detail/detail';
import AuthorityGroup from './pages/setting/authority_group/authority_group';
import MemberFeedback from './pages/member/feedback/feedback';
import AuthorityAssort from './pages/setting/authority_group/authority_assort/authority_assort';
import ProductList from './pages/product/list/list';
import NewProduct from './pages/product/list/new/new';
import ProductClassification from './pages/product/category/category';
import ProductSubcategory from './pages/product/category/subcategory/subcategory';
import NewProductClassification from './pages/product/category/new/new';
import ProductSpecifications from './pages/product/specifications/specifications';
import NewProductSpecifications from './pages/product/specifications/attribute/attribute';
import MemberList from './pages/member/list/list';
import MemberAdmins from './pages/member/admins/admins';
import OrderSetting from './pages/setting/order/order';
import SystemSetting from './pages/setting/system/system';
import SensitiveWordsSetting from './pages/setting/sensitive_words/sensitive_words';
import WatermarkSetting from './pages/setting/watermark/watermark';
import ShopSetting from './pages/setting/shop/shop';
import PactSetting from './pages/setting/pact/pact';
import NoticeContent from './pages/market/notice_content/notice_content';
import MarketCoupon from './pages/market/coupon/coupon';
import ProductComment from './pages/product/comment/comment';
import {HasToken} from './utils/request';
import HomeList from './pages/home/list/list';
import DunfengLogin from './pages/login/dunfeng_login/dunfeng_login';
import _ from "lodash";
import Oss from './pages/setting/oss/oss';

const publicInitData: any = {
    menuRouter: [
        {
            authId: '-1',
            isMenu: false,
            name: '登录',
            route: '/login',
            component: <Login/>
        },
        {
            authId: '-1',
            isMenu: false,
            name: '盾丰登录',
            route: '/dunfeng_login',
            component: <DunfengLogin/>
        },
        {
            authId: '-1',
            isMenu: true,
            name: '首页',
            route: '/home',
            icon: <span className='iconfont iconshouye'/>,
            component: <Home/>,
            children: [
                {
                    authId: '-1',
                    isMenu: false,
                    name: '热搜词',
                    route: '/hot_words_list',
                    component: <HomeList/>
                },
                {
                    authId: '-1',
                    isMenu: false,
                    name: '排行榜',
                    route: '/leaderboards_list',
                    component: <HomeList/>
                }
            ]
        },
        {
            authId: '1',
            isMenu: true,
            name: '商品',
            icon: <span className='iconfont iconshangpin'/>,
            children: [
                {
                    authId: '2',
                    isMenu: true,
                    name: '商品列表',
                    route: '/product_list',
                    component: <ProductList/>,
                    children: [
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '新增商品',
                            route: '/new_product',
                            component: <NewProduct/>
                        },
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '编辑商品',
                            route: '/edit_product',
                            component: <NewProduct/>
                        },
                    ]
                },
                {
                    authId: '3',
                    isMenu: true,
                    name: '商品分类',
                    route: '/product_category',
                    component: <ProductClassification/>,
                    children: [
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '下级分类',
                            route: '/product_subcategory',
                            component: <ProductSubcategory/>
                        },
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '新增分类',
                            route: '/new_product_classification',
                            component: <NewProductClassification/>
                        },
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '编辑分类',
                            route: '/edit_product_classification',
                            component: <NewProductClassification/>
                        },
                    ]
                },
                {
                    authId: '4',
                    isMenu: true,
                    name: '商品规格',
                    route: '/product_specifications',
                    component: <ProductSpecifications/>,
                    children: [
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '新增规格',
                            route: '/new_product_specifications',
                            component: <NewProductSpecifications/>
                        },
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '编辑规格',
                            route: '/edit_product_specifications',
                            component: <NewProductSpecifications/>
                        },
                    ]
                },
                {
                    authId: '5',
                    isMenu: true,
                    name: '商品评论',
                    route: '/product_comment',
                    component: <ProductComment/>
                }
            ]
        },
        {
            authId: '6',
            isMenu: true,
            name: '订单',
            icon: <span className='iconfont icondingdan'/>,
            children: [
                {
                    authId: '7',
                    isMenu: true,
                    name: '订单列表',
                    route: '/order_list',
                    component: <OrderList/>,
                    children: [
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '订单详情',
                            route: '/order_list_detail',
                            component: <OrderListDetail/>
                        }
                    ]
                },
                {
                    authId: '8',
                    isMenu: true,
                    name: '退款申请',
                    route: '/refund_list',
                    component: <RefundList/>,
                    children: [
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '退款详情',
                            route: '/refund_list_detail',
                            component: <RefundListDetail/>
                        }
                    ]
                }
            ]
        },
        {
            authId: '9',
            isMenu: true,
            name: '营销',
            icon: <span className='iconfont iconzhexiantu'/>,
            children: [
                {
                    authId: '10',
                    isMenu: true,
                    name: '广告列表',
                    route: '/market_ads',
                    component: <MarketAds/>,
                    children: [
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '新增广告',
                            route: '/new_ads_banner',
                            component: <NewAdsBanner/>
                        },
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '编辑广告',
                            route: '/edit_ads_banner',
                            component: <NewAdsBanner/>
                        },
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '新增广告',
                            route: '/new_screen_banner',
                            component: <NewScreenBanner/>
                        },
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '编辑广告',
                            route: '/edit_screen_banner',
                            component: <NewScreenBanner/>
                        }
                    ]
                },
                {
                    authId: '11',
                    isMenu: true,
                    name: '消息通知',
                    route: '/notice_content',
                    component: <NoticeContent/>,
                },
                {
                    authId: '12',
                    isMenu: true,
                    name: '优惠券',
                    route: '/market_coupon',
                    component: <MarketCoupon/>,
                    children: [
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '新增优惠券',
                            route: '/new_market_coupon',
                            component: <NewMarketCoupon/>
                        },
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '编辑优惠券',
                            route: '/edit_market_coupon',
                            component: <NewMarketCoupon/>
                        },
                        {
                            authId: '-1',
                            isMenu: false,
                            name: '优惠券详情',
                            route: '/market_coupon_detail',
                            component: <MarketCouponDetail/>
                        }
                    ]
                }
            ]
        },
        {
            authId: '13',
            isMenu: true,
            name: '用户',
            icon: <span className='iconfont icon203yonghu_yonghu4'/>,
            children: [
                {
                    authId: '14',
                    isMenu: true,
                    name: '用户列表',
                    route: '/member_list',
                    component: <MemberList/>
                },
                {
                    authId: '15',
                    isMenu: true,
                    name: '管理员',
                    route: '/member_admins',
                    component: <MemberAdmins/>
                },
                {
                    authId: '16',
                    isMenu: true,
                    name: '意见反馈',
                    route: '/member_feedback',
                    component: <MemberFeedback/>
                }
            ]
        },
        {
            authId: '17',
            isMenu: true,
            name: '设置',
            icon: <span className='iconfont iconshezhi'/>,
            children: [
                {
                    authId: '18',
                    isMenu: true,
                    name: '协议设置',
                    route: '/pact_setting',
                    component: <PactSetting/>
                },
                {
                    authId: '19',
                    isMenu: true,
                    name: '门店设置',
                    route: '/shop_setting',
                    component: <ShopSetting/>
                },
                {
                    authId: '20',
                    isMenu: true,
                    name: '水印设置',
                    route: '/watermark_setting',
                    component: <WatermarkSetting/>
                },
                {
                    authId: '21',
                    isMenu: true,
                    name: '敏感词设置',
                    route: '/sensitive_words_setting',
                    component: <SensitiveWordsSetting/>
                },
                {
                    authId: '22',
                    isMenu: true,
                    name: '订单设置',
                    route: '/order_setting',
                    component: <OrderSetting/>
                },
                {
                    authId: '23',
                    isMenu: true,
                    name: '角色列表',
                    route: '/authority_group',
                    component: <AuthorityGroup/>,
                    children: [
                        {
                            authId: '-1',
                            isMenu: true,
                            name: '权限分配',
                            route: '/authority_assort/:id',
                            component: <AuthorityAssort/>
                        }
                    ]
                },
                {
                    authId: '24',
                    isMenu: true,
                    name: '系统设置',
                    route: '/system_setting',
                    component: <SystemSetting/>
                },
                {
                    authId: '24',
                    isMenu: true,
                    name: 'OSS存储',
                    route: '/oss_setting',
                    component: <Oss/>
                }
            ]
        }
    ],
    authList: ['-1'],
    imgDomain: '',
    loggedIn: false
};

const publicReducer = (state: typeof publicInitData, action: IAction) => {
    switch (action.type) {
        case 'setMenuRouter':
            return Object.assign({}, state, {menuRouter: action.value});
        case 'setAuthList':
            state.authList = action.value;
            return _.cloneDeep(state);
        case 'setImgDomain':
            return Object.assign({}, state, {imgDomain: action.value});
        case 'setLoggedIn':
            return Object.assign({}, state, {loggedIn: action.value});
        default:
            return Object.create(state);
    }
};

const App = () => {
    //绝大部分的全局共享状态由此处管理
    const [publicData, publicDispatch] = useReducer(publicReducer, publicInitData);

    //deps数组中有依赖值时，每次组件重新渲染后检测依赖值是否改变，如果改变则执行副作用
    useEffect(() => {
        //token不存在时跳转登录
        if (!HasToken()||!localStorage.getItem('divms_auth')) {
            publicDispatch({
                type: 'setLoggedIn',
                value: false
            });
            createHashHistory().replace('/login');
        } else {
            publicDispatch({
                type: 'setImgDomain',
                value: localStorage.getItem('divms_url')
            });
            publicDispatch({
                type: 'setLoggedIn',
                value: true
            });
            publicDispatch({
                type: 'setAuthList',
                value: localStorage.getItem('divms_auth')!.split(',')
            });
        }
    }, []);

    return (
        <PublicContext.Provider value={{publicData, publicDispatch}}>
            <Layout>
                {
                    publicData.loggedIn &&
                    <Layout.Sider style={{
                        position: 'fixed',
                        zIndex: 100,
                        height: '100%',
                        overflowY: 'scroll',
                        overflowX: 'hidden'
                    }}>
                        <MainMenu/>
                    </Layout.Sider>
                }
                <Layout>
                    <Router>
                        {
                            publicData.loggedIn &&
                            <Layout.Header style={{position: 'fixed', zIndex: 99, width: '100%', left: 0}}>
                                <Header/>
                            </Layout.Header>
                        }
                        <Layout.Content style={{paddingLeft: '200px', paddingTop: '48px'}}>
                            {
                                publicData.menuRouter.map((res: any) => {
                                    return (
                                        <React.Fragment key={res.name}>
                                            {
                                                (publicData.authList.indexOf(res.authId) >= 0 && res.component) &&
                                                <Route key={res.route} path={res.route}>
                                                    {res.component}
                                                </Route>
                                            }
                                            {
                                                res.children &&
                                                res.children.map((res: any) => {
                                                    return (
                                                        <React.Fragment key={res.name}>
                                                            {
                                                                (publicData.authList.indexOf(res.authId) >= 0 && res.component) &&
                                                                <Route key={res.route} path={res.route}>
                                                                    {res.component}
                                                                </Route>
                                                            }
                                                            {
                                                                res.children &&
                                                                res.children.map((res: any) => {
                                                                    return (
                                                                        publicData.authList.indexOf(res.authId) >= 0 &&
                                                                        <Route key={res.route} path={res.route}>
                                                                            {res.component}
                                                                        </Route>
                                                                    );
                                                                })
                                                            }
                                                        </React.Fragment>
                                                    );
                                                })
                                            }
                                        </React.Fragment>
                                    );
                                })
                            }
                            <Route exact path="/">
                                <Home/>
                            </Route>
                        </Layout.Content>
                    </Router>
                </Layout>
            </Layout>
        </PublicContext.Provider>
    );
};
export default App;
