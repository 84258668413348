import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Form, Input, Modal, Table, message, Button, Space, Divider} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import TableHead from '../../../components/table_head/table_head';
import CustomPagination from '../../../components/custom_pagination/custom_pagination';
import {
    getSpecificationsCategoryList,
    addSpecificationsCategory,
    delSpecificationsCategory
} from '../../../service/product.api';


//单页数据量
const PAGE_SIZE = 20;

const EditableCell: React.FC<any> = ({editing, children}) => {
    return (
        <td>
            {editing ? (
                <Form.Item
                    name="name"
                    style={{margin: 0}}
                    rules={[
                        {
                            required: true,
                            message: '请输入规格名称',
                        },
                    ]}
                >
                    <Input style={{width: '100%'}}/>
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const ProductSpecifications = () => {
    const [addForm] = Form.useForm();
    const [form] = Form.useForm();
    //表格数据
    const [tableData, setTableData] = useState<Array<any>>([]);
    //表格列
    const columns: Array<any> = [
        {
            title: '规格名称',
            dataIndex: 'name',
            key: 'id',
            editable: true,
            onCell: (record: any) => ({
                record,
                dataIndex: 'name',
                title: '规格名称',
                editing: record.id === editingKey,
            }),

        },
        {
            title: '属性数量',
            dataIndex: 'attribute_count',
            key: 'id',
            width: 140
        },
        {
            title: '操作',
            dataIndex: 'action',
            key: 'action',
            width: 260,
            render: (text: string, record: any) => {
                return record.id === editingKey ? (
                    <Space size={'small'}>
                        <Link to={{pathname: '/new_product_specifications', search: `?id=${record.id}`}}>属性</Link>
                        <Divider type="vertical"/>
                        <a onClick={() => showDeleteConfirm(record)}> 删除</a>
                        <Divider type="vertical"/>
                        <a onClick={() => save(record)}> 保存</a>
                        <Divider type="vertical"/>
                        <a onClick={cancel}> 取消</a>
                    </Space>
                ) : (
                    <Space size={'small'}>
                        <Link to={{pathname: '/new_product_specifications', search: `?id=${record.id}`}}>属性</Link>
                        <Divider type="vertical"/>
                        <a onClick={() => edit(record)}>编辑</a>
                        <Divider type="vertical"/>
                        <a onClick={() => showDeleteConfirm(record)}> 删除</a>
                    </Space>
                );
            }
        }
    ];
    //当前编辑的行key(id)
    const [editingKey, setEditingKey] = useState('');
    //当前页码
    const [currentPage, setCurrentPage] = useState(1);
    //数据总数
    const [count, setCount] = useState(0);
    //搜索内容
    const [searchContent, setSearchContent] = useState('');

    //新增Modal框控制
    const [addVisible, setAddVisible] = useState(false);

    const [confirmLoading, setConfirmLoading] = useState(false);

    useEffect(() => {
        if (currentPage === 1) getTableData().then();
        else setCurrentPage(1);
    }, [searchContent]);

    useEffect(() => {
        getTableData().then();
    }, [currentPage]);

    /**
     * 编辑事件
     * @param record
     */
    const edit = (record: any) => {
        console.log(record);
        form.setFieldsValue({name: '', ...record});
        setEditingKey(record.id);
    };
    /**
     * 取消编辑
     */
    const cancel = () => {
        setEditingKey('');
    };
    /**
     * 获取表格数据
     */
    const getTableData = async () => {
        const params: {} = {
            size: PAGE_SIZE,
            page: currentPage,
            kwd: searchContent
        };
        try {
            const req: any = await getSpecificationsCategoryList(params);
            const {result} = req;
            const {data, count} = result;
            setCount(count);
            setTableData(data || []);
        } catch (e) {
            console.log(e);
        }
    };
    /**
     * 新增规格
     */
    const handlerAdd = async () => {
        try {
            await addForm.validateFields(['name']);
            const name = addForm.getFieldsValue(['name']);
            const req = await addSpecificationsCategory(name);
            setAddVisible(false);
            message.success('添加成功');
            setCurrentPage(1);
            await getTableData();
        } catch (e) {
            console.log(e);
        }
    };
    /**
     * 保存编辑
     */
    const save = async (record: any) => {
        try {
            const row = await form.validateFields(['name']);
            const {code}: any = await addSpecificationsCategory({...record, ...row});
            if (code !== -1) {
                const newData = [...tableData];
                const index = newData.findIndex(item => record.id === item.id);
                if (index > -1) {
                    const item = newData[index];
                    newData.splice(index, 1, {
                        ...item,
                        ...row,
                    });
                    setTableData(newData);
                    setEditingKey('');
                } else {
                    newData.push(row);
                    setTableData(newData);
                    setEditingKey('');
                }
            }
        } catch (errInfo) {
            console.log('编辑异常', errInfo);
        }
    };
    /**
     * 删除商品的确认框(Modal框)
     * @params records
     */
    const showDeleteConfirm = (records: { id: number | string }): void => {
        Modal.confirm({
            content: '确认删除该规格吗?',
            icon: <ExclamationCircleOutlined/>,
            centered: true,
            bodyStyle: {
                fontSize: 16,
            },
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk: () => {
                delSpecification(records);
            }
        });
    };
    /**
     * 删除规格
     * @param records
     */
    const delSpecification = async (records: { id: number | string }) => {
        try {
            const {id} = records;
            await delSpecificationsCategory({id});
            message.success('删除成功');
            await getTableData();
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <div className="content_container">
            <Modal
                title="新增规格"
                visible={addVisible}
                onOk={handlerAdd}
                onCancel={() => {
                    setAddVisible(false);
                }}
                okText="确认"
                cancelText="取消"
                confirmLoading={confirmLoading}
                centered={true}
            >
                <Form
                    form={addForm}
                    name="addForm"
                    labelCol={{span: 6}}
                    labelAlign={'left'}
                    wrapperCol={{span: 18}}
                    colon={true}
                >
                    <Form.Item
                        label="规格名称"
                        name="name"
                        required={true}
                    >
                        <Input placeholder="请输入规格名称"/>
                    </Form.Item>
                </Form>

            </Modal>
            <div className={'main_wrap'}>
                <div className={'main_container'}>
                    <TableHead onSearch={(content: string) => setSearchContent(content)} addType="新增规格"
                               onClick={() => setAddVisible(true)}/>
                    <Form form={form} component={false}>
                        <Table
                            components={{
                                body: {
                                    cell: EditableCell,
                                },
                            }}
                            columns={columns}
                            dataSource={tableData}
                            pagination={false}
                            scroll={{y: 680, x: 1000}}
                        />
                    </Form>
                </div>
                <CustomPagination
                    currentPage={currentPage}
                    total={count}
                    onChange={(page: number) => setCurrentPage(page)}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </div>
    );
};
export default ProductSpecifications;
