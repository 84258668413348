import './authority_assort.less';
import {Button, Checkbox, message, Space, Tabs, Tree} from 'antd';
import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {getAssortApi, updateAssortApi} from "../../../../service/setting/authority_group.api";

const {TabPane} = Tabs;
const AuthorityAssort = () => {
    const {id} = useParams<any>()
    const history = useHistory()
    const [treeData, setTreeData] = useState<Array<any>>([])
    const [checkedKeys, setCheckedKeys] = useState<React.Key[]>([]);
    const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
    const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
    const [tabKey, setTabKey] = useState<string>('1');

    useEffect(() => {
        getAssort()
    }, [])
    const getAssort = () => {
        getAssortApi(id).then((res: any) => {
            let ownKeys: React.Key[] = []
            let expandKeys: React.Key[] = []
            res = res.result.map((e: any) => {
                // if (e.own) {
                //     ownKeys.push(e.id)
                // }
                expandKeys.push(e.id)
                return {
                    key: e.id,
                    title: e.name,
                    children: e.child.map((e: any) => {
                        if (e.own) {
                            ownKeys.push(e.id)
                        }
                        return {
                            key: e.id,
                            title: e.name,
                        }
                    })
                }
            })
            setTreeData(res)
            setCheckedKeys(ownKeys)
            setExpandedKeys(expandKeys)
        })
    }
    const tabChange = (e: any) => {
        setTabKey(e);
    };

    const onExpand = (expandedKeysValue: React.Key[]) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onCheck = (checkedKeysValue: any) => {
        setCheckedKeys(checkedKeysValue);
    };

    const saveAssort = () => {
        let keys = checkedKeys
        treeData.forEach((e: any) => {
            let checked = false
            e.children.forEach((e: any) => {
                if (keys.includes(e.key)) {
                    checked = true
                }
            })
            if (checked) {
                keys.push(e.key)
            }
        })
        updateAssortApi(Number(id), keys).then(e => {
            message.success('保存成功')
        })
    }
    return (
        <div className="content_container">
            <div className={'main_wrap'}>
                <div className={'main_container'}>
                    <div className={'assort_container'}>
                        <Tabs defaultActiveKey="1" onChange={tabChange}>
                            <TabPane tab="菜单权限" key="1"/>
                            {/*<TabPane tab="资源权限" key="2"/>*/}
                        </Tabs>
                        {
                            tabKey == '1' ?
                                <div style={{marginTop: 16, marginBottom: 100}}>
                                    <Tree
                                        checkable
                                        onExpand={onExpand}
                                        expandedKeys={expandedKeys}
                                        autoExpandParent={autoExpandParent}
                                        onCheck={onCheck}
                                        checkedKeys={checkedKeys}
                                        selectedKeys={[]}
                                        treeData={treeData}
                                    />
                                </div> :
                                <div style={{marginTop: 16, marginBottom: 100}}>
                                    <div className="source_list">
                                        <div className="source_list_item">
                                            <Checkbox>商品模块</Checkbox>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </div>
            <div className={'add_footer'}>
                <Space>
                    <Button onClick={() => {
                        history.goBack()
                    }}>取消</Button>
                    <Button type={'primary'} onClick={saveAssort}>保存</Button>
                </Space>
            </div>
        </div>
    );
};
export default AuthorityAssort;
