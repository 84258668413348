import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Button, Divider, message, Space, Table} from 'antd';
import moment from 'moment';
import {getCoupon, delCoupon} from '../../../service/market/coupon.api';
import TableHead from '../../../components/table_head/table_head';
import CustomPagination from '../../../components/custom_pagination/custom_pagination';
import ConfirmDelete from '../../../components/confirm_delete/confirm_delete';
import {getCouponState, formatPrice} from '../../../utils';
import './coupon.less';

const PAGE_SIZE = 20;
const MarketCoupon = () => {
    const [searchContent, setSearchContent] = useState('');
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    //表格(商品)数据
    const [tableData, setTableData] = useState([]);
    //商品列表columns信息
    const columns = [
        {
            title: '优惠券名称',
            dataIndex: 'name',
        },
        {
            title: '可用范围',
            dataIndex: 'use_type',
            width: 180,
            render: (text: string | number) => text === 1 ? '全场通用' : '指定商品'
        },
        {
            title: '面值（¥）',
            dataIndex: 'amount',
            width: 140,
            render:(text:any) => (formatPrice(text)),
        },
        {
            title: '有效期',
            width: 240,
            render: (text: string, record: any) => {
                const {start_time, end_time} = record;
                return `${moment(start_time).format('YYYY-MM-DD')} 至 
                 ${moment(end_time).format('YYYY-MM-DD')}`;
            }
        },
        {
            title: '状态',
            key: 'id',
            width: 140,
            render: (text: string, record: any) => {
                const {start_time, end_time} = record;
                return getCouponState(start_time, end_time);
            }
        },
        {
            title: '操作',
            key: 'action',
            width: 200,
            render: (text: any, record: any) => (
                <Space size="small">
                    <Link to={{
                        pathname: '/market_coupon_detail',
                        search: `data=${encodeURIComponent(JSON.stringify(record))}`
                    }}>查看</Link>
                    <Divider type="vertical"/>
                    <Link to={{
                        pathname: '/edit_market_coupon',
                        search: `data=${encodeURIComponent(JSON.stringify(record))}`
                    }}>
                        编辑
                    </Link>
                    <Divider type="vertical"/>
                    <a onClick={() => ConfirmDelete(record, '确认删除该优惠券吗', deleteCoupon)}>删除</a>
                </Space>
            ),
        },
    ];

    useEffect(() => {
        getCouponList().then();
    }, [page,searchContent]);

    const getCouponList = async () => {
        try {
            const {result: {data, count}}: any = await getCoupon({
                kwd: searchContent,
                page,
                size: PAGE_SIZE
            });
            setTableData(data);
            setCount(count);
        } catch (e) {
            console.log(e);
        }
    };
    const onSearch = async (content: string) => {
        setSearchContent(content);
        setPage(1);
        //await getCouponList();
    };
    /**
     * 删除优惠券
     */
    const deleteCoupon = async (record: any) => {
        const {id} = record;
        try {
            await delCoupon({id});
            message.success('优惠券删除成功');
            await getCouponList();
        } catch (e) {
            console.log(e);
            message.error('优惠券删除失败');
        }
    };
    return (
        <div className="content_container">
            <div className={'main_wrap'}>
                <div className={'main_container'}>
                    <TableHead
                        onSearch={onSearch}
                        addType="新增优惠券"
                        url="/new_market_coupon"
                    />
                    <div className={'table_wrap'}>
                        <Table
                            dataSource={tableData}
                            columns={columns}
                            pagination={false}
                            scroll={{y: 680}}
                        />
                    </div>
                </div>
                <CustomPagination
                    currentPage={page}
                    total={count}
                    onChange={(page: number) => setPage(page)}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </div>
    );
};
export default MarketCoupon;
