import React, {useContext, useEffect, useState} from 'react';
import {Button, Col, DatePicker, Image, Input, message, Modal, Row, Table} from 'antd';
import CustomPagination from '../../../components/custom_pagination/custom_pagination';
import ConfirmDelete from '../../../components/confirm_delete/confirm_delete';
import {getCommentList, delComment} from '../../../service/product.api';
import {PublicContext} from '../../../context';
import moment from 'moment';

const {Search} = Input;
const {RangePicker} = DatePicker;
const PAGE_SIZE = 20;

const ProductComment = () => {
    const {publicData} = useContext(PublicContext);
    const {imgDomain} = publicData;
    const [visible, setVisible] = useState(false);
    const [preViewUrls, setPreviewUrls] = useState<Array<any>>([]);

    const [searchContent, setSearchContent] = useState<string>('');
    const [startTime, setStartTime] = useState<number>(0);
    const [endTime, setEndTime] = useState<number>(0);
    //当前页码
    const [currentPage, setCurrentPage] = useState<number>(1);
    //总页数总数
    const [count, setCount] = useState<number>();
    const [tableData, setTableData] = useState<any>([]);
    const columns: Array<any> = [
        // {
        //     title: 'ID',
        //     dataIndex: 'id',
        //     key: 'id',
        //     width: 80,
        //     fixed: 'left',
        // },
        {
            title: '评分',
            dataIndex: 'star',
            key: 'id',
            width: 120
        },
        {
            title: '评论内容',
            dataIndex: 'content',
            key: 'id',
        },
        {
            title: '图片',
            dataIndex: 'pics',
            key: 'id',
            render: (text: Array<any>) => (
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {
                        text.map(res => {
                            return (
                                <div style={{margin: 2}}>
                                    <Image
                                        // preview={{visible: false}}
                                        width={54}
                                        height={54}
                                        src={imgDomain + res}
                                        // onClick={() => {
                                        //     setPreviewUrls(text);
                                        //     setVisible(true);
                                        // }}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
            )
        },
        {
            title: '评论时间',
            dataIndex: 'created_at',
            key: 'id',
            width: 180,
            render: (text: any, record: any) => moment(record.created_at * 1000).format('YYYY-MM-DD HH:mm:ss')
        },
        {
            title: '所属商品',
            dataIndex: 'product_name',
            key: 'id'
        },
        {
            title: '评论用户',
            dataIndex: 'member_nick_name',
            key: 'id'
        },
        {
            title: '操作',
            key: 'action',
            width: 120,
            fixed: 'right',
            render: (text: any, record: any) =>
                <a onClick={() => ConfirmDelete(record, '确认删除该评论吗', handlerDel)}>删除</a>
        },
    ];
    useEffect(() => {
        console.log(1212);
        getList().then();
    }, [currentPage, searchContent, startTime, endTime]);

    const getList = async () => {
        try {
            const {result: {count, data}}: any = await getCommentList({
                start: startTime,
                end: endTime,
                kwd: searchContent,
                page: currentPage,
                size: PAGE_SIZE
            });
            setTableData(data);
            setCount(count);
        } catch (e) {
            console.log(e);
        }
    };
    /**
     * 搜索
     */
    const onSearch = async (searchContent: string) => {
        setSearchContent(searchContent);
        setCurrentPage(1);
        //await getList();
    };
    /**
     * 选择时间
     */
    const selectTime = async (data: any) => {
        if (!data || data.length === 0) {
            setStartTime(0);
            setEndTime(0);
            return;
        }
        const [start, end] = data;
        setStartTime(moment(start.toString()).unix());
        setEndTime(moment(end.toString()).unix());
    };
    /**
     * 删除评论
     */
    const handlerDel = async (records: any) => {
        try {
            const {id} = records;
            await delComment({id});
            message.success('删除成功');
            await getList();
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <div className="content_container">
            <div style={{display: 'none'}}>
                <Image.PreviewGroup preview={{visible, onVisibleChange: vis => setVisible(vis)}}>
                    {
                        preViewUrls.map(res => {
                            return (<Image src={imgDomain + res}/>);
                        })
                    }
                </Image.PreviewGroup>
            </div>
            <div className="main_wrap">
                <div className="main_container">
                    <Row style={{margin: '16px 0'}}>
                        <Col span={8}>
                            <RangePicker
                                style={{marginRight: '20px'}}
                                showTime
                                onChange={selectTime}
                            />
                        </Col>
                        <Col span={12} offset={4}>
                            <Search
                                placeholder="输入评论内容、商品名称、评论用户进行搜索"
                                onSearch={onSearch}
                                enterButton="搜索"
                                allowClear
                            />
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        pagination={false}
                        scroll={{x: 1000, y: 680}}
                    />
                </div>
                <CustomPagination
                    currentPage={currentPage}
                    total={count}
                    onChange={(page: number) => setCurrentPage(page)}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </div>
    );
};
export default ProductComment;
