import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Col, Form, Modal, Row, Steps, Table, Input, Select, message, Descriptions} from 'antd';
import moment from 'moment';
import {remark, changeAddress, orderDetail} from '../../../../service/order/list';
import {OrderDetailContext} from './detail';
import SendModal from '../components/send_modal';
import {map} from '../components/order_item';
import RegionSelect from '../../../../components/region_select/regionSelect';
import './detail.less';
import {formatPrice} from '../../../../utils';

const {TextArea} = Input;
const {Option} = Select;
const UI = () => {
    const history = useHistory();
    const {orderData, orderDispatch} = useContext(OrderDetailContext);
    const [actionForm] = Form.useForm();
    const {detailInfo, actionTable, currentStep} = orderData;
    const {
        status,
        id,
        list,
        pay_amount,
        total_amount,
        coupon_amount,
        discount_amount,
        delivery_amount,
        pay_type,
        bill: {numbers, company},
        order_item,
        nickname
    } = detailInfo;
    const product = order_item[0];
    const [visible, setVisible] = useState(false);
    const [title, setTitle] = useState<string>();
    const [sendVisible, setSendVisible] = useState<boolean>(false);
    const [actionType, setActionType] = useState(1);//0备注订单1修改收货地址2发货
    const [receiver_province, setReceiver_province] = useState<string>();
    const [receiver_city, setReceiver_city] = useState<string>();
    const [receiver_region, setReceiver_region] = useState<string>();
    const [cities, setCities] = useState<Array<any>>([]);
    const [counties, setCounties] = useState<Array<any>>([]);
    const columns = [
        {
            title: '操作人',
            dataIndex: '',
            key: 'id'
        },
        {
            title: '操作时间',
            dataIndex: '',
            key: 'id',
        },
        {
            title: '商品状态',
            dataIndex: '',
            key: 'id',
        },
        {
            title: '商品名称',
            dataIndex: '',
            key: 'id'
        },
        {
            title: '备注',
            dataIndex: '',
            key: 'id'
        },
    ];
    const openModal = (type: number) => {
        setActionType(type);
        setVisible(true);
    };
    const ok = async () => {
        try {
            if (!actionType) {
                //备注
                const note = actionForm.getFieldValue('remark');
                await remark({id, note});
                message.success('备注成功');
            } else {
                //修改收货地址
                const {consignee, detailAdd, phone} = actionForm.getFieldsValue();
                await changeAddress({
                    order_id: id,
                    receiver_name: consignee,
                    receiver_phone: phone,
                    receiver_province: receiver_province,
                    receiver_city: receiver_city,
                    receiver_region: receiver_region,
                    receiver_detail_address: detailAdd,
                });
                message.success('修改成功');
                const {result}: any = await orderDetail({id});
                orderDispatch({
                    type: 'setDetail',
                    value: result
                });
            }
            setVisible(false);
        } catch (e) {
            console.log(e);
        }
    };

    const cancel = () => {
        actionForm.resetFields();
        setVisible(false);
    };
    const regionSelectChange = (value: [string, string, string]) => {
        const province = value[0];
        const city = value[1];
        const region = value[2];
        setReceiver_province(province);
        setReceiver_city(city);
        setReceiver_region(region);
    };
    useEffect(() => {
        if (visible) {
            actionForm.setFieldsValue({
                consignee: product.receiver_name,
                phone: product.receiver_phone,
                detailAdd: product.receiver_detail_address,
            });
            setReceiver_province(product.receiver_province);
            setReceiver_city(product.receiver_city);
            setReceiver_region(product.receiver_region);
        }
    }, [visible]);
    // useEffect(() => {
    //     if (cities && cities.length !== 0) {
    //         setReceiver_city(cities[0].name);
    //     }
    // }, [cities]);
    // useEffect(() => {
    //     if (counties && counties.length !== 0) {
    //         setReceiver_region(counties[0].name);
    //     }
    // }, [counties]);
    // useEffect(() => {
    //     const {city} = Regions;
    //     const cities = city.filter(city => city.p_name === receiver_province);
    //     setCities(cities);
    // }, [receiver_province]);
    // useEffect(() => {
    //     const {county} = Regions;
    //     const counties = county.filter(county => county.c_name === receiver_city);
    //     setCounties(counties);
    // }, [receiver_city]);
    return (
        <div className={'add_body_wrap'}>
            <div className={'add_body'}>
                <div className={'order_detail_wrap'}>
                    <div className={'order_step_wrap'}>
                        <Steps current={currentStep}>
                            {/*description="This is a description."*/}
                            {/*description="This is a description."*/}
                            {/*description="This is a description."*/}
                            {/*description="This is a description."*/}
                            {/*description="This is a description."*/}
                            <Steps.Step title="待支付"/>
                            <Steps.Step title="待发货"/>
                            <Steps.Step title="待收货"/>
                            <Steps.Step title="已签收"/>
                            <Steps.Step title="已评价"/>
                        </Steps>
                    </div>
                    <Row style={{minWidth: 900, display: 'flex', justifyContent: 'flex-end'}}>
                        {
                            currentStep === 0 ?
                                <span style={{marginTop: 10, marginRight: 10}}>
                                    <Button type={'primary'} onClick={() => openModal(1)}>修改收货地址</Button>
                                </span>
                                : currentStep === 1 ?
                                    <Fragment>
                                        {/*<span style={{marginTop: 10, marginRight: 10}}>*/}
                                        {/*    <Button type={'primary'} onClick={() => openModal(0)}>备注订单</Button>*/}
                                        {/*</span>*/}
                                        <span style={{marginTop: 10, marginRight: 10}}>
                                    <Button type={'primary'} onClick={() => openModal(1)}>修改收货地址</Button>
                                    </span>
                                        <span style={{marginTop: 10}}>
                                        <Button type={'primary'} onClick={() => {
                                            setActionType(2);
                                            setSendVisible(true);
                                        }}>发货</Button>
                                    </span>
                                    </Fragment>

                                    : null
                            // <span style={{marginTop: 10, marginRight: 10}}>
                            // <Button type={'primary'} onClick={() => openModal(0)}>备注订单</Button>
                            // </span>
                        }
                        <Descriptions title="订单信息"
                                      style={{border: '1px solid #E8E8E8', padding: '20px', margin: '10px 0'}}>
                            <Descriptions.Item label="订单编号">{id}</Descriptions.Item>
                            <Descriptions.Item label="中文抬头">{company ? company : '未填写'}</Descriptions.Item>
                            <Descriptions.Item label="下单用户">{nickname}</Descriptions.Item>
                            <Descriptions.Item label="纳税人识别号">{numbers ? numbers : '未填写'}</Descriptions.Item>
                            <Descriptions.Item
                                label="支付方式">{pay_type === 0 ? '未设置' : pay_type === 1 ? '支付宝' : '微信支付'}</Descriptions.Item>
                        </Descriptions>
                        <Descriptions title="收货人信息"
                                      style={{border: '1px solid #E8E8E8', padding: '20px', margin: '10px 0'}}>
                            <Descriptions.Item label="收件人">{product.receiver_name}</Descriptions.Item>
                            <Descriptions.Item
                                label="所在地区">{product.receiver_province + product.receiver_city + product.receiver_region}</Descriptions.Item>
                            <Descriptions.Item label="手机号码">{product.receiver_phone}</Descriptions.Item>
                            <Descriptions.Item label="详细地址">{product.receiver_detail_address}</Descriptions.Item>
                        </Descriptions>
                        <Col span={24} style={{marginTop: 10, textIndent: 20, fontSize: '16px', fontWeight: 'bold'}}>
                            商品信息
                        </Col>
                        <Col span={24} className={'order_info'} style={{marginBottom: 100}}>

                            {
                                order_item && order_item.map((item: any) => {
                                    const {
                                        product_pic,
                                        product_name,
                                        sp_data,
                                        product_quantity,
                                        product_price,
                                        send_time,
                                        status
                                    } = item;
                                    return (
                                        <div className={'detail'}>
                                            <img
                                                src={localStorage.getItem('divms_url') + product_pic}
                                                className={'picture'}
                                            />
                                            <div className="name">
                                                <div>
                                                    {product_name}
                                                </div>
                                                <div>
                                                    {sp_data && sp_data.map((sp: any, index: number) => (
                                                        <span>{sp.value}{index !== sp_data.length - 1 && '、'}</span>
                                                    ))}
                                                </div>
                                                {
                                                    (status !== 0 && status !== 10 && status !== 60) &&
                                                    <div>
                                                        <span>发货时间：</span>
                                                        <span>{moment.unix(send_time).format('YYYY-MM-DD HH:mm:ss')}</span>
                                                    </div>
                                                }
                                            </div>
                                            <div className="text">¥{formatPrice(product_price)}</div>
                                            <div className="text">{product_quantity}</div>
                                            <div className="text">{map.get(status)}</div>
                                        </div>
                                    );
                                })
                            }
                            <Row style={{padding: 10, color: 'rgba(0, 0, 0, 0.65)', textAlign: 'right'}}>
                                <Col span={4} offset={16}>
                                    <p>商品合计</p>
                                    <p>运费</p>
                                    <p>优惠券</p>
                                    <p>限时促销</p>
                                    <p>实际付款</p>
                                </Col>
                                <Col span={4}>
                                    <p>¥{formatPrice(total_amount)}</p>
                                    <p>{delivery_amount === 0 ? '包邮' : `￥${delivery_amount}`}</p>
                                    <p>- ¥{formatPrice(coupon_amount)}</p>
                                    <p>- ¥{formatPrice(discount_amount)}</p>
                                    <p>¥{formatPrice(pay_amount)}</p>
                                </Col>
                            </Row>
                        </Col>

                        {/*<Col span={24} style={{marginTop: 10, textIndent: 20, fontSize: '16px', fontWeight: 'bold'}}>*/}
                        {/*    操作信息*/}
                        {/*</Col>*/}
                        {/*<Col span={24} style={{padding: '10px 0'}}>*/}
                        {/*    <Table dataSource={actionTable} columns={columns} pagination={false}/>*/}
                        {/*</Col>*/}
                    </Row>
                </div>
            </div>
            {
                actionType === 2 ?
                    <SendModal
                        sendVisible={sendVisible}
                        setSendVisible={setSendVisible}
                        list={order_item}
                        getOrderList={() => history.replace('/order_list')}
                        orderId={id}
                    />
                    :
                    <Modal
                        title={actionType === 0 ? '备注订单' : '修改收货地址'}
                        visible={visible}
                        onOk={ok}
                        onCancel={cancel}
                        okText="确认"
                        cancelText="取消"
                        centered={true}
                    >
                        <Form
                            style={{marginTop: 30}}
                            form={actionForm}
                            name="actionForm"
                            labelCol={{span: 4}}
                            labelAlign={'left'}
                            wrapperCol={{span: 20}}
                            colon={true}
                        >
                            {
                                actionType === 0 ?
                                    <Form.Item
                                        label={'备注'}
                                        name={'remark'}
                                        // rules={[{required: true, message: '请输入广告位'}]}
                                    >
                                        <TextArea rows={5}/>
                                    </Form.Item>
                                    :
                                    <Fragment>
                                        <Form.Item
                                            label={'收件人'}
                                            name={'consignee'}
                                            // rules={[{required: true, message: '请输入广告位'}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item
                                            label={'手机号码'}
                                            name={'phone'}
                                        >
                                            <Input/>
                                        </Form.Item>
                                        <Form.Item
                                            label={'所在地区'}
                                            name={'region'}
                                            labelCol={{span: 4}}
                                        >
                                            <RegionSelect
                                                province={receiver_province}
                                                city={receiver_city}
                                                county={receiver_region}
                                                provinceChange={(value: string) => setReceiver_province(value)}
                                                cityChange={(value: string) => setReceiver_city(value)}
                                                countyChange={(value: string) => setReceiver_region(value)}
                                                // onChange={regionSelectChange}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={'所在地址'}
                                            name={'detailAdd'}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Fragment>
                            }
                        </Form>
                    </Modal>
            }
        </div>
    );
};
export default UI;
