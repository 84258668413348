import React, {useContext} from 'react'
import {Button, Checkbox, Col, Input, Row, Space, Switch, Upload} from 'antd';
import {API_URL} from "../../../utils/request";
import UploadImgButton from "../../../components/upload_Img_button/upload_Img_button";
import {SettingContext} from "./system";

const PayOption = ()=>{
    const {settingData, settingDispatch,handlePreview,save} = useContext(SettingContext);
    const {
        alipaySwitch,
        alipayId,
        alipaySecret,
        alipayCallBack,
        alipayAppCer,
        alipayCer,
        alipayRootCer,
        alipayPrivateKey,
        wechatSwitch,
        wechatId,
        wechatSecret,
        wechatAppId,
        wechatAPPSecret,
        wechatShopNum,
        wechatCerNum,
        wechatAPIv3,
        wechatCallBack,
        wechatP12,
        wechatCerPem,
        wechatKeyPem
    } = settingData
    return(
        <div className='form_wrap' style={{paddingBottom:200}}>
            <div className={'title'} style={{marginBottom:20}}>
                <Checkbox
                    checked={alipaySwitch}
                    onChange={(e)=> settingDispatch({type:'setAlipaySwitch',value:e.target.checked})}
                >支付宝</Checkbox>
            </div>
            <div className={'setting_label'}><span>*</span>Appid</div>
            <Input
                value={alipayId}
                onChange={(e)=>settingDispatch({type:'setAlipayId',value:e.target.value})}
            />
            <div className={'setting_label'}><span>*</span>Secret</div>
            <Input
                value={alipaySecret}
                onChange={(e)=>settingDispatch({type:'setAlipaySecret',value:e.target.value})}
            />
            <div className={'setting_label'}><span>*</span>回调地址</div>
            <Input
                value={alipayCallBack}
                onChange={(e)=>settingDispatch({type:'setAlipayCallBack',value:e.target.value})}
            />
            <div className={'setting_label'}><span>*</span>证书文件</div>
            <div className={'cer_wrap'}>
                <Upload
                    name="picture"
                    accept="image/*"
                    action={`${API_URL}/api/v1/file/upload`}
                    listType="picture-card"
                    fileList={alipayAppCer}
                    onPreview={handlePreview}
                    onChange={res => {
                        const {fileList} = res;
                        settingDispatch({type:'setAlipayAppCer',value:fileList})
                    }}
                >
                    {alipayAppCer.length < 1 && <UploadImgButton name='应用公钥证书.cer上传'/>}
                </Upload>
                <Upload
                    name="picture"
                    accept="image/*"
                    action={`${API_URL}/api/v1/file/upload`}
                    listType="picture-card"
                    fileList={alipayCer}
                    onPreview={handlePreview}
                    onChange={res => {
                        const {fileList} = res;
                        settingDispatch({type:'setAlipayCer',value:fileList})
                    }}
                >
                    {alipayCer.length < 1 && <UploadImgButton name='支付宝公钥证书.cer上传'/>}
                </Upload>
                <Upload
                    name="picture"
                    accept="image/*"
                    action={`${API_URL}/api/v1/file/upload`}
                    listType="picture-card"
                    fileList={alipayRootCer}
                    onPreview={handlePreview}
                    onChange={res => {
                        const {fileList} = res;
                        settingDispatch({type:'setAlipayRootCer',value:fileList})
                    }}
                >
                    {alipayRootCer.length < 1 && <UploadImgButton name='应用根证书.cer上传'/>}
                </Upload>
                <Upload
                    name="picture"
                    accept="image/*"
                    action={`${API_URL}/api/v1/file/upload`}
                    listType="picture-card"
                    fileList={alipayPrivateKey}
                    onPreview={handlePreview}
                    onChange={res => {
                        const {fileList} = res;
                        settingDispatch({type:'setAlipayPrivateKey',value:fileList})
                    }}
                >
                    {alipayPrivateKey.length < 1 && <UploadImgButton name='****_私钥.txt上传'/>}
                </Upload>
            </div>
            <div className={'title'} style={{marginBottom:20}}>
                <Checkbox
                    checked={wechatSwitch}
                    onChange={(e)=>{settingDispatch({type:'setWechatSwitch',value:e.target.checked})}}
                >微信支付</Checkbox>
            </div>
            <div className={'setting_label'}><span>*</span>服务号Appid</div>
            <Input
                value={wechatId}
                onChange={(e)=>settingDispatch({type:'setWechatId',value:e.target.value})}
            />
            <div className={'setting_label'}><span>*</span>服务号Secret</div>
            <Input
                value={wechatSecret}
                onChange={(e)=>settingDispatch({type:'setWechatSecret',value:e.target.value})}
            />
            <div className={'setting_label'}><span>*</span>小程序Appid</div>
            <Input
                value={wechatAppId}
                onChange={(e)=>settingDispatch({type:'setWechatAppId',value:e.target.value})}
            />
            <div className={'setting_label'}><span>*</span>小程序Secret</div>
            <Input
                value={wechatAPPSecret}
                onChange={(e)=>settingDispatch({type:'setWechatAPPSecret',value:e.target.value})}
            />
            <div className={'setting_label'}><span>*</span>商户号</div>
            <Input
                value={wechatShopNum}
                onChange={(e)=>settingDispatch({type:'setWechatShopNum',value:e.target.value})}
            />
            <div className={'setting_label'}><span>*</span>证书序列号</div>
            <Input
                value={wechatCerNum}
                onChange={(e)=>settingDispatch({type:'setWechatCerNum',value:e.target.value})}
            />
            <div className={'setting_label'}><span>*</span>APIv3密钥</div>
            <Input
                value={wechatAPIv3}
                onChange={(e)=>settingDispatch({type:'setWechatAPIv3',value:e.target.value})}
            />
            <div className={'setting_label'}><span>*</span>回调地址</div>
            <Input
                value={wechatCallBack}
                onChange={(e)=>settingDispatch({type:'setWechatCallBack',value:e.target.value})}
            />
            <div className={'setting_label'}><span>*</span>证书文件</div>
            <div className={'cer_wrap'}>
                <Upload
                    name="picture"
                    accept="image/*"
                    action={`${API_URL}/api/v1/file/upload`}
                    listType="picture-card"
                    fileList={wechatP12}
                    onPreview={handlePreview}
                    onChange={res => {
                        const {fileList} = res;
                        settingDispatch({type:'setWechatP12',value:fileList})
                    }}
                >
                    {wechatP12.length < 1 && <UploadImgButton name='apiclient_cert.p12'/>}
                </Upload>
                <Upload
                    name="picture"
                    accept="image/*"
                    action={`${API_URL}/api/v1/file/upload`}
                    listType="picture-card"
                    fileList={wechatCerPem}
                    onPreview={handlePreview}
                    onChange={res => {
                        const {fileList} = res;
                        settingDispatch({type:'setWechatCerPem',value:fileList})
                    }}
                >
                    {wechatCerPem.length < 1 && <UploadImgButton name='apiclient_cert.pem'/>}
                </Upload>
                <Upload
                    name="picture"
                    accept="image/*"
                    action={`${API_URL}/api/v1/file/upload`}
                    listType="picture-card"
                    fileList={wechatKeyPem}
                    onPreview={handlePreview}
                    onChange={res => {
                        const {fileList} = res;
                        settingDispatch({type:'setWechatKeyPem',value:fileList})
                    }}
                >
                    {wechatKeyPem.length < 1 && <UploadImgButton name='apiclient_key.pem'/>}
                </Upload>

            </div>
            {/*<Row style={{padding:20}}>*/}
            {/*    <Col span={2} offset={22}>*/}
            {/*        <Button type={"primary"} onClick={save}>保存</Button>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <div className={'add_footer'}>
                <Space>
                    <Button type={"primary"} onClick={save}>保存</Button>
                </Space>
            </div>
        </div>
    )
}
export default PayOption
