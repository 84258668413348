import React, {useContext, useEffect} from 'react';
import {Button, Col, DatePicker, Row, Input, Table, message, Space} from 'antd';
import moment from 'moment';
import CustomPagination from '../../../../components/custom_pagination/custom_pagination';
import {CouponDetailContext} from './detail';
import {getCouponState} from '../../../../utils';
import './detail.less';
import {Link, useHistory} from 'react-router-dom';
import ConfirmDelete from '../../../../components/confirm_delete/confirm_delete';
import {delCoupon} from '../../../../service/market/coupon.api';
import {formatPrice} from '../../../../utils';

const {RangePicker} = DatePicker;
const {Search} = Input;
const PAGE_SIZE = 10;
const UI = () => {
    const history = useHistory();
    const {couponData, couponDispatch} = useContext(CouponDetailContext);
    const {start_time, end_time, name, amount, note, publish_count, per_limit, use_type, product_list, id} = couponData;
    const {tableData, page, count} = couponData;
    const columns = [
        {
            title: '优惠券编码',
            dataIndex: 'coupon_id',
            key: 'coupon_id',
        },
        {
            title: '领取用户',
            dataIndex: 'nickname',
            key: 'nickname',
        },
        {
            title: '当前状态',
            dataIndex: 'use_status',
            key: 'use_status',
            render: (text: any) => text == 1 ? '正常' : '过期'
        },
        {
            title: '领取时间',
            dataIndex: 'gain_time',
            key: 'gain_time',
            render: (text: any) => text > 0 ? moment.unix(text).format('YYYY-MM-DD') : '未使用'
        },
        {
            title: '使用时间',
            dataIndex: 'use_time',
            key: 'use_time',
            render: (text: any) => text > 0 ? moment.unix(text).format('YYYY-MM-DD') : '未使用'
        },
        {
            title: '订单编号',
            key: 'order_sn',
            dataIndex: 'order_sn',
            render: (text: any) => text ? text : '未下单'
        }
    ];
    const deleteCoupon = async (record: any) => {
        const {id} = record;
        try {
            await delCoupon({id});
            message.success('优惠券删除成功');
            history.replace('/market_coupon');
        } catch (e) {
            console.log(e);
            message.error('优惠券删除失败');
        }
    };
    return (
        <div className={'container'}>
            <div className={'header'}>
                <Row>
                    <Col span={10} style={{display:'flex',alignItems:'center'}}>
                        <span style={{fontSize: '20px'}}>优惠券信息</span>
                        <span className={'tag'} style={{
                            width: 100,
                            display: 'inline-block',
                            textAlign: 'center',
                            margin:'0 0 0 8px'
                        }}>{getCouponState(start_time, end_time)}</span>
                    </Col>
                    <Col span={5} offset={9}>
                        <Space style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button onClick={() => ConfirmDelete({id}, '确认删除该优惠券吗', deleteCoupon)}>删除</Button>
                            <Link to={{
                                pathname: '/edit_market_coupon',
                                search: history.location.search
                            }}>
                                <Button type="primary">编辑</Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>
                <Row className={'row'}>
                    <Col span={2}>
                        <div>优惠券名称:</div>
                    </Col>
                    <Col span={5}>
                        <div style={{width:200,overflow:'hidden',textOverflow:'ellipsis',whiteSpace:'nowrap'}}>{name}</div>
                    </Col>
                    <Col span={2} offset={1}>
                        <div>面值:</div>
                    </Col>
                    <Col span={5}>
                        <div>￥{formatPrice(amount)}元</div>
                    </Col>
                    <Col span={2} offset={1}>
                        <div>有效期:</div>
                    </Col>
                    <Col span={5}>
                        <div>{moment(start_time).format('YYYY-MM-DD')} 至 {moment(end_time).format('YYYY-MM-DD')}</div>
                    </Col>
                </Row>
                <Row className={'row'}>
                    <Col span={2}>
                        <div>优惠券描述:</div>
                    </Col>
                    <Col span={5} style={{position:'relative'}}>
                        <div style={{position:'absolute',left:0,top:1}}>{note}</div>
                    </Col>
                    <Col span={2} offset={1}>
                        <div>总发行量:</div>
                    </Col>
                    <Col span={5}>
                        <div>{publish_count}张</div>
                    </Col>
                    <Col span={2} offset={1}>
                        <div>可用范围:</div>
                    </Col>
                    <Col span={5} style={{position:'relative'}}>
                        <div>{use_type === 1 ? '全场通用' : '指定商品'}</div>
                        <div className={'product_wrap'} style={{position:'absolute',left:0,top:30}}>
                            {
                                use_type === 2 && product_list.map((item: any) => (
                                    <div className={'tag'} style={{textAlign: 'center',width:'max-content',padding:'0 12px'}}>{item.name}</div>
                                ))
                            }
                        </div>
                    </Col>
                </Row>
                <Row className={'row'}>
                    <Col span={2} offset={8}>
                        <div>每人限领:</div>
                    </Col>
                    <Col span={5}>
                        <div>{per_limit}张</div>
                    </Col>
                </Row>
            </div>
            <div className={'footer'}>
                <Row>
                    <Col span={17} style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{marginRight: 8}}>领取时间</div>
                        <RangePicker onChange={e=>{
                            couponDispatch({
                                type:'setGainRange',
                                value:e
                            })
                        }}/>
                        <div style={{marginRight: 8, marginLeft: 8}}>使用时间</div>
                        <RangePicker onChange={e=>{
                            couponDispatch({
                                type:'setUseRange',
                                value:e
                            })
                        }}/>
                    </Col>
                    <Col span={6} offset={1} style={{display: 'flex', alignItems: 'center'}}>
                        <Search
                            placeholder="输入优惠券编码、领取用户、订单编号进行搜索"
                            allowClear
                            enterButton
                            onSearch={e=>{
                                couponDispatch({
                                    type:'setSearchContent',
                                    value:e
                                })
                            }}
                        />
                    </Col>
                </Row>
                <Row className={'row'}>
                    <Col span={24}>
                        <Table columns={columns} dataSource={tableData} pagination={false}/>
                    </Col>
                    <Col span={24}>
                        <CustomPagination
                            currentPage={page}
                            total={count}
                            onChange={(page: number) => couponDispatch({type: 'setPage', value: page})}
                            pageSize={PAGE_SIZE}
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
};
export default UI;
