import {Button, Input} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {Link} from 'react-router-dom';
import React, {useState} from 'react';
import './table_head.less';

const {Search} = Input;
const TableHead = (props: { onSearch?: Function, addType: string, url?: string, onClick?: any }) => {
    //搜索内容
    // const [searchContent, setSearchContent] = useState('');
    const {onSearch, addType, url, onClick} = props;
    const handlerSearch = (e:any) => {
        onSearch && onSearch(e);
    };

    return (
        <div className={'table_head'}>
            {onSearch ? <Search
                className={'search'}
                placeholder="请输入"
                onSearch={handlerSearch}
                enterButton="搜索"
                // onChange={(e) => setSearchContent(e.target.value)}
                allowClear
            /> : <div/>}
            {url ? <Link to={url}><Button type={'primary'} icon={<PlusOutlined/>}>{addType}</Button></Link>
                : <Button type={'primary'} icon={<PlusOutlined/>} onClick={onClick}>{addType}</Button>}

        </div>
    );
};
export default TableHead;
