import '../new.less';
import {NewProductContext} from '../new';
import React, {useContext} from 'react';
import {DatePicker, Radio, Switch, Table} from 'antd';

const Step3 = () => {
    const {newProductData, newProductDispatch} = useContext(NewProductContext);
    const {isNew, isRecommend, promotionType, promotionTimeRange, skus} = newProductData;
    return (
        <div className="form_wrap">
            <div className="info_line">商品推荐</div>
            <div className="info_line">
                <Switch checked={isNew} onChange={() => {
                    newProductDispatch({type: 'isNewCheck'});
                }}/>
                <div style={{margin: '0 10px 0 4px'}}>新品</div>
                <Switch checked={isRecommend} onChange={() => {
                    newProductDispatch({type: 'isRecommendCheck'});
                }}/>
                <div style={{margin: '0 10px 0 4px'}}>推荐</div>
            </div>
            <div className="info_line"><span>*</span>促销方式</div>
            <div className="info_line">
                <Radio.Group onChange={(e) => {
                    newProductDispatch({
                        type: 'setPromotionType',
                        value: e.target.value
                    });
                }} value={promotionType}>
                    <Radio value={1}>无促销</Radio>
                    <Radio value={2}>限时促销</Radio>
                </Radio.Group>
            </div>
            {
                promotionType === 2 &&
                <>
                    <div className="info_line"><span>*</span>限时范围</div>
                    <div className="info_line">
                        <DatePicker.RangePicker showTime value={promotionTimeRange} onChange={(e: any) => {
                            newProductDispatch({
                                type: 'setPromotionTimeRange',
                                value: e
                            });
                        }}/>
                    </div>
                    <div className="info_line"><span>*</span>促销价格</div>
                    <div className="info_line">
                        <div style={{width: '100%'}}>
                            <Table dataSource={skus.data} columns={skus.columns2} pagination={false}/>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};
export default Step3;
