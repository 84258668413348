import {Get, Post} from '../../utils/request';


/**
 *
 * 优惠券列表
 * @param req
 */
export const getCoupon = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/coupon/query', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * 删除优惠券
 * @param req
 */
export const delCoupon = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/coupon/delete', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * 新增、编辑优惠券
 * @param req
 */
export const updateCoupon = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/coupon/update', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
export const getCouponHistory = (req:{})=>{
    return new Promise(async (resolve, reject) => {
        try {
            const res = Get('/api/v1/coupon/history/list', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
}
