import React, {useEffect, useState} from 'react';
import {Button, Checkbox, Col, Form, message, Modal, Radio, Row, Space, Switch, Upload} from 'antd';
import {API_URL} from '../../../utils/request';
import UploadImgButton from '../../../components/upload_Img_button/upload_Img_button';
import {getBase64} from '../../../utils';
import {getWaterMark, updateWater} from '../../../service/setting/watermark.api';
import './watermark.less';

import {ExclamationCircleOutlined} from '@ant-design/icons';

const WatermarkSetting = () => {
    const [waterForm] = Form.useForm();
    const [imgList, setImgList] = useState<Array<any>>([]);
    const [switch_checked, setSwitch_checked] = useState<boolean>();
    const [srcInfo, setSrcInfo] = useState<Array<any>>([]);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<any>();
    useEffect(() => {
        getInfo().then();
    }, []);

    const getInfo = async () => {
        const {result}: any = await getWaterMark({});
        setSrcInfo(result);
        _setInfo(result);
    };
    const _setInfo = (info: Array<any>) => {
        console.log(info);
        info.forEach((item: any) => {
            const {name, value} = item;
            switch (name) {
                case 'watermark_switch':
                    setSwitch_checked(value === '1');
                    break;
                case 'watermark_scope':
                    const range = value.split(',').map((item: string) => Number(item));
                    waterForm.setFieldsValue({range});
                    break;
                case 'watermark_position':
                    const position = Number(value);
                    waterForm.setFieldsValue({position});
                    break;
                case 'watermark_pic':
                    setImgList([{
                        url: localStorage.getItem('divms_url') + value,
                        originalUrl: value
                    }]);
            }
        });
    };
    const save = async () => {
        try {
            await waterForm.validateFields();
            console.log(imgList);
            const range = waterForm.getFieldValue('range').join(',');
            const data = [
                {name: 'watermark_switch', value: String(switch_checked ? 1 : 2)},
                {name: 'watermark_scope', value: range},
                {name: 'watermark_position', value: String(waterForm.getFieldValue('position'))},
                {name: 'watermark_pic', value: imgList[0].originalUrl || imgList[0].response.result.key}
            ];
            await updateWater(data);
            message.success('保存成功');
        } catch (e) {
            console.log(e);
        }
    };
    const reset = () => {
        Modal.confirm({
            content: '是否确认重置?',
            icon: <ExclamationCircleOutlined/>,
            centered: true,
            bodyStyle: {
                fontSize: 16,
            },
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk: () => {
                _setInfo(srcInfo);
            }
        });
    };
    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };
    return (
        <div className="watermark_wrap">
            <div className="body">
                <div className="wrap">
                    <Form
                        form={waterForm}
                        name="waterForm"
                        labelAlign={'left'}
                        layout={'horizontal'}
                        labelCol={{span: 6}}
                        wrapperCol={{span: 18}}
                        colon={true}
                        validateTrigger={'submit'}
                    >
                        <Col span={24} className="borderBottom">
                            <Form.Item
                                label="水印开关"
                                name="switch"
                            >
                                <Switch checked={switch_checked} onChange={(value) => setSwitch_checked(value)}/>
                            </Form.Item>
                        </Col>
                        <Col span={24} className="borderBottom">
                            <Form.Item
                                label="水印范围"
                                name="range"
                                rules={[{required: true, message: '请选择水印范围'}]}
                            >
                                <Checkbox.Group>
                                    <Checkbox value={1}>视频</Checkbox>
                                    <Checkbox value={2}>图片</Checkbox>
                                </Checkbox.Group>
                            </Form.Item>
                        </Col>
                        <Col span={24} className="borderBottom">
                            <Form.Item
                                label="水印位置"
                                name="position"
                                rules={[{required: true, message: '请选择水印位置'}]}
                            >
                                <Radio.Group>
                                    <Row>
                                        <Radio.Button value={1}>左上</Radio.Button>
                                        <Radio.Button value={2}>中上</Radio.Button>
                                        <Radio.Button value={3}>右上</Radio.Button>
                                    </Row>
                                    <Row>
                                        <Radio.Button value={4}>左中</Radio.Button>
                                        <Radio.Button value={5}>居中</Radio.Button>
                                        <Radio.Button value={6}>右中</Radio.Button>
                                    </Row>
                                    <Row>
                                        <Radio.Button value={7}>左下</Radio.Button>
                                        <Radio.Button value={8}>中下</Radio.Button>
                                        <Radio.Button value={9}>右下</Radio.Button>
                                    </Row>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={24} className="borderBottom">
                            <Form.Item
                                label="水印图片"
                                name="picture"
                                rules={[{
                                    required: true, message: '请上传水印图片',
                                    validator: (rule, value, callback) => {
                                        if (imgList.length < 1) return Promise.reject('请上传广告图片');
                                        return Promise.resolve('');
                                    }
                                }]}
                            >
                                <Space size={'small'}>
                                    <Upload
                                        name="picture"
                                        accept="image/*"
                                        action={`${API_URL}/api/v1/file/upload`}
                                        listType="picture-card"
                                        fileList={imgList}
                                        onPreview={handlePreview}
                                        onChange={res => setImgList(res.fileList)}
                                    >
                                        {imgList.length < 1 && <UploadImgButton/>}
                                    </Upload>

                                    <div className="tips">
                                        点击图片选择切换水印图片，水印图片会被自动转为尺寸：120x30
                                    </div>
                                </Space>
                            </Form.Item>
                        </Col>
                    </Form>
                    {/*<Row>*/}
                    {/*    <Col offset={0}>*/}
                    {/*        <Space style={{marginTop:24}}>*/}
                    {/*            <Button size={'middle'} onClick={reset}>重置</Button>*/}
                    {/*            <Button type={'primary'} size={'middle'} onClick={save}>保存</Button>*/}
                    {/*        </Space>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <div className={'add_footer'}>
                        <Space>
                            <Button size={'middle'} onClick={reset}>重置</Button>
                            <Button type={'primary'} size={'middle'} onClick={save}>保存</Button>
                        </Space>
                    </div>
                </div>
            </div>
            <Modal
                visible={previewVisible}
                footer={null}
                onCancel={() => setPreviewVisible(false)
                }
            >
                <img alt="example" style={{width: '100%'}} src={previewImage}/>
            </Modal>
        </div>
    );
};
export default WatermarkSetting;
