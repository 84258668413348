import './quantity_statistics.less';
import {DatePicker, Radio, Tabs} from 'antd';
import React, {useEffect, useState} from 'react';
import {Line} from '@ant-design/plots';
import {GetRangeCollectCount, GetRangeSaleCount, GetRangeSaleMoney, GetVisitCount} from '../../../../service/home';

const {RangePicker} = DatePicker;

const QuantityStatistics = () => {
    const [tabValue, setTabValue] = useState<any>('1');
    const [timeRange, setTimeRange] = useState<Array<any>>([]);
    const [radioValue, setRadioValue] = useState<number>(1);
    const [data, setData] = useState<Array<any>>([]);
    const config: any = {
        data,
        xField: 'day',
        yField: 'value',
        seriesField: 'platform',
        color: ['#1990FA', '#2FC25B'],
        legend: false,
        yAxis: {
            label: {
                // 数值格式化为千分位
                formatter: (v: any) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
            },
        },
    };

    useEffect(() => {
        getChartValue().then();
    }, [timeRange, tabValue, radioValue]);

    const getChartValue = async () => {
        switch (tabValue) {
            case '1':
                setData(await GetVisitCount({
                    begin: timeRange[0] ? timeRange[0].format('YYYY-MM-DD HH:mm:ss') : '2000-01-01 00:00:00',
                    end: timeRange[1] ? timeRange[1].format('YYYY-MM-DD HH:mm:ss') : '3000-01-01 00:00:00',
                    platform: radioValue == 1 ? 'all' : radioValue == 2 ? 'app' : 'mini'
                }) as Array<any>);
                break;
            case '2':
                setData(await GetRangeSaleCount({
                    begin: timeRange[0] ? timeRange[0].format('YYYY-MM-DD HH:mm:ss') : '2000-01-01 00:00:00',
                    end: timeRange[1] ? timeRange[1].format('YYYY-MM-DD HH:mm:ss') : '3000-01-01 00:00:00',
                    platform: radioValue == 1 ? 'all' : radioValue == 2 ? 'app' : 'mini'
                }) as Array<any>);
                break;
            case '3':
                setData(await GetRangeSaleMoney({
                    begin: timeRange[0] ? timeRange[0].format('YYYY-MM-DD HH:mm:ss') : '2000-01-01 00:00:00',
                    end: timeRange[1] ? timeRange[1].format('YYYY-MM-DD HH:mm:ss') : '3000-01-01 00:00:00',
                    platform: radioValue == 1 ? 'all' : radioValue == 2 ? 'app' : 'mini'
                }) as Array<any>);
                break;
            case '4':
                setData(await GetRangeCollectCount({
                    begin: timeRange[0] ? timeRange[0].format('YYYY-MM-DD HH:mm:ss') : '2000-01-01 00:00:00',
                    end: timeRange[1] ? timeRange[1].format('YYYY-MM-DD HH:mm:ss') : '3000-01-01 00:00:00',
                    platform: radioValue == 1 ? 'all' : radioValue == 2 ? 'app' : 'mini'
                }) as Array<any>);
                break;
            default:
        }
    };
    return (
        <div className="quantity_statistics_container">
            <div className="tab_line">
                <Tabs
                    defaultActiveKey="1"
                    onChange={(e: any) => {
                        setTabValue(e);
                    }}
                >
                    <Tabs.TabPane tab="访问量" key="1"/>
                    <Tabs.TabPane tab="销售量" key="2"/>
                    <Tabs.TabPane tab="销量额" key="3"/>
                    <Tabs.TabPane tab="收藏" key="4"/>
                </Tabs>
                <RangePicker
                    onChange={(e: any) => {
                        setTimeRange(e ? e : []);
                    }}
                />
            </div>
            <div className="radio_line">
                <Radio.Group
                    onChange={e => {
                        setRadioValue(e.target.value);
                    }}
                    value={radioValue}
                >
                    <Radio value={1}>全部</Radio>
                    <Radio value={2}>
                        <div style={{color: '#1990FA'}}>APP</div>
                    </Radio>
                    <Radio value={3}>
                        <div style={{color: '#2FC25B'}}>小程序</div>
                    </Radio>
                </Radio.Group>
            </div>
            <div className="chart_container">
                <Line {...config} />
            </div>
        </div>
    );
};
export default QuantityStatistics;
