import {Button, Form, Input, message, Modal, Space, Table} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import React, {useEffect, useState} from 'react';
import {Link, useLocation, useHistory} from 'react-router-dom';
import _ from 'lodash';

import {
    getSpecificationsAttributeList,
    delSpecificationsAttribute,
    saveSpecificationsAttribute
} from '../../../../service/product.api';
import './attribute.less';


const {TextArea} = Input;

const NewProductSpecifications = (props: any) => {
    const history = useHistory();
    const location = useLocation();
    const [categoryId, setCategoryId] = useState('');
    //表格数据
    const [tableData, setTableData] = useState<any>([]);
    //批量添加modal
    const [batchVisible, setBatchVisible] = useState(false);
    //分页数据
    // const [currentPage,setCurrentPage] = useState(1)
    // const [count,setCount] = useState(0)
    //提交时加载状态
    const [loading, setLoading] = useState(false);
    //异步关闭Modal
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [addForm] = Form.useForm();
    const columns = [
        {
            title: '属性名称',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '可选值',
            dataIndex: 'input_list',
            key: 'name',
            render: (text: string) => {
                const str = text.replace(/,/g, ' ');
                return <div>{str}</div>;
            }
        },
        {
            title: '操作',
            render: (_: string, record: any, index: number) =>
                <Button type="primary" danger onClick={() => showDeleteConfirm(record)}>删除</Button>,
        },
    ];

    useEffect(() => {
        const {search} = location;
        const searchString: string = search.substring(1);
        const searchParams: any = new URLSearchParams(searchString);
        const id: string = searchParams.get('id');
        setCategoryId(id);
    }, []);
    useEffect(() => {
        if (batchVisible) return;
        getAttribute().then();
    }, [categoryId]);

    /**
     * 获取规格属性
     */
    const getAttribute = async () => {
        if (!categoryId) return;
        const params: {} = {
            product_attribute_category_id: parseInt(categoryId),
            // page:1,
            // size:9999
        };
        try {
            const req: any = await getSpecificationsAttributeList(params);
            const {result: {data, count}} = req;
            setTableData(data);
            // setCount(count)
        } catch (e) {
            console.log(e);
        }
    };
    /**
     * 确认是否删除属性
     * @param record
     */
    const showDeleteConfirm = (record: any) => {
        Modal.confirm({
            content: '确认删除该属性吗?',
            icon: <ExclamationCircleOutlined/>,
            centered: true,
            bodyStyle: {
                fontSize: 16,
            },
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk: () => {
                delAttribute(record).then();
            }
        });
    };
    /**
     * 删除属性
     * @param record
     */
    const delAttribute = async (record: any) => {
        const {id, name} = record;
        const newData: Array<any> = [...tableData];
        const index: number = newData.findIndex(item => item.name === name);
        newData.splice(index, 1);
        setTableData(newData);
        if (id) {
            try {
                await delSpecificationsAttribute({id});
            } catch (e) {
                console.log(e);
            }
        }
    };
    /**
     * 批量添加属性
     */
    const handlerAdd = async () => {
        try {
            await addForm.validateFields(['name', 'optional']);
            const {name, optional} = addForm.getFieldsValue();
            let arr = optional.split(/\n/);
            let input_list = '';
            arr = _.uniq(arr);
            arr.forEach((item: any) => {
                input_list += item + ',';
            });
            input_list = input_list.replace(/^,*|,*$/g, '');
            const row: any = {name, input_list};
            const newData: Array<any> = [...tableData];
            const index: number = newData.findIndex((item: any) => row.name === item.name);
            if (index > -1) {
                newData.splice(index, 1, row);
                setTableData(newData);
            } else {
                newData.push(row);
                setTableData(newData);
            }
            setBatchVisible(false);
        } catch (e) {
            console.log(e);
        }
    };
    /**
     * 提交规格属性
     */
    const submit = async () => {
        try {
            let data = [...tableData];
            data.forEach(item => {
                item.product_attribute_category_id = parseInt(categoryId);
                delete item.created_at;
                delete item.updated_at;
            });
            const {code}: any = await saveSpecificationsAttribute(data);
            if (code !== -1) {
                message.success('提交成功');
                history.replace('/product_specifications');
            }
        } catch (e) {
            console.log(e);
            message.error('提交失败');
        }

    };
    return (
        <div style={{height: '100%'}}>
            <Modal
                title="新增规格"
                visible={batchVisible}
                onOk={handlerAdd}
                onCancel={() => {
                    setBatchVisible(false);
                }}
                okText="确认"
                cancelText="取消"
                confirmLoading={confirmLoading}
                centered={true}
                destroyOnClose={true}
                afterClose={() => addForm.resetFields()}
            >
                <Form
                    form={addForm}
                    name="addForm"
                    labelCol={{span: 6}}
                    labelAlign={'left'}
                    wrapperCol={{span: 18}}
                    colon={true}
                >
                    <Form.Item
                        label="属性名称"
                        name="name"
                        required={true}
                    >
                        <Input placeholder="请输入属性名称"/>
                    </Form.Item>
                    <Form.Item
                        label="可选值"
                        name="optional"
                    >
                        <TextArea rows={3} placeholder="可批量设置,一行一个"/>
                    </Form.Item>
                </Form>
            </Modal>
            <div className="specification_add_wrap">
                <div className="container">
                    <div className="table_wrap">
                        <Table dataSource={tableData} columns={columns} pagination={false}/>
                        <div className="batch_wrap">
                            <Button type="primary" onClick={() => {
                                setBatchVisible(true);
                            }}>添加列表</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'add_footer'}>
                <Space>
                    <Link to="/product_specifications" replace={true}><Button>取消</Button></Link>
                    <Button
                        type="primary"
                        onClick={submit}
                        loading={loading}
                    >保存</Button>
                </Space>
            </div>
        </div>

    );
};
export default NewProductSpecifications;
