import React, {useReducer} from 'react';
import {Form, Input, Radio, Row, Col, Upload, Modal, message, Space, Button} from 'antd';

import {useHistory, useParams} from 'react-router-dom';
import {getBase64, searchObj} from '../../../../utils';
import UploadImgButton from '../../../../components/upload_Img_button/upload_Img_button';
import FormFooter from '../../../../components/form_footer/form_footer';

import {API_URL} from '../../../../utils/request';
import {CreateNewCategory} from '../../../../service/public.apis';

const initialState = {
    //分类名称
    name: '',
    //是否隐藏
    isHint: '',
    //关键词
    keyWord: '',
    //分类描述
    desc: '',
    //预览的图片url/base64
    previewImg: '',
    //是否处于预览状态
    isPreview: false,
    //分类icon列表
    iconList: [],
};
//状态处理
const reducer = (state: typeof initialState, action: IAction) => {
    switch (action.type) {
        case 'setPreViewImg':
            return Object.assign({}, state, {previewImg: action.value});
        case 'setIsPreview':
            return Object.assign({}, state, {isPreview: action.value});
        case 'setIconList':
            return Object.assign({}, state, {iconList: action.value});
        case 'setName':
            return Object.assign({}, state, {name: action.value});
        case 'setIsHint':
            return Object.assign({}, state, {setIsHint: action.value});
        case 'setKeyWord':
            return Object.assign({}, state, {setKeyWord: action.value});
        case 'setDesc':
            return Object.assign({}, state, {setDesc: action.value});
        default:
            return Object.create(state);
    }
};
const ProductSubcategory = () => {
    //路由控制实例
    const history = useHistory();
    const [newCategoryForm] = Form.useForm();
    const [categoryData, dispatch] = useReducer(reducer, initialState);
    const {isHint, previewImg, isPreview, iconList} = categoryData;

    /**
     * 自定义图片校验规则
     */
    const checkPicture = () => {
        if (iconList.length === 0) return Promise.reject(new Error('请上传至少一张商品图片'));
        return Promise.resolve('');
    };

    /**
     * 处理icon预览
     */
    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        dispatch({type: 'setPreViewImg', value: file.url || file.preview});
        dispatch({type: 'setIsPreview', value: true});
    };

    /**
     * 取消
     */
    const onCancel = () => {
        history.replace('/product_category');
    };
    /**
     * 保存
     */
    const onSubmit = async () => {
        let params = searchObj(history.location.search);
        try {
            await newCategoryForm.validateFields();
            let formValue = newCategoryForm.getFieldsValue();
            console.log(formValue);
            let data = {
                id: 0,
                parent_id: params.parent_id ? Number(params.parent_id) : 0,
                name: formValue.name,
                show_status: formValue.hint ? 1 : 2,
                icon: formValue.picture.file.response.result.key,
                description: formValue.desc,
                keywords: formValue.keyWord
            };
            try {
                await CreateNewCategory(data);
                message.success('提交成功');
                history.replace('/product_category');
            } catch (e) {
                console.log(e);
            }
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <div className={'list_add_body_wrap'}>
            <div className={'list_add_body'}>
                <div className={'form_wrap'}>
                    <Form
                        form={newCategoryForm}
                        name="newCategoryForm"
                        labelCol={{span: 4}}
                        labelAlign={'left'}
                        wrapperCol={{span: 20}}
                        colon={true}
                        initialValues={{hint: false}}
                    >
                        <div className={'title'}>
                            分类信息
                        </div>
                        <Form.Item
                            label="分类名称"
                            name="name"
                            rules={[{required: true, message: '请输入分类名称'}]}
                        >
                            {/*<Input onChange={e=>setName(e.target.value)}/>*/}
                            <Input onChange={(e) => dispatch({type: 'setName', value: e.target.value})}/>
                        </Form.Item>
                        <Form.Item
                            label="是否隐藏"
                            name="hint"
                        >
                            <Radio.Group onChange={e => dispatch({type: 'setIsHint', value: e.target.value})}
                                         value={isHint}>
                                <Row>
                                    <Col span={24}>
                                        <Radio value={true} style={{lineHeight: '32px'}}>
                                            是
                                        </Radio>
                                        <Radio value={false} style={{lineHeight: '32px'}}>
                                            否
                                        </Radio>
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="关键词"
                            name="keyWord"
                        >
                            <Input onChange={e => dispatch({type: 'setKeyWord', value: e.target.value})}/>
                        </Form.Item>
                        <Form.Item
                            label="分类描述"
                            name="desc"
                        >
                            <Input onChange={e => dispatch({type: 'setDesc', value: e.target.value})}/>
                        </Form.Item>
                        <Form.Item
                            label="分类图标"
                            name="picture"
                            rules={[{validator: checkPicture}]}
                        >
                            <Upload
                                name="picture"
                                action={`${API_URL}/api/v1/file/upload`}
                                listType="picture-card"
                                fileList={iconList}
                                onPreview={handlePreview}
                                onChange={res => {
                                    dispatch({type: 'setIconList', value: res.fileList});
                                }}
                            >
                                {iconList.length < 1 && <UploadImgButton/>}
                            </Upload>
                        </Form.Item>
                    </Form>
                    {/*<FormFooter oncancel={onCancel} onSubmit={onSubmit}/>*/}
                </div>
            </div>
            <div className={'add_footer'}>
                <Space>
                    <Button
                        onClick={onCancel}
                    >取消
                    </Button>
                    <Button type={"primary"} onClick={onSubmit}>保存</Button>
                </Space>
            </div>
            <Modal
                visible={isPreview}
                footer={null}
                onCancel={() => {
                    dispatch({type: 'setIsPreview', value: false});
                }}
            >
                <img alt="example" style={{width: '100%'}} src={previewImg}/>
            </Modal>
        </div>
    );
};
export default ProductSubcategory;
