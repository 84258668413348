import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {Tabs, Button, Table, Space, message, Image, Divider} from 'antd';
import ConfirmDelete from '../../../components/confirm_delete/confirm_delete';
import CustomPagination from '../../../components/custom_pagination/custom_pagination';
import {getAdvertising, delAdvertising} from '../../../service/market/advertising';
import TableHead from '../../../components/table_head/table_head';

const PAGE_SIZE = 20;
const MarketAds = () => {
    //Tab状态 1是banner 2 是插屏
    const [tabState, setTabState] = useState(1);
    const [searchContent, setSearchContent] = useState('');
    const [tableData, setTableData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);

    const columns:any = [
        {
            title: '排序',
            dataIndex: 'sort',
            key: 'id',
            width: 100,
            fixed:'left'
        },
        {
            title: '广告位',
            render: (text: any) => tabState === 1 ? 'APP首页轮播' : 'APP首页插屏'
        },
        {
            title: '广告名称',
            dataIndex: 'name',
            key: 'id'
        },
        {
            title: '广告图片',
            dataIndex: 'pic',
            key: 'id',
            width: 140,
            render: (text: string, record: any) =>
                <Image style={{width: 60, height: 60}} src={localStorage.getItem('divms_url') + text}/>
        },
        {
            title: '时间',
            key: 'id',
            width: 240,
            render: (text: any, record: any) => {
                const {start_time, end_time} = record;
                return `${moment(start_time).format('YYYY-MM-DD')} 至 ${moment(end_time).format('YYYY-MM-DD')}`;
            }
        },
        {
            title: '操作',
            width: 200,
            fixed:'right',
            render: (text: any, record: any) => (
                <div style={{display: 'flex'}}>
                    <Space size="small">
                        {tabState === 1 ?
                            <Link to={{
                                pathname: '/edit_ads_banner',
                                search: `data=${encodeURIComponent(JSON.stringify(record))}`
                            }}>
                                编辑
                            </Link> :
                            <Link to={{
                                pathname: '/edit_screen_banner',
                                search: `data=${encodeURIComponent(JSON.stringify(record))}`
                            }}>
                                编辑
                            </Link>
                        }
                        <Divider type="vertical"/>
                        <a onClick={() => ConfirmDelete(record, '确认删除该广告吗', delAd)}>删除</a>
                    </Space>
                </div>
            )
        },
    ];

    const changeTab = (type: any) => {
        type = Number(type) === 1 ? 1 : 2;
        if (type === tabState) return;
        setTabState(type);
    };
    useEffect(() => {
        getAds().then();
    }, [tabState, searchContent]);
    const getAds = async () => {
        const {result: {count, data}}: any = await getAdvertising({
            type: tabState,
            kwd: searchContent,
            page: currentPage,
            size: PAGE_SIZE
        });
        setTableData(data);
        setCount(count);
    };
    const onSearch = (e: React.SetStateAction<string>) => {
        setSearchContent(e);
    };
    const delAd = async (record: any) => {
        try {
            const {id} = record;
            await delAdvertising({id});
            message.success('删除成功');
            await getAds();
        } catch (e) {
            message.error('删除失败');
            console.log(e);
        }
    };
    return (
        <div className="content_container">
            <Tabs defaultActiveKey="1" style={{padding: '0 40px', backgroundColor: '#FFFFFF', width: '100%'}}
                  onChange={changeTab}>
                <Tabs.TabPane tab="Banner" key="1"/>
                <Tabs.TabPane tab="插屏" key="2"/>
            </Tabs>
            <div className={'main_wrap'}>
                <div className={'main_container'}>
                    <TableHead onSearch={onSearch} addType="新增广告"
                               url={tabState === 1 ? `/new_ads_banner` : `/new_screen_banner`}/>
                    <div className={'table_wrap'}>
                        <Table dataSource={tableData}
                               columns={columns}
                               pagination={false}
                               scroll={{y: 680,x:1000}}
                        />
                    </div>
                </div>
                <CustomPagination
                    currentPage={currentPage}
                    total={count}
                    onChange={(page: number) => setCurrentPage(page)}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </div>
    );
};
export default MarketAds;
