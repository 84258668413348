import {Get, Post} from '../utils/request';


/**
 *
 * [商品]获取商品列表
 * @param req
 */
export const getProduct = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/product/query', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * [商品]新增、编辑商品
 * @param req
 */
export const addProduct = (req?: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/product/update', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * [商品]上/下架商品
 */
export const putProduct = (req?: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/product/soldout', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * [商品]删除商品
 */
export const delProduct = (req?: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/product/delete', req);
            resolve(res);
        } catch (e) {
            //reject(e);
        }
    });
};
/**
 * [分类]获取商品分类列表
 * @param req
 */
export const getCategoryList = (req?: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Get('/api/v1/category/query', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * [规格分类]获取规格分类列表
 * @param req
 */
export const getSpecificationsCategoryList = (req?: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/attribute_category/list', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 *  [规格分类]新增规格分类
 * @param req
 */
export const addSpecificationsCategory = (req?: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/attribute_category/add', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 *  [规格分类]删除规格分类
 * @param req
 */
export const delSpecificationsCategory = (req?: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/attribute_category/delete', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * [规格属性]获取规格属性
 * @param req
 */
export const getSpecificationsAttributeList = (req?: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/attribute/query', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * [规格属性]保存规格属性
 * @param req
 */
export const saveSpecificationsAttribute = (req?: Array<{}>) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/attribute/update', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * [规格属性]删除规格属性
 * @param req
 */
export const delSpecificationsAttribute = (req?: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/attribute/delete', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * [商品评论]获取商品评论列表
 * @param req
 */
export const getCommentList = (req?: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/comment/query', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * [商品评论]删除商品评论
 * @param req
 */
export const delComment = (req?: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/comment/delete', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};

export const getProductDetail = (id: number) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/product/detail', {product_id: id});
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const categoryDrag = (req?: {}) => {
    return new Promise(async (resolve) => {
        try {
            const res = await Post('/api/v1/category/drag', req);
            resolve(res);
        } catch (e) {
            // reject(e);
        }
    });
};

export const deleteSku = (id: number) => {
    return new Promise(async (resolve) => {
        try {
            const res = await Post('/api/v1/product/sku/delete', {product_sku_id: id});
            resolve(res);
        } catch (e) {
            // reject(e);
        }
    });
};
