import './login.less';
import {PublicContext} from '../../context';
import {Button, Card, Form, Input, Space} from 'antd';
import {useContext, useEffect, useState} from 'react';
import {createHashHistory} from 'history';
import {LoginRequest} from '../../service/public.apis';
import {SetToken} from '../../utils/request';

const Login = () => {
    const {publicDispatch} = useContext(PublicContext);
    const [loggingIn, setLoggingIn] = useState<boolean>(false);
    useEffect(() => {
        publicDispatch({
            type: 'setLoggedIn',
            value: false
        });
    }, []);
    const loginConfirm = async (e: any) => {
        setLoggingIn(true);
        try {
            const res: any = await LoginRequest({
                account: e.account,
                pwd: e.pwd
            });
            publicDispatch({
                type: 'setImgDomain',
                value: res.img_domain
            });
            publicDispatch({
                type: 'setLoggedIn',
                value: true
            });
            let array=['-1']
            res.permission.forEach((e:any)=>{
                if(e.own){
                    array.push(String(e.id))
                }
                e.child.forEach((e:any)=>{
                    if(e.own){
                        array.push(String(e.id))
                    }
                })
            })
            publicDispatch({
                type: 'setAuthList',
                value: array
            });
            await localStorage.setItem('divms_auth', array.join(','));
            SetToken(res.token);
            await localStorage.setItem('divms_login_token', res.token);
            await localStorage.setItem('divms_url', res.img_domain);
            createHashHistory().replace('/home');
        } catch (e) {
        }
        setLoggingIn(false);
    };

    return (
        <div className="login_container">
            <Space direction="vertical" align="center" size="large">
                <h1 className="login_title_text">十三烧电商管理后台</h1>
                <Form
                    name="basic"
                    wrapperCol={{span: 24}}
                    initialValues={{remember: true}}
                    onFinish={loginConfirm}
                    autoComplete="off"
                >
                    <Card style={{width: 460, padding: '24px 42px 0 42px', borderRadius: '10px'}}>
                        <h2 className="login_box_title">账号登录</h2>
                        <Form.Item
                            name="account"
                            rules={[{required: true, message: '账号'}]}
                        >
                            <Input style={{height: '50px', borderRadius: '10px', backgroundColor: '#F8F8F8'}}
                                   placeholder="请输入账号"/>
                        </Form.Item>
                        <Form.Item
                            name="pwd"
                            rules={[{required: true, message: '密码'}]}
                        >
                            <Input.Password style={{height: '50px', borderRadius: '10px', backgroundColor: '#F8F8F8'}}
                                            placeholder="请输入密码"/>
                        </Form.Item>
                        <Form.Item>
                            <Button style={{height: '50px', borderRadius: '10px',borderColor:'#D6B974',backgroundColor:'#D6B974'}} loading={loggingIn} type="primary"
                                    htmlType="submit" block>登录</Button>
                        </Form.Item>
                    </Card>
                </Form>
            </Space>
        </div>
    );
};
export default Login;
