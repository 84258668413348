import React, {useEffect, useState} from 'react';
import {Col, Row, Select, DatePicker, Input} from 'antd';
import Item from './components/order_item';
import CustomPagination from '../../../components/custom_pagination/custom_pagination';
import {getList} from '../../../service/order/list';
import moment, {Moment} from 'moment';
import {searchObj} from '../../../utils';
import {useHistory} from 'react-router-dom';

const {Option} = Select;
const {Search} = Input;
const {RangePicker} = DatePicker;

const PAGE_SIZE = 20;
const OrderList = () => {
    const history = useHistory();
    const [selectState, setSelectState] = useState<number>(-1);
    const [orderTime, setOrderTime] = useState<[Moment, Moment]>();
    const [searchContent, setSearchContent] = useState<string>();
    const [orderList, setOrdList] = useState<Array<any>>([]);
    const [page, setPage] = useState<number>(1);
    const [count, setCount] = useState<number>(0);
    useEffect(() => {
        if (history.location.search !== "") {
            let params = searchObj(history.location.search);
            if (params.kwd) {
                setSearchContent(params.kwd);
            }
        }
        getOrderList().then();
    }, [page, searchContent, orderTime, selectState]);
    const getOrderList = async () => {
        const start = orderTime && orderTime[0];
        const end = orderTime && orderTime[1];
        const {result: {count, data}}: any = await getList({
            page,
            size: PAGE_SIZE,
            status: selectState,
            start: start?.unix(),
            end: end?.unix(),
            kwd: searchContent,
        });
        setCount(count);
        setOrdList(data);
    };
    const onSearch = async (content: string) => {
        setSearchContent(content);
        setPage(1);
        //await getOrderList();
    };
    return (
        <div className="content_container">
            <div className={'main_wrap'}>
                <div className={'main_container'}>
                    <div style={{display: 'flex', alignItems: 'center', margin: '16px 0'}}>
                        <Select style={{width: 200}} size={'middle'} defaultValue={-1}
                                onChange={(value) => setSelectState(value)}>
                            <Option value={-1}>全部状态</Option>
                            <Option value={0}>待支付</Option>
                            <Option value={10}>待发货</Option>
                            <Option value={20}>待收货</Option>
                            <Option value={30}>已签收</Option>
                            <Option value={40}>已退货</Option>
                            <Option value={50}>已退款</Option>
                            <Option value={60}>已关闭</Option>
                        </Select>
                        <div style={{margin: '0 20px'}}>
                            <RangePicker
                                value={orderTime}
                                showTime
                                onChange={(data: any) => setOrderTime(data)}
                                style={{width: 260}}
                            />
                        </div>
                        <div className={'flex_1'}>
                            <Search
                                placeholder="输入订单编号、商品名称、收件人、物流单号进行搜索"
                                onSearch={onSearch}
                                onChange={(e: any) => setSearchContent(e.target.value)}
                                enterButton="搜索"
                                allowClear={true}
                                value={searchContent}
                            />
                        </div>
                    </div>
                    <div style={{maxHeight: '740px', overflowY: 'scroll'}}>
                        {
                            orderList && orderList.map((order: any, index: number) =>
                                (
                                    <React.Fragment key={order.id}>
                                        <Item order={order} getOrderList={getOrderList}/>
                                    </React.Fragment>
                                )
                            )
                        }
                    </div>
                </div>
                <CustomPagination
                    currentPage={page}
                    total={count}
                    onChange={(page: number) => setPage(page)}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </div>
    );
};
export default OrderList;
