import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'
import {Form, Input, DatePicker, Radio, Upload, Modal, message, Select, Space, Button} from 'antd';
import {getBase64, searchObj} from "../../../../utils";
import {API_URL} from "../../../../utils/request";
import UploadImgButton from "../../../../components/upload_Img_button/upload_Img_button";
import FormFooter from "../../../../components/form_footer/form_footer";
import {updateAdvertising} from '../../../../service/market/advertising'
import moment from 'moment'

let url:string|null = null;
const {RangePicker} = DatePicker
const {Option} = Select;
const NewAdsBanner = () => {
    const history = useHistory();
    const [BannerForm] = Form.useForm();
    const [imgList,setImgList] = useState<Array<any>>([]);
    const [urlData,setUrlData] = useState<any>();
    const [isPreview,setIsPreview] = useState(false);
    const [previewImg,setPreViewImg] = useState('')

    useEffect(()=>{
        url = history.location.pathname
        getEditData()
    },[])
    useEffect(()=>{
        if(!urlData) return
        setForm()
    },[urlData])
    const getEditData = ()=>{
        if(!history.location.search) return;
        const urlData = JSON.parse(decodeURIComponent(history.location.search).substring(6))
        console.log('编辑信息',urlData)
        const {pic} = urlData;
        setUrlData(urlData)
        setImgList([{
            url: localStorage.getItem('divms_url') + pic,
            originalUrl: pic
        }])
    }
    const setForm = ()=>{
        const {name,sort,start_time,end_time,link_type,link_url,position} = urlData;
        BannerForm.setFieldsValue({
            name: name,
            sort: sort,
            linkType: link_type,
            linkUrl: link_url,
            rangeTime:[moment(start_time),moment(end_time)],
            position
        })
    }
    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setIsPreview(true);
        setPreViewImg(file.url || file.preview)
    };
    const submit = async()=>{
        try{
            await BannerForm.validateFields();
            const formValue = BannerForm.getFieldValue([])
            let data = {
                id: urlData ? urlData.id : 0,
                type: url?.match(/ads_banner$/gi) ? 1 : 2,
                name: formValue.name,
                sort: Number(formValue.sort),
                start_time: formValue.rangeTime[0].valueOf(),
                position:formValue.position,
                end_time: formValue.rangeTime[1].valueOf(),
                link_type: formValue.linkType,
                link_url: formValue.linkUrl,
                pic:urlData?urlData.pic:formValue.picture.file.response.result.key
            };
            await updateAdvertising(data)
            message.success('提交成功')
            history.replace('/market_ads')
        }catch (e){
            message.error('提交失败')
            console.log(e)
        }

    }

    return(
        <div className={'add_body_wrap'}>
            <div className={'add_body'}>
                <div className={'form_wrap'}>
                    <Form
                        form={BannerForm}
                        name="BannerForm"
                        labelAlign={'left'}
                        layout={"vertical"}
                        colon={true}
                        initialValues={{
                            linkType:1
                        }}
                    >
                        <div className={'title'}>
                            基本信息
                        </div>
                        <Form.Item
                            label="广告名称"
                            name="name"
                            rules={[{required: true, message: '请输入广告名称'}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="广告位"
                            name="position"
                            rules={[{required: true, message: '请输入广告位'}]}
                        >
                           <Select>
                               <Option value={'APP首页轮播'}>
                                   APP首页轮播
                               </Option>
                           </Select>
                        </Form.Item>
                        <Form.Item
                            label="排序"
                            name="sort"
                            rules={[{required: true, message: '请输入排序'}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="时间"
                            name="rangeTime"
                            rules={[{required: true, message: '请选择时间'}]}
                        >
                            <RangePicker/>
                        </Form.Item>
                        <Form.Item
                            label="跳转类型"
                            name="linkType"
                            rules={[{required: true, message: '请选择跳转类型'}]}
                        >
                            <Radio.Group>
                                <Radio value={3}>APP内</Radio>
                                <Radio value={2}>HTTP</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="跳转地址"
                            name="linkUrl"
                            rules={[{required: true, message: '请输入跳转地址'}]}
                        >
                            <Input/>
                        </Form.Item>
                            <div style={{fontSize:'13px',marginBottom:'10px'}}>
                                *微信小程序站外跳转仅支持：已绑定公众号的文章地址、已配置业务域名下的网页地址
                            </div>
                        <Form.Item
                            label="广告图片"
                            name="picture"
                            rules={[{required: true, message: '请上传广告图片',
                                validator:(rule, value, callback)=>{
                                    if(imgList.length < 1) return Promise.reject('请上传广告图片')
                                    return Promise.resolve('')
                                }}]}
                        >
                            <Upload
                                name="picture"
                                accept="image/*"
                                action={`${API_URL}/api/v1/file/upload`}
                                listType="picture-card"
                                fileList={imgList}
                                onPreview={handlePreview}
                                onChange={res => {
                                    const {fileList} = res;
                                    setImgList(fileList)
                                }}
                            >
                                {imgList.length < 1 && <UploadImgButton/>}
                            </Upload>
                        </Form.Item>
                    </Form>
                    {/*<FormFooter oncancel={()=>history.replace('/market_ads')} onSubmit={submit}/>*/}
                </div>
            </div>
            <div className={'add_footer'}>
                <Space>
                    <Button
                        onClick={()=>history.replace('/market_ads')}
                    >取消
                    </Button>
                    <Button type={"primary"} onClick={submit}>保存</Button>
                </Space>
            </div>
            <Modal
                visible={isPreview}
                footer={null}
                onCancel={() =>setIsPreview(false)
                }
            >
                <img alt="example" style={{width: '100%'}} src={previewImg}/>
            </Modal>
        </div>
    )
};
export default NewAdsBanner;
