import zhCN from 'antd/lib/locale/zh_CN';
import {ConfigProvider, Pagination} from 'antd';
import React from 'react';

const CustomPagination = (props: any) => {
    const {currentPage, total, onChange, pageSize} = props;
    const containerStyle: any = {
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        background: '#FFFFFF',
        justifyContent: 'flex-end',
        borderTop: '1px solid #E9E9E9',
        boxSizing: 'border-box',
        padding: '0 20px'
    };

    return (
        <div style={containerStyle}>
            <ConfigProvider locale={zhCN}>
                <Pagination style={{height: '32px'}}
                            current={currentPage}
                            total={total}
                            pageSize={pageSize}
                            onChange={onChange}
                            showSizeChanger={false}
                            showQuickJumper
                />
            </ConfigProvider>
        </div>
    );

};
export default CustomPagination;
