import React, {useEffect, useState,Fragment} from "react";
import {useHistory} from 'react-router-dom'
import {Col, Form, Input, DatePicker, Radio, Row, Button, Table, Modal, message, Space} from 'antd';
import BraftEditor from "braft-editor";
import moment from 'moment'
import update from 'immutability-helper'

import FormFooter from "../../../../components/form_footer/form_footer";
import RichTextEditor from "../../../../components/rich_text_editor/rich_text_editor";
import {getProduct} from '../../../../service/product.api'
import {updateCoupon} from '../../../../service/market/coupon.api'
import CustomPagination from "../../../../components/custom_pagination/custom_pagination";

import {formatPrice} from '../../../../utils';

const { RangePicker } = DatePicker;
const {Search} = Input;


const PAGE_SIZE = 5;

let selectData:Array<any> = [];
const NewMarketCoupon = () => {
    const history = useHistory();
    const [newCouponForm] = Form.useForm();
    const [page,setPage] = useState(1);
    const [count,setCount] = useState(0);
    const [searchContent,setSearchContent] = useState('');
    const [useRange,setUseRange] = useState(1);//1全场通用2指定商品
    const [productData,setProductData] = useState<Array<any>>([]);

    const [selectKeys,setSelectKeys] = useState<Array<any>>([])
    const [saveData,setSaveData] = useState<Array<any>>([]);
    const [urlData,setUrlData] = useState<any>({});
    const [showTable,setShowTable] = useState(false);
    const [addVisible,setAddVisible] = useState(false);
    const [richText,setRichText] =useState(BraftEditor.createEditorState('<p>222222</p>'));
    const columns = [
        {
            title:'商品名称',
            dataIndex:'name',
            key:'id'
        },
        {
            title:'单价',
            dataIndex:'default_sku',
            key:'id',
            render:(_:string,record:any)=> {
                const {default_sku:{price}}:any = record;
                return `￥${formatPrice(price)}`
            }
        },
        {
            title:'库存',
            dataIndex: 'stock',
            key:'id',
        },
        {
            title:'操作',
            render:(text:any)=>
                <Button
                    type='primary'
                    danger
                    onClick={()=>delProduct(text)}
                >
                    移除
                </Button>
        }
    ]

    useEffect(()=>{
        setShowTable(useRange === 2)
        if(useRange ===1){
            setSaveData([])
        }
    },[useRange])
    useEffect(()=>{
        if (history.location.search){
            const data = JSON.parse(decodeURIComponent(history.location.search).substring(6))
            setUrlData(data)
            console.log('编辑信息',data)
            const {use_type,product_list} = data;
            setUseRange(use_type);
            console.log('商品信息',product_list)
            setSaveData(product_list.map((item:any)=>Object.assign({},item,{'key':item.id})));
        }
    },[])

    useEffect(()=>{
        setForm()
    },[urlData])
    useEffect(()=>{
        getProductData().then()
    },[page])
    useEffect(()=>{
        setPage(1)
        getProductData().then()
    },[searchContent])
    useEffect(()=>{
        newCouponForm.setFieldsValue({
            use_type:useRange
        })
    },[useRange])
    useEffect(()=>{
        setSelectKeys(update(saveData,{
            $apply:()=>saveData.map((item:any)=>item.key)
        }))
    },[saveData])
    /**
     * 表单初始化
     */
    const setForm = ()=>{
        const {name,note,amount,publish_count,per_limit,start_time,end_time,content} = urlData
        console.log(urlData)
        newCouponForm.setFieldsValue({
            name,
            note,
            amount,
            publish_count,
            per_limit,
            rangeTime:start_time&&end_time?[
                moment(start_time),
                moment(end_time)
            ]:[],
            content:BraftEditor.createEditorState(content),
        })
    }
    /**
     * 指定商品
     */
    const getProductData = async ()=>{
        const {result:{data,count}}:any = await  getProduct({
            type:2,
            kwd:searchContent,
            page,
            size:PAGE_SIZE
        })
        const table = [...data]
        table.forEach((item:any)=>{
            item.key = item.id;
        })
        setProductData(table);
        setCount(count);
    }
    /**
     * 移除商品
     */
    const delProduct = (text:any)=>{
        const {key} = text
        const table = [...saveData]
        setSaveData(update(saveData,{
            $splice:[[table.findIndex(item=>item.key === key),1]]
        }))
    }
    /**
     * 完成添加
     */
    const handlerAdd = ()=>{
        setAddVisible(false);
        setSaveData([...selectData]);
    }
    /**
     * 提交表单
     */
    const onSubmit = async()=>{
        try{
            await newCouponForm.validateFields()
            const formData = await newCouponForm.getFieldValue([])
            const {rangeTime,amount,per_limit,publish_count,content} = formData;
            const data = update(formData,{
                id:{$set:Object(urlData).length!==0?urlData.id:0},
                start_time:{$set:rangeTime[0].valueOf()},
                end_time:{$set:rangeTime[1].valueOf()},
                amount:{$set:Number(amount) * 100},
                per_limit:{$set:Number(per_limit)},
                publish_count:{$set:Number(publish_count)},
                product_ids: {
                    $apply:()=>selectKeys.map(item => ({product_id: item}))
                },
                content:{$set:content}
            })
            await updateCoupon(data);
            message.success('提交成功')
            history.replace('/market_coupon')
        }catch (e){
            console.log(e)
        }
    }
    /**
     * 面值校验
     */
    const amountValid = ()=>{
        const input = newCouponForm.getFieldValue(['amount'])
        if(input <1) return Promise.reject('优惠券面值不能小于1')
        if((/^([1-9]+[\d]*(.[0-9]{1,2})?)$/g).test(String(input))){
            return Promise.resolve()
        }else{
            return Promise.reject('优惠券面值只能是数值，最多精确至 2 位小数点')
        }
    }
    /**
     * 搜索商品
     * @param content
     */
    const searchProduct = async(content:string)=>{
        setSearchContent(content);
    }
    /**
     * 总发行量校验
     * @param a
     * @constructor
     */
    const countValid = ()=>{
        const input = newCouponForm.getFieldValue(['publish_count'])
        if(input < 1 ||!input) return Promise.reject('总发行量不得小于1')
        return Promise.resolve()
    }
    /**
     * 限领校验
     * @param a
     * @constructor
     */
    const limitValid = ()=>{
        const input = newCouponForm.getFieldValue(['per_limit'])
        if(input < 1 || !input) return Promise.reject('每人限领数量不得小于1')
        return Promise.resolve()
    }

    const rowSelection = {
        selectedRowKeys:selectKeys,
        onChange: (selectedRowKeys: any, selectedRows: any) => {
            selectData = selectedRows;
            setSelectKeys(selectedRowKeys)
        },
    };
    return(
        <div className={'add_body_wrap'}>
            <div className={'add_body'}>
                <div className={'form_wrap'}>
                    <Form
                        form={newCouponForm}
                        name="newCouponForm"
                        labelAlign={'left'}
                        layout={"vertical"}
                        colon={true}
                    >
                        <div className={'title'}>
                            基本信息
                        </div>
                        <Form.Item
                            label="优惠券名称"
                            name="name"
                            rules={[{required: true, message: '请输入优惠券名称'}]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="优惠券描述"
                            name="note"
                        >
                            <Input maxLength={32}/>
                        </Form.Item>

                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label='面值（¥）'
                                    name="amount"
                                    rules={[{required: true, validator: amountValid }]}
                                >
                                    <Input type='number' style={{width:'140px'}}/>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="总发行量"
                                    name="publish_count"
                                    rules={[{required: true, validator: countValid }]}
                                >
                                    <Input type='number' style={{width:'140px'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item
                            label="每人限领"
                            name="per_limit"
                            rules={[{ required: true,validator: limitValid }]}
                        >
                            <Input type='number' style={{width:'140px'}}/>
                        </Form.Item>
                        <Form.Item
                            label="有效期"
                            name="rangeTime"
                            rules={[{required: true,message:'请选择有效期'}]}
                        >
                            <RangePicker
                                placeholder={['年/月/日','年/月/日']}
                                showTime={{ format: 'YYYY-MM-DD HH:mm' }}
                                format="YYYY-MM-DD HH:mm"
                            />
                        </Form.Item>
                        <Form.Item
                            label="可用范围"
                            name="use_type"
                            rules={[{required: true,message:'请选择可用范围',validator:()=>{
                                    if(useRange!==0) return Promise.resolve()
                                    return Promise.reject('请选择可用范围')
                                }}]}
                        >
                            <Radio.Group value={useRange} onChange={(e)=>setUseRange(e.target.value)}>
                                <Radio value={1}>全场通用</Radio>
                                <Radio value={2}>指定商品</Radio>
                            </Radio.Group>
                            {
                                showTable &&
                                    <Fragment>
                                        <Table
                                            dataSource={saveData}
                                            columns={columns}
                                            pagination={false}
                                        />
                                        <div
                                            style={{width:'100%',display:'flex',justifyContent:"flex-end",marginTop:20}}
                                        >
                                            <Button
                                                type='primary'
                                                onClick={()=> {
                                                    setAddVisible(true)
                                                    getProductData().then()
                                                }}
                                            >添加列表
                                            </Button>
                                        </div>
                                    </Fragment>
                            }
                        </Form.Item>

                        <Form.Item
                            label="优惠券主页"
                            name="content"
                            rules={[{required: true,message:"请填写优惠券主页", validator:(rule, value, callback)=>{
                                    if(!value || value === '<p></p>') return Promise.reject('请填写优惠券')
                                    return Promise.resolve();
                                }}]}
                        >
                            <RichTextEditor
                                onchange={(e:any)=>{
                                    console.log(e)
                                    newCouponForm.setFieldsValue({
                                    content:e.toHTML()
                                })}}
                                value={richText}
                            />
                        </Form.Item>
                    </Form>
                    {/*<FormFooter oncancel={()=>history.replace('/market_coupon')} onSubmit={onSubmit}/>*/}
                </div>
            </div>
            <div className={'add_footer'}>
                <Space>
                    <Button
                        onClick={()=>history.replace('/market_coupon')}
                    >取消
                    </Button>
                    <Button type={"primary"} onClick={onSubmit}>保存</Button>
                </Space>
            </div>
            <Modal
                width={'800px'}
                title="指定商品"
                visible={addVisible}
                onOk={handlerAdd}
                onCancel={()=>{setAddVisible(false)}}
                okText="确认"
                cancelText="取消"
                centered={true}
                destroyOnClose={true}
                // afterClose={()=>addForm.resetFields()}
            >
                <Row>
                    <Col span={24}>
                        <Search
                            className={'search'}
                            placeholder="请输入"
                            onSearch={(content)=>searchProduct(content)}
                            enterButton="搜索"
                            size={'large'}
                        />
                    </Col>
                    <Col span={24}>
                        <Table
                            dataSource={productData}
                            pagination={false}
                            columns={[...columns].splice(0,3)}
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                            }}
                        />
                        <CustomPagination
                            currentPage={page}
                            total={count}
                            onChange={(page:number)=>setPage(page)}
                            pageSize={PAGE_SIZE}
                        />
                    </Col>

                </Row>
            </Modal>
        </div>
    )
};
export default NewMarketCoupon;
