import {Button, Form, Input, Radio, Space} from 'antd';
import React from 'react';
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const Oss = () => {
    const [form] = Form.useForm();
    const onFinish = (values: any) => {
        console.log(values);
    };
    return <div className='add_body_wrap'>
      <div className='add_body'>
          <div className='form_wrap'>
              <Form {...layout} form={form} name="control-hooks" onFinish={onFinish} initialValues={{
                  isOpen:1
              }}>
                  <Form.Item name="isOpen" label="是否启用" rules={[{ required: true }]}>
                      <Radio.Group>
                          <Radio value={1}>是</Radio>
                          <Radio value={2}>否</Radio>
                      </Radio.Group>
                  </Form.Item>
                  <Form.Item name="accessKeyId" label="accessKeyId" rules={[{ required: true }]}>
                      <Input />
                  </Form.Item>
                  <Form.Item name="accessKeySecret" label="accessKeySecret" rules={[{ required: true }]}>
                      <Input />
                  </Form.Item>
                  <Form.Item name="bucket" label="bucket" rules={[{ required: true }]}>
                      <Input />
                  </Form.Item>
                  <Form.Item name="endpoint" label="Endpoint(区域节点)" rules={[{ required: true }]}>
                      <Input />
                  </Form.Item>
                  <Form.Item name="space" label="空间域名" rules={[{ required: true }]}>
                      <Input />
                  </Form.Item>
              </Form>
          </div>
          <div className={'add_footer'}>
              <Space>
                  <Button type={"primary"} onClick={()=>{
                      form.submit()
                  }}>保存</Button>
                  <Button type={"primary"} onClick={()=>{
                      form.resetFields()
                  }}>重置</Button>
              </Space>
          </div>
      </div>
  </div>
}
export default Oss
