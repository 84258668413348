import {Get, Post} from '../utils/request';

export const CreateNewCategory = (req: ICreateNewCategory) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await Post('/api/v1/category/update', req);
            resolve(res);
        } catch (e) {
            //reject(e);
        }
    });
};

export const GetCategory = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/category/query');
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};
export const DeleteCategory = (id: number) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await Post('/api/v1/category/delete', {product_category_id: id});
            resolve(res);
        } catch (e) {
            //reject(e);
        }
    });
};
export const CreateAttributeCategory = (name: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = await Post('/api/v1/attribute_category/add', {name: name});
            resolve(res);
        } catch (e) {
            //reject(e);
        }
    });
};

export const GetAttributeCategoryList = (req: IGetAttributeCategoryList) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Post('/api/v1/attribute_category/list', req);
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const TransferCategoryProduct = (from: number, to: number) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Post('/api/v1/category/product/transfer', {
                product_category_id_form: from,
                product_category_id_to: to
            });
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};

export const LoginRequest = (req: ILogin) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Post('/api/v1/user/login', req);
            resolve(res.result);
        } catch (e) {
            reject(e);
        }
    });
};
