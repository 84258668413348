import {Post,Get} from '../../utils/request'

/**
 * 获取订单配置列表
 * @param req
 */
export const getOrderOptions = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Get('/api/v1/order/system/info', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * 保存订单配置列表
 * @param req
 */
export const  updateOrderOptions= (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/order/system/update', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};

