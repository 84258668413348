import {Post,Get} from '../../utils/request'

/**
 * 敏感词
 * @param req
 */
export const getList = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Get('/api/v1/word/query', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * 添加敏感词
 * @param req
 */
export const add = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/word/add', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};

/**
 * 删除敏感词
 * @param req
 */
export const del = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/word/delete', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * 用户名处理方式
 * @param req
 */
export const userChange = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/word/ban', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * 用户名处理方式
 * @param req
 */
export const commentChange = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/word/comment', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
