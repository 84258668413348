import {Table} from 'antd';
import React, {useEffect, useState} from 'react';
import CustomPagination from '../../../components/custom_pagination/custom_pagination';
import {searchObj} from '../../../utils';
import {GetHotWords, GetLeaderboards} from '../../../service/home';

let params = {
    radio: '1',
    type: '1'//1=热搜词 2=排行榜
};
const PAGE_SIZE = 20;
const HomeList = () => {
    const [page, setPage] = useState<number>(1);
    const [count, setCount] = useState<number>(0);
    const [data, setData] = useState<Array<any>>([]);
    const [columns, setColumns] = useState<Array<any>>([]);

    useEffect(() => {
        params = searchObj(`?${window.location.href.split('?')[1]}`);
        if (params.type == '1') {
            setColumns([
                {
                    title: '排名',
                    dataIndex: 'sort',
                    key: 'sort',
                    width: 100
                },
                {
                    title: '搜索关键词',
                    dataIndex: 'keywords',
                    key: 'keywords',
                    render: (text: string) => {
                        return (
                            <div style={{color: '#1890FF'}}>{text}</div>
                        );
                    }
                },
                {
                    title: '搜索次数',
                    dataIndex: 'count',
                    key: 'count',
                    width: 100,
                    render:(text:number) => (
                        `${text}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
                    )
                },
            ]);
        } else {
            setColumns([
                {
                    title: '排名',
                    dataIndex: 'sort',
                    key: 'sort',
                    width: 100
                },
                {
                    title: '商品名称',
                    dataIndex: 'name',
                    key: 'name',
                    render: (text: string) => {
                        return (
                            <div style={{color: '#1890FF'}}>{text}</div>
                        );
                    }
                },
                {
                    title: '销量',
                    dataIndex: 'sale',
                    key: 'sale',
                    width: 140,
                    render:(text:number) => (
                        `${text}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
                    )
                },
                {
                    title: '收益',
                    dataIndex: 'income',
                    key: 'income',
                    width: 140,
                    render:(text:number) => (
                        `${text}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
                    )
                },
            ]);
        }
    }, []);

    useEffect(() => {
        if (params.type == '1') {
            getHotWords().then();
        } else {
            getLeaderboards().then();
        }
    }, [page]);

    const getHotWords = async () => {
        const res: any = await GetHotWords({
            page: page,
            size: PAGE_SIZE,
            platform: params.radio == '1' ? 'app' : 'mini'
        });
        setData(res.array);
        setCount(res.count);
    };
    const getLeaderboards = async () => {
        const res: any = await GetLeaderboards({
            page: page,
            size: PAGE_SIZE,
            week: params.radio == '1' ? 7 : 8
        });
        setData(res.array);
        setCount(res.count);
    };

    return (
        <div className="content_container">
            <div className={'main_wrap'}>
                <div className={'main_container'}>
                    <div className={'table_wrap'} style={{paddingTop: 24}}>
                        <Table dataSource={data} columns={columns} pagination={false} scroll={{y: 720}}/>
                    </div>
                </div>
                <CustomPagination
                    currentPage={page}
                    total={count}
                    onChange={(page: number) => {
                        setPage(page);
                    }}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </div>
    );
};
export default HomeList;
