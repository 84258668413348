import React, {useEffect, useState} from 'react';
import {Col, Row, Select, Input, DatePicker, Table, Image} from 'antd';
import {getList} from '../../../service/member/feedback.api';
import moment, {Moment} from 'moment';
import CustomPagination from '../../../components/custom_pagination/custom_pagination';

const {Option} = Select;
const {Search} = Input;
const {RangePicker} = DatePicker;
const PAGE_SIZE = 20;
const map = new Map();
map.set(-1, '全部反馈类型');
map.set(1, '功能建议');
map.set(2, '性能问题');
map.set(3, '账号被封');
map.set(4, '单纯吐槽');
const MemberFeedback = () => {
    const [fbType, setFbType] = useState<number>(-1);
    const [tableData, setTableData] = useState<Array<any>>();
    const [page, setPage] = useState<number>(1);
    const [count, setCount] = useState<number>(0);
    const [searchContent, setSearchContent] = useState<string>();
    const [rangeTime, setRangeTime] = useState<[Moment, Moment]>();
    const [visible, setVisible] = useState<boolean>(false);
    const [preViewUrls, setPreviewUrls] = useState<Array<any>>([]);
    const columns = [
        {
            title: '反馈用户',
            dataIndex: 'nick_name',
            key: 'id'
        },
        {
            title: '反馈类型',
            dataIndex: 'back_type',
            key: 'id',
            render: (text: number) => map.get(text)
        },
        {
            title: '反馈描述',
            dataIndex: 'content',
            key: 'id'
        },
        {
            title: '反馈图片',
            dataIndex: 'pics',
            key: 'id',
            render: (text: Array<string>) =>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                    {
                        text && text.map(res => {
                            return (
                                <div style={{margin: 2}}>
                                    <Image
                                        // preview={{visible: false}}
                                        width={54}
                                        height={54}
                                        src={localStorage.getItem('divms_url') + res}
                                        // onClick={() => {
                                        //     setPreviewUrls(text);
                                        //     setVisible(true);
                                        // }}
                                    />
                                </div>
                            );
                        })
                    }
                </div>
        },
        {
            title: '反馈时间',
            dataIndex: 'create_time',
            key: 'id',
            render: (text: number) => moment.unix(text).format('YYYY-MM-DD')
        },
    ];
    useEffect(() => {
        getFeedBackList().then();
    }, [page]);
    useEffect(() => {
        getFeedBackList().then();
    }, [searchContent]);
    const getFeedBackList = async () => {
        try {
            let start = 0;
            let end = 0;
            if (rangeTime) {
                start = rangeTime[0].unix();
                end = rangeTime[1].unix();
            }
            const {result: {data, count}}: any = await getList({
                back_type: fbType,
                page,
                size: PAGE_SIZE,
                start,
                end,
                kwd: searchContent,
            });
            setTableData(data);
            setCount(count);
        } catch (e) {
            console.log(e);
        }
    };
    const onSearch = async (content: string) => {
        setSearchContent(content);
        setPage(1);
        await getFeedBackList();
    };
    return (
        <div className="content_container">
            <div style={{display: 'none'}}>
                <Image.PreviewGroup preview={{visible, onVisibleChange: vis => setVisible(vis)}}>
                    {
                        preViewUrls.map(res => {
                            return (<Image src={localStorage.getItem('divms_url') + res}/>);
                        })
                    }
                </Image.PreviewGroup>
            </div>
            <div className={'main_wrap'}>
                <div className={'main_container'}>
                    <div style={{display: 'flex', alignItems: 'center', margin: '16px 0'}}>
                        <Select value={fbType} onChange={(value) => setFbType(value)} style={{width: '200px'}}>
                            <Option value={-1}>全部反馈类型</Option>
                            <Option value={1}>功能建议</Option>
                            <Option value={2}>性能问题</Option>
                            <Option value={3}>账号被封</Option>
                            <Option value={4}>单纯吐槽</Option>
                        </Select>
                        <div style={{margin: '0 20px'}}>
                            <RangePicker
                                style={{width: '260px'}}
                                value={rangeTime}
                                showTime
                                onChange={(dates: any) => setRangeTime(dates)}
                            />
                        </div>
                        <div style={{width: '500px'}}>
                            <Search
                                placeholder="输入反馈用户、反馈描述进行搜索"
                                onSearch={onSearch}
                                enterButton="搜索"
                                allowClear
                            />
                        </div>
                    </div>
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        pagination={false}
                        scroll={{y: 680}}
                    />
                </div>
                <CustomPagination
                    currentPage={page}
                    total={count}
                    onChange={(page: number) => setPage(page)}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </div>
    );
};
export default MemberFeedback;
