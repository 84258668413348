import {Post,Get} from '../../utils/request'

/**
 * 水印详情
 * @param req
 */
export const getWaterMark = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Get('/api/v1/watermark/info',req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * 更新水印信息
 * @param req
 */
export const updateWater = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/watermark/update',req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
