import React, {useEffect, useState} from 'react';
import {Col, Row, Tabs, Radio, Button, Modal, message, Space} from 'antd';
import BraftEditor, {EditorState} from 'braft-editor';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import RichTextEditor from '../../../components/rich_text_editor/rich_text_editor';
import {getInfo, updateInfo} from '../../../service/setting/pact.api';
import './pact.less';

const {TabPane} = Tabs;
const PactSetting = () => {
    const [type, setType] = useState(1);
    const [richText1, setRichText1] = useState(BraftEditor.createEditorState('<p></p>'));
    const [richText2, setRichText2] = useState(BraftEditor.createEditorState('<p></p>'));
    const [pactInfo, setPactInfo] = useState<Array<any>>();
    useEffect(() => {
        getPact().then();
    }, []);
    const getPact = async () => {
        try {
            const {result}: any = await getInfo({});
            setPactInfo(result);
            result.forEach((item: any) => {
                const {name, value} = item;
                if (name === 'agreement_privacy') setRichText1(BraftEditor.createEditorState(value));
                if (name === 'agreement_license') setRichText2(BraftEditor.createEditorState(value));
            });
        } catch (e) {
            console.log(e);
        }
    };
    const changeTab = (e: string) => {
        const value = Number(e);
        if (type === value) return;
        setType(type === 1 ? 2 : 1);
    };
    const reset = () => {
        Modal.confirm({
            content: `是否重置${type === 1 ? '隐私政策' : '用户协议'}的编辑内容?`,
            icon: <ExclamationCircleOutlined/>,
            centered: true,
            bodyStyle: {
                fontSize: 16,
            },
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk: () => {
                pactInfo?.forEach((item: any) => {
                    const {name, value} = item;
                    if (name === 'agreement_privacy' && type === 1) setRichText1(BraftEditor.createEditorState(value));
                    if (name === 'agreement_license' && type === 2) setRichText2(BraftEditor.createEditorState(value));
                });
            }
        });
    };
    const save = async () => {
        try {
            await updateInfo([
                {name: 'agreement_privacy', value: richText1.toHTML()},
                {name: 'agreement_license', value: richText2.toHTML()},
            ]);
            message.success('保存成功');
            await getPact();
        } catch (e) {
            console.log(e);
        }


    };
    return (
        <div className={'main_wrap'}>
            <div className={'contain_wrap'}>
                <div className={'pact_wrap'}>
                    <Row>
                        <Col span={24}>
                            <div className={'tab'}>
                                <Tabs
                                    defaultActiveKey={'1'}
                                    onChange={changeTab}
                                >
                                    <TabPane tab="隐私政策" key={'1'}/>
                                    <TabPane tab="用户协议" key={'2'}/>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            {
                                type === 1 ?
                                    <RichTextEditor
                                        contentStyle={{border: '1px solid #c0c2c4', minHeight: 500}}
                                        value={richText1}
                                        onchange={(e: EditorState) => setRichText1(e)}
                                    /> :
                                    <RichTextEditor
                                        contentStyle={{width: '100%', border: '1px solid #c0c2c4', minHeight: 500}}
                                        value={richText2}
                                        onchange={(e: EditorState) => setRichText2(e)}
                                    />
                            }
                        </Col>
                    </Row>
                    {/*<Row style={{marginTop: 20}} gutter={0}>*/}
                    {/*    <Col span={2} offset={0}>*/}
                    {/*        <Space>*/}
                    {/*            <Button onClick={reset}>重置</Button>*/}
                    {/*            <Button type={'primary'} onClick={save}>保存</Button>*/}
                    {/*        </Space>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <div className={'add_footer'}>
                        <Space>
                            <Button onClick={reset}>重置</Button>
                            <Button type={'primary'} onClick={save}>保存</Button>
                        </Space>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default PactSetting;
