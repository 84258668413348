import React, {useEffect, useState} from 'react'
import {Button, Cascader, Form, Modal, Space, Table, Input, Col, Select, Row, message, Divider} from 'antd';
import Regions from 'chinese_regions';
import TableHead from "../../../components/table_head/table_head";
import showDeleteConfirm from "../../../components/confirm_delete/confirm_delete";
import {getList,updateAddress,delShop,addShop} from '../../../service/setting/shop.api.tx'
import CustomPagination from "../../../components/custom_pagination/custom_pagination";
import RegionSelect from "../../../components/region_select/regionSelect";


const {province} = Regions;
const PAGE_SIZE = 20;
const {Option} = Select
let id:number|null = null;
const ShopSetting = () => {
  const [shopForm] = Form.useForm();
  const [visible,setVisible] = useState<boolean>(false);
  const [page,setPage] = useState(1)
  const [count,setCount] = useState(0)
  const [searchContent,setSearchContent] = useState<string>('');
  const [tableData,setTableData] = useState<Array<any>>();
  const [confirmLoading,setConfirmLoading] =useState<boolean>(false);
  const [receiver_province, setReceiver_province] = useState<string>();
  const [receiver_city, setReceiver_city] = useState<string>();
  const [receiver_region, setReceiver_region] = useState<string>();

  const columns = [
    {
      title:'门店名称',
      dataIndex:'address_name',
      key:'id'
    },
    {
      title:'收件地址',
      key:'id',
      render:(text:any,record:any)=>{
        const {name,phone,province,city,region,detail_address} = record
        return (
            <div>
              <div style={{marginBottom:8}}>
                <span style={{marginRight:10}}>{name}</span>
                <span>{phone}</span>
              </div>
              <div style={{marginBottom:8}}>
                <span style={{marginRight:10}}>{province}</span>
                <span style={{marginRight:10}}>{city}</span>
                <span>{region}</span>
              </div>
              <div style={{marginBottom:8}}>
                {detail_address}
              </div>
            </div>
        )
      }

    },
    {
      title:'操作',
      width:200,
      render:(text:any,record:any)=>(
          <Space size={'small'}>
            <a onClick={()=>edit(record)}>编辑</a>
            <Divider type="vertical"/>
            <a onClick={()=>showDeleteConfirm(record,'是否删除该门店',delItem)}>删除</a>
          </Space>
      )
    }
  ]
  useEffect(()=>{
    getShopList().then()
  },[page,searchContent])

  const getShopList = async ()=>{
    const {result:{data,count}}:any = await getList({
      page,
      size:PAGE_SIZE,
      kwd:searchContent
    })
    setTableData(data)
    setCount(count)
  }
  const onSearch = async (content:string)=>{
    setSearchContent(content)
    setPage(1)
    // await getShopList()
  }

  const delItem = async (record:any)=>{
    try{
      const {id} = record;
      if(!id) return;
      await delShop({id})
      message.success('删除成功')
      await getShopList()
    }catch (e){
      console.log(e)
    }
  }
  const edit = (record:any)=>{
    id = record.id;
    const {province,city,region,name,detail_address,address_name,phone} = record
    shopForm.setFieldsValue({
      name:address_name,
      person:name,
      phone,
      address:detail_address,
    })
    setReceiver_province(province)
    setReceiver_city(city)
    setReceiver_region(region)
    setVisible(true)
  }
  const saveAdd = async()=>{
    const {address,name,person,phone} = shopForm.getFieldsValue()
    const data ={
      address_name:name,
      name:person,
      phone,
      province:receiver_province,
      city:receiver_city,
      region:receiver_region,
      detail_address:address
    }
    try{
      if(id){
        await updateAddress(Object.assign(data,{id}))
        message.success('更新成功')
      }else{
        await addShop(data)
        message.success('添加成功')
      }
      cancel()
      await getShopList()
    }catch (e){
      console.log()
    }
  }
  const cancel = ()=>{
    setReceiver_province('')
    setReceiver_city('')
    setReceiver_region('')
    shopForm.resetFields()
    id=null;
    setVisible(false)
  }

  return(
      <div className='main_wrap'>
        <div className='main_container'>
          <TableHead onSearch={onSearch} addType="新增门店" onClick={()=>setVisible(true)}/>
          <Table dataSource={tableData} columns={columns} pagination={false} scroll={{y: 680}}/>
        </div>
        <CustomPagination
            currentPage={page}
            total={count}
            onChange={(page:number)=>setPage(page)}
            pageSize={PAGE_SIZE}
        />
        <Modal
            title="门店信息"
            visible={visible}
            onOk={saveAdd}
            onCancel={cancel}
            okText="确认"
            cancelText="取消"
            confirmLoading={confirmLoading}
            centered={true}
        >
          <Form
              name='shopForm'
              form={shopForm}
              labelAlign={'left'}
              layout={"horizontal"}
              labelCol={{span:6}}
              wrapperCol={{span:18}}
              colon={true}
          >
            <Form.Item
                label='门店名称'
                name='name'
                rules={[{required: true, message: '请输入门店名称'}]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                label='收件人'
                name='person'
                rules={[{required: true, message: '请输入收件人'}]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                label='手机号码'
                name='phone'
                rules={[{required: true, message: '请输入手机号码'}]}
            >
              <Input/>
            </Form.Item>
            <Form.Item
                label='所在地区'
                name='distract'
                rules={[{required: true, message: '请输入所在地区'}]}
            >
              <RegionSelect
                  province={receiver_province}
                  city={receiver_city}
                  county={receiver_region}
                  provinceChange={(value:string)=>setReceiver_province(value)}
                  cityChange={(value:string)=>setReceiver_city(value)}
                  countyChange={(value:string)=>setReceiver_region(value)}
              />
            </Form.Item>
            <Form.Item
                label='详细地址'
                name='address'
                rules={[{required: true, message: '请输入地址'}]}
            >
              <Input/>
            </Form.Item>
          </Form>
        </Modal>
      </div>
  )
}
export default ShopSetting
