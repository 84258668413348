import {message} from 'antd';
import {createHashHistory} from 'history';

let token: string = String(localStorage.getItem('divms_login_token'));

export const SetToken = (res: string) => {
    token = res;
};

export const HasToken = () => {
    return token && token !== 'null';
};

export const ClearToken = () => {
    token = '';
};

// export const API_URL = 'http://192.168.110.70/admin';
export const API_URL = 'http://mall-test.divms.com/admin';

const request = (method: any, url: string, data?: any) => {
    if (data && method == 'GET') {
        url += '?';
        for (let i in data) {
            if (data.hasOwnProperty(i)) {
                url += `${i}=${data[i]}&`;
            }
        }
        url = url.substr(0, url.length - 1);
    }
    return (new Promise((resolve, reject) => {
        fetch(API_URL + url, method == 'GET' ? {
            method: method,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'token': token
            },
            mode: 'cors'
        } : {
            method: method,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                'token': token
            },
            body: data ? JSON.stringify(data) : '',
            mode: 'cors'
        })
            .then(res => res.json())
            .then(data => {
                if (data.code === 0) {
                    resolve(data);
                } else {
                    message.error(data.msg);
                    reject(data);
                }
                if (data.code === 1000) {
                    createHashHistory().replace('/login');
                }
            })
            .catch((err: any) => {
                reject(err);
            });
    }));
};

export const Get = (url: string, data?: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await request('GET', url, data));
        } catch (e) {
            reject(e);
        }
    });
};

export const Post = (url: string, data?: any) => {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await request('POST', url, data));
        } catch (e) {
            reject(e);
        }
    });
};
