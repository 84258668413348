import {Get, Post} from "../../utils/request";
import {IGetAdminList, IGetRoleList, IUpdateAdmin, IUpdateRole} from "../../interface/setting.interface";
import React from "react";

export const updateRole = (req: IUpdateRole) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/role/update', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
export const getRoleListApi = (req: IGetRoleList) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/role/list', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
export const deleteRoleApi = (id: number) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/role/delete', {id});
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
export const getAssortApi = (id: number) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Get('/api/v1/role/premission/list', {role_id: id});
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};

export const updateAssortApi = (id: number, permissionId: Array<React.Key>) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/role/premission/bind', {role_id: id, permission_id: permissionId});
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};

export const getAdminListApi = (req: IGetAdminList) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/admin/query', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
export const updateAdmin = (req: IUpdateAdmin) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/admin/update', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
export const deleteAdminApi = (id: number) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/admin/delete', {id});
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
