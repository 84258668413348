import React from 'react'
import {Select} from 'antd'

const {Option} = Select
const OrderSelect = (props:{value:number,onChange:Function})=>{
    const {value,onChange} = props
    return (
        <div style={{width:50}}>
            <Select  value={value} onChange={(value)=>onChange(Number(value))}>
                <Option value={1}>分钟</Option>
                <Option value={2}>天</Option>
            </Select>
        </div>

    )
}
export default OrderSelect
