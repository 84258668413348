import React, {useEffect, useState} from 'react'
import {Button, Col, Modal, Row, Select, Table, Input, message} from "antd";
import TableHead from "../../../components/table_head/table_head";
import showDeleteConfirm from "../../../components/confirm_delete/confirm_delete";
import CustomPagination from "../../../components/custom_pagination/custom_pagination";
import {getList,add,del,userChange,commentChange} from '../../../service/setting/sensitive_words.api'

const {Option} = Select;
const {TextArea} = Input;
const PAGE_SIZE = 20;
const SensitiveWordsSetting = () => {
    const [tableData,setTableData] = useState<Array<any>>([]);
    const [visible,setVisible] = useState<boolean>();
    const [words,setWords] = useState<string>('')
    const [page,setPage] = useState<number>(1);
    const [count,setCount] = useState<number>(0);
    const [searchContent,setSearchContent] = useState<string>('');
    const [confirmLoading,setConfirmLoading] = useState(false)
    const columns = [
        {
            title:'过滤词名',
            dataIndex:'name',
            key:'id'
        },
        {
            title:'用户处理方式',
            dataIndex: 'op_nickname',
            render:(text:any,record:any)=>(
                <Select value={text} onChange={(value)=>userDealChange(record,value)} style={{width:120}}>
                    <Option value={2}>
                        禁用
                    </Option>
                    <Option value={1}>
                        不处理
                    </Option>
                </Select>
            )
        },
        {
            title:'评论处理方式',
            dataIndex: 'op_comment',
            render:(text:any,record:any)=>(
                <Select  value={text} onChange={(value)=>commentDealChange(record,value)} style={{width:120}}>
                    <Option value={2}>
                        禁用
                    </Option>
                    <Option value={1}>
                        不处理
                    </Option>
                </Select>
            )
        },
        {
            title:'操作',
            width:200,
            render:(text:any,record:any)=>(
                <a onClick={()=>showDeleteConfirm(record,'是否删除该敏感词',delItem)}>删除</a>
            )
        }

    ]
    useEffect(()=>{
        getSensitive().then()
        console.log(page)
    },[page])
    useEffect(()=>{
        setPage(1)
        getSensitive().then()
    },[searchContent])
    const getSensitive = async ()=>{
        const {result:{data,count}}:any = await getList({
            page,
            size:PAGE_SIZE,
            kwd:searchContent
        })
        setTableData(data)
        setCount(count)
    }
    const userDealChange = async(record:any,value:number)=>{
        const {id} = record;
        await userChange({
            id,
            op_nickname:value
        })
        await getSensitive()
    }
    const commentDealChange = async (record:any,value:number)=>{
        const {id} = record;
        await commentChange({
            id,
            op_comment:value
        })
        await getSensitive()
    }
    const delItem = async(record:any)=>{
        const {id} = record
        try{
            await del({ids:String(id)})
            message.success('删除成功')
            await getSensitive()
        }catch (e){
            console.log(e)
        }
    }
    const onSearch = async (content:string)=>{
        setSearchContent(content)
    }
    const saveAdd = async ()=>{
        try{
            if(words.trim().length === 0){
                message.warn('内容不可为空')
                return;
            }
            const arr = words.trim().split(',')
            let flag = true;
            arr.forEach((item:string)=>{
                if(item.length > 16) flag = false;
            })
            if(!flag){
                message.warn('单词长度不得超过16位字符')
                return;
            }
            const dedupeArr = Array.from(new Set(arr))
            const word = dedupeArr.join(',')
            await add({word})
            message.success('添加成功')
            setVisible(false)
            await getSensitive()
            cancel()
        }catch (e){
            console.log(e)
        }
    }
    const cancel = ()=>{
        setWords('')
        setVisible(false);
    }
    return (
        <div className='main_wrap'>
            <div className='main_container'>
                <TableHead onSearch={onSearch} addType="批量新增" onClick={()=>setVisible(true)}/>
                <Table dataSource={tableData} columns={columns} pagination={false} scroll={{y: 680}}/>
            </div>
            <CustomPagination
                currentPage={page}
                total={count}
                onChange={(page:number)=> {setPage(page)}}
                pageSize={PAGE_SIZE}
            />
            <Modal
                title="新增敏感词"
                visible={visible}
                footer={null}
                onCancel={cancel}
                confirmLoading={confirmLoading}
                centered={true}
                width={600}
            >
                <Row>
                    <Col span={24} style={{fontSize:13}}>批量新增过滤词名：</Col>
                    <Col span={24}>
                        <TextArea
                            rows={7}
                            value={words}
                            onChange={(e)=>setWords(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop:20}}>
                    <Col span={17} style={{fontSize:12}}>
                        <p>- 批量添新增时，使用英文逗号“,”隔开，单词长度不得超过16位字符；</p>
                        <p>- 新增成功后，默认为“禁止”状态；</p>
                        <p>- 新增过滤词名与现存过滤词名相同时，新增过滤词将跳过；</p>
                    </Col>
                    <Col span={3} style={{alignContent:'flex-end'}}>
                        <br/>
                        <br/>
                        <Button onClick={cancel}>取消</Button>
                    </Col>
                    <Col span={4}>
                        <br/>
                        <br/>
                        <Button onClick={saveAdd} type={"primary"}>确定新增</Button>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
};
export default SensitiveWordsSetting;
