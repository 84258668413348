import {Post,Get} from '../../utils/request'

/**
 *
 * 获取系统设置
 * @param req
 */
export const getSys = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Get('/api/v1/system/info', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 *
 * 保存系统设置
 * @param req
 */
export const saveSetting = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/system/update', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
