import React, {useEffect, useReducer} from 'react';
import {useHistory} from 'react-router-dom';

import {getCouponHistory} from '../../../../service/market/coupon.api';
import UI from './UI';

/**
 * 优惠券详情上下文
 */
export const CouponDetailContext = React.createContext<any>(null);
const PAGE_SIZE = 5;
const couponInitData: any = {
    id: '',
    start_time: '',
    end_time: '',
    name: '',
    amount: '',
    note: '',
    publish_count: '',
    per_limit: '',
    use_type: '',
    tableData: [],
    page: 1,
    count: 0,
    searchContent: '',
    gainRange: null,
    useRange: null

};

const couponReducer = (state: typeof couponInitData, action: IAction) => {
    switch (action.type) {
        case 'reset':
            return couponInitData;
        case 'setDetail':
            return Object.assign({}, state, action.value);
        case 'setTableData':
            const {total, data} = action.value;
            return Object.assign({}, state, {count: total, tableData: data});
        case 'setCount':
            return Object.assign({}, state, action.value);
        case 'setPage':
            return Object.assign({}, state, action.value);
        case 'setSearchContent':
            return Object.assign({}, state, {searchContent:action.value});
        case 'setGainRange':
            return Object.assign({}, state, {gainRange: action.value});
        case 'setUseRange':
            return Object.assign({}, state, {useRange: action.value});
        default:
            return Object.assign({}, state);
    }
};
const MarketCouponDetail = () => {
    const history = useHistory();
    const [couponData, couponDispatch] = useReducer(couponReducer, couponInitData);
    const {id, page, searchContent, gainRange, useRange} = couponData;
    useEffect(() => {
        if (history.location.search) {
            const data = JSON.parse(decodeURIComponent(history.location.search).substring(6));
            console.log('优惠券信息', data);
            couponDispatch({
                type: 'setDetail',
                value: data
            });
        }

    }, []);
    // useEffect(() => {
    //     if (page === 1) return;
    //     getHistory().then();
    // }, [page]);
    useEffect(() => {
        if (id) getHistory().then();
    }, [id, page, gainRange, useRange, searchContent]);
    const getHistory = async () => {
        try {
            const {result}: any = await getCouponHistory({
                coupon_id: id,
                page,
                size: PAGE_SIZE,
                kwd: searchContent,
                gain_start: gainRange ? gainRange[0].unix() : '',
                gain_end: gainRange ? gainRange[1].unix() : '',
                use_start: useRange ? useRange[0].unix() : '',
                use_end: useRange ? useRange[1].unix() : '',
            });
            couponDispatch({
                type: 'setTableData',
                value: result
            });
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <CouponDetailContext.Provider value={{couponData, couponDispatch}}>
            <UI/>
        </CouponDetailContext.Provider>
    );
};
export default MarketCouponDetail;
