import '../new.less';
import {NewProductContext} from '../new';
import {Input, message, Modal, TreeSelect, Upload} from 'antd';
import React, {useContext, useEffect, useState} from 'react';
import {API_URL} from '../../../../../utils/request';
import UploadImgButton from '../../../../../components/upload_Img_button/upload_Img_button';
import {getBase64} from '../../../../../utils';
import {EditorState} from 'braft-editor';
import {GetCategory} from '../../../../../service/public.apis';
import RichTextEditor from '../../../../../components/rich_text_editor/rich_text_editor';

const Step1 = () => {
    const {newProductData, newProductDispatch} = useContext(NewProductContext);
    const {name, category, mainImgList, mainVideoList, productDetailRichText} = newProductData;
    const [categoryList, setCategoryList] = useState<Array<any>>([]);
    const [previewImage, setPreviewImage] = useState<any>(null);
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);

    useEffect(() => {
        getCategory().then();
    }, [category]);

    const getCategory = async () => {
        try {
            let categoryList: any = await GetCategory();
            const processCategoryList = (req: Array<any>, arr: Array<any>) => {
                req.forEach((res: any, index) => {
                    let array = arr.concat();
                    array.push(index);
                    res.indexArray = array;
                    res.children = res.child;
                    res.key = `${res.id}-${res.level}`;
                    res.title = res.name;
                    res.value = `${res.id}-${res.level}`;
                    if (category == res.id) {
                        newProductDispatch({
                            type: 'setCategory',
                            value: res.value
                        });
                    }
                    if ((res.level.toString().substr(1, 1) &&
                            res.level.toString().substr(1, 1) === res.level.toString().substr(0, 1)) ||
                        res.level == 1) {
                        res.disabled=false
                    } else {
                        res.disabled=true
                    }
                    if (res.children) {
                        processCategoryList(res.children, array.concat());
                    }
                });
            };
            processCategoryList(categoryList, []);
            setCategoryList(categoryList);
        } catch (e) {
            console.log(e);
        }
    };

    const categorySelect = (e: any) => {
        let level = e.split('-')[1];
        if ((level.substr(1, 1) &&
                level.substr(1, 1) === level.substr(0, 1)) ||
            level == 1) {
            newProductDispatch({
                type: 'setCategory',
                value: e
            });
        } else {
            newProductDispatch({
                type: 'setCategory',
                value: undefined
            });
            message.warning('请选择末级分类');
        }
    };

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
    };

    return (
        <div className="form_wrap">
            <div className="info_title">基本信息</div>
            <div className="info_line"><span>*</span>商品名称</div>
            <div className="info_line"><Input value={name} placeholder="请输入" onInput={(e: any) => {
                newProductDispatch({
                    type: 'setName',
                    value: e.target.value
                });
            }}/></div>
            <div className="info_line"><span>*</span>商品分类</div>
            <div className="info_line">
                <TreeSelect
                    style={{width: '100%'}}
                    value={category}
                    dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                    treeData={categoryList}
                    placeholder="请选择"
                    treeDefaultExpandAll
                    onChange={(e) => {
                        categorySelect(e);
                    }}
                />
            </div>
            <div className="info_title">商品详情</div>
            <div className="info_line"><span>*</span>商品相册</div>
            <div className="info_line">
                <Upload
                    name="picture"
                    accept="image/*"
                    action={`${API_URL}/api/v1/file/upload`}
                    listType="picture-card"
                    fileList={mainImgList}
                    onPreview={handlePreview}
                    onChange={res => {
                        newProductDispatch({type: 'setMainImgList', value: res.fileList});
                    }}
                >
                    {mainImgList.length < 6 && <UploadImgButton/>}
                </Upload>
                <Modal
                    visible={previewVisible}
                    footer={null}
                    onCancel={() => {
                        setPreviewVisible(false);
                    }}
                >
                    <img alt="example" style={{width: '100%'}} src={previewImage}/>
                </Modal>
            </div>
            <div className="info_line">主图视频</div>
            <div className="info_line">
                <Upload
                    name="picture"
                    accept="video/*"
                    action={`${API_URL}/api/v1/file/upload`}
                    listType="picture-card"
                    fileList={mainVideoList}
                    onChange={res => {
                        newProductDispatch({type: 'setMainVideoList', value: res.fileList});
                    }}
                >
                    {mainVideoList.length < 1 && <UploadImgButton/>}
                </Upload>
            </div>
            <div className="info_line"><span>*</span>详情介绍</div>
            <div className="info_line">
                <RichTextEditor value={productDetailRichText} onchange={(res: EditorState) => {
                    newProductDispatch({type: 'setProductDetailRichText', value: res});
                }}/>
            </div>
        </div>
    );
};
export default Step1;
