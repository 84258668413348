import {Get, Post} from '../../utils/request';

/**
 * 消息列表
 * @param req
 */
export const getNotice = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/notice/list', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * 删除消息
 * @param req
 */
export const delNotice = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Get('/api/v1/notice/delete', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * 新增/修改消息
 * @param req
 */
export const createNotice = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/notice/create', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
