import {Button, Divider, Form, Input, message, Modal, Select, Table} from 'antd';
import React, {useEffect, useState} from 'react';
import CustomPagination from '../../../components/custom_pagination/custom_pagination';
import {IUpdateAdmin} from "../../../interface/setting.interface";
import {
    deleteAdminApi,
    deleteRoleApi,
    getAdminListApi,
    getRoleListApi,
    updateAdmin,
} from "../../../service/setting/authority_group.api";
import {addKey} from "../../../utils";
import moment from "moment";

const {Search} = Input;
const PAGE_SIZE = 10;
let id = 0;
const MemberAdmins = () => {
    const [form] = Form.useForm();
    const [infoVisible, setInfoVisible] = useState<boolean>(false);
    const [searchContent, setSearchContent] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);
    const [tableData, setTableData] = useState<Array<any>>([]);
    const [roles, setRoles] = useState<Array<any>>([]);
    const [role, setRole] = useState<string>('-1')
    const columns = [
        {
            title: '所属权限组',
            dataIndex: 'group_role_name',
            key: 'group_role_name'
        },
        {
            title: '员工名称',
            dataIndex: 'nickname',
            key: 'nickname'
        },
        {
            title: '电话',
            dataIndex: 'account',
            key: 'account'
        },
        {
            title: '邮箱',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: '入职时间',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (text: number) => moment.unix(text).format('YYYY-MM-DD hh:mm:ss')
        },
        {
            title: '操作',
            render: (text: any, record: any) => (
                <>
                    <a onClick={() => {
                        id = record.id;
                        form.setFieldsValue({
                            'name': record.nickname,
                            'phone': record.account,
                            'email': record.email,
                            'role': String(record.group_role)
                        })
                        setInfoVisible(true);
                    }}>编辑</a>
                    <Divider type="vertical"/>
                    <a onClick={() => {
                        Modal.confirm({
                            content: '是否确认删除，删除后将无法恢复',
                            onOk: () => {
                                deleteAdminApi(record.id).then(res => {
                                    message.success('删除成功')
                                    getRoleList()
                                })
                            }
                        })
                    }}>删除</a>
                </>
            )
        }
    ];

    useEffect(() => {
        getRoleListApi({
            page: 1,
            size: 100,
            kwd: ''
        }).then((res: any) => {
            setRoles(res.result.data.map((e: any) => {
                return {
                    key: e.id,
                    name: e.name
                }
            }))
        })
    }, [])

    useEffect(() => {
        getRoleList()
    }, [searchContent, currentPage,role])

    const onSearch = async (content: string) => {
        setSearchContent(content);
        setCurrentPage(1);
        //await getOrderList();
    };

    const getRoleList = () => {
        getAdminListApi({
            page: currentPage,
            size: PAGE_SIZE,
            kwd: searchContent,
            group_role: Number(role)
        }).then((res: any) => {
            setTableData(addKey(res.result.data, 'id'))
            setCount(res.result.count)
        })
    }

    const createRole = (req: IUpdateAdmin) => {
        updateAdmin(req).then(res => {
            message.success(id === 0 ? '新建成功' : '修改成功')
            setInfoVisible(false);
            form.resetFields(['name', 'phone', 'password', 'email', 'role']);
            getRoleList()
        }).catch(err => {
            message.error(String(err))
        })
    }

    return (
        <div className="content_container">
            <div className={'main_wrap'}>
                <div className={'main_container'}>
                    <div style={{display: 'flex', alignItems: 'center', margin: '16px 0'}}>
                        <Select value={role} onChange={value => {
                            setRole(value)
                        }} style={{marginRight: 20, width: 200}}>
                            <Select.Option key={-1}>全部权限组</Select.Option>
                            {
                                roles.map(e => {
                                    return (
                                        <Select.Option key={e.key}>{e.name}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                        <div style={{marginRight: 20, width: 550}}>
                            <Search
                                placeholder="输入员工名称进行搜索"
                                onSearch={onSearch}
                                enterButton="搜索"
                                allowClear={true}
                            />
                        </div>
                        <div className={'flex_1'}>
                            <Button
                                type={'primary'}
                                style={{float: 'right'}}
                                onClick={() => {
                                    id = 0
                                    form.resetFields(['name', 'phone', 'password', 'email', 'role']);
                                    setInfoVisible(true);
                                }}
                            >添加员工</Button>
                        </div>
                    </div>
                    <div className={'table_wrap'}>
                        <Table dataSource={tableData} columns={columns} pagination={false} scroll={{y: 640, x: 1000}}/>
                    </div>
                </div>
                <CustomPagination
                    currentPage={currentPage}
                    total={count}
                    onChange={(page: number) => setCurrentPage(page)}
                    pageSize={PAGE_SIZE}
                />
            </div>
            <Modal
                title="员工信息"
                visible={infoVisible}
                onOk={() => {
                    form.submit();
                }}
                onCancel={() => {
                    setInfoVisible(false);
                    form.resetFields(['name', 'phone', 'password', 'email', 'role']);
                }}
            >
                <Form
                    form={form}
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    onFinish={e => {
                        createRole({
                            id: id,
                            nickname: e.name,
                            phone: e.phone,
                            email: e.email,
                            pwd: e.password,
                            group_role: Number(e.role)
                        })
                        form.resetFields(['name', 'phone', 'password', 'email', 'role']);
                    }}
                >
                    <Form.Item label="员工名称" name="name" rules={[{required: true, message: '请输入角色名称!'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="电话(登录账号)" name="phone" rules={[{required: true, message: '请输入电话!'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="登录密码" name="password" rules={[{required: true, message: '请输入登录密码!'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="邮箱" name="email">
                        <Input/>
                    </Form.Item>
                    <Form.Item label="所属权限组" name="role" rules={[{required: true, message: '请选择所属权限组!'}]}>
                        <Select>
                            {
                                roles.map(e => {
                                    return (
                                        <Select.Option key={e.key}>{e.name}</Select.Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default MemberAdmins;
