import {PublicContext} from '../../context';
import {Breadcrumb as AntBreadcrumb} from 'antd';
import {useContext, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';

const Breadcrumb = () => {
    const {publicData} = useContext(PublicContext);
    const [list, setList] = useState<Array<any>>([]);
    let location = useLocation();

    useEffect(() => {
        const path = location.pathname;
        publicData.menuRouter.forEach((res1: any) => {
            if (res1.route && path === res1.route) {
                setList([res1]);
                return;
            } else {
                res1.children && res1.children.forEach((res2: any) => {
                    if (res2.route && path === res2.route) {
                        setList([res1, res2]);
                        return;
                    } else {
                        res2.children && res2.children.forEach((res3: any) => {
                            if (res3.route && path === res3.route) {
                                setList([res1, res2, res3]);
                                return;
                            }
                        });
                    }
                });
            }
        });
    }, [location, publicData.menuRouter]);

    return (
        <AntBreadcrumb>
            {
                list.length > 1 && list.map((res: any, index) => {
                    return (
                        <AntBreadcrumb.Item key={res.name}>
                            {
                                (res.route && index !== list.length - 1) ?
                                    <Link to={res.route}>
                                        {res.name}
                                    </Link> : res.name
                            }
                        </AntBreadcrumb.Item>
                    );
                })
            }
        </AntBreadcrumb>
    );
};
export default Breadcrumb;
