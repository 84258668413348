import {Checkbox, Col, Form, Input, message, Modal, Row} from "antd";
import React, {useState} from "react";
import {sendShip} from "../../../../service/order/list";


const  SendModal = (props:any)=>{
    const {list,getOrderList,sendVisible,setSendVisible,orderId} = props
    const [transForm] = Form.useForm();

    const [sendChecked,setSendChecked] = useState([])
    const [modalStep,setModalStep] = useState<number>(0)
    const cancel = ()=>{
        setSendChecked([])
        transForm.resetFields();
        setModalStep(0)
        setSendVisible(false)
    }
    const handlerSend = async()=>{
        if(modalStep){
            const ids = sendChecked
            const {company,field} = transForm.getFieldValue([]);
            try{
                await transForm.validateFields()
                await sendShip({
                    oder_id:orderId,
                    ids,
                    delivery_company:company,
                    delivery_sn:field,
                })
                message.success('发货成功')
                if(getOrderList) await getOrderList()
                cancel();
            }catch (e){
                console.log(e)
            }
            return;
        }
        if(sendChecked.length === 0) {
            message.warning('请选择至少一个商品进行发货')
            return;
        }
        setModalStep(1)
    }
    return (
        <Modal
            title="发货"
            visible={sendVisible}
            onOk={handlerSend}
            onCancel={cancel}
            okText="确认"
            cancelText="取消"
            centered={true}
        >
            {
                modalStep === 0&&
                <Checkbox.Group style={{width:'100%'}} value={sendChecked} onChange={(value:any)=>setSendChecked(value)}>
                    {
                        list&&list.map((item:any)=>{
                            const {id,product_name,sp_data,product_quantity,product_price,product_pic,status} = item;
                            if(status==10)
                            return (
                                <Row className={'detail'} style={{margin:'16px 0'}} key={id}>
                                    <Col span={2}>
                                        <Checkbox value={id}/>
                                    </Col>
                                    <Col span={4}>
                                        <img
                                            src={localStorage.getItem('divms_url') + product_pic}
                                            style={{width:60,height:60}}
                                        />
                                    </Col>
                                    <Col span={8} style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                                        <div>
                                            {product_name}
                                        </div>
                                        <div>
                                            {sp_data&&sp_data.map((sp:any,index:number)=>(
                                                <span key={index}>{sp.value}{index!==sp_data.length-1 && '、'}</span>
                                            ))}
                                        </div>
                                    </Col>
                                    <Col span={6} offset={4} style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                                        <div>x{product_quantity}</div>
                                        <div>¥{product_price}</div>
                                    </Col>
                                </Row>
                            )
                        })
                    }
                </Checkbox.Group>
            }
            {
                modalStep === 1&&
                <Form
                    form={transForm}
                    name="transForm"
                    labelCol={{ span: 6 }}
                    labelAlign={"left"}
                    wrapperCol={{ span: 18 }}
                    colon={true}

                >
                    <Form.Item
                        label='物流公司'
                        name='company'
                        rules={[{required: true, message: '请输入物流公司'}]}
                    >
                        <Input placeholder={'请输入'}/>
                    </Form.Item>
                    <Form.Item
                        label='物流单号'
                        name='field'
                        rules={[{required: true, message: '请输入物流单号'}]}
                    >
                        <Input placeholder={'请输入'}/>
                    </Form.Item>
                </Form>
            }
        </Modal>
    )
}
export default SendModal
