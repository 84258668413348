import './new.less';
import React, {useContext, useEffect, useReducer, useState} from 'react';
import {Button, message, Steps, Result, Space} from 'antd';
import Step1 from './components/step1';
import Step2 from './components/step2';
import Step3 from './components/step3';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {addProduct, getProductDetail} from '../../../../service/product.api';
import {searchObj} from '../../../../utils';
import {PublicContext} from '../../../../context';
import BraftEditor from 'braft-editor';

let id: number | null = null;//有id表示编辑

/**
 * 新增商品上下文
 */
export const NewProductContext = React.createContext<any>(null);

const newProductInitData: any = {
    name: '',
    category: undefined,
    mainImgList: [],
    mainVideoList: [],
    productDetailRichText: '',
    productAttributeCategory: undefined,
    skus: {
        columns1: [],
        columns2: [],
        data: []
    },
    isNew: false,
    isRecommend: false,
    promotionType: 1,//促销类型：1->没有促销使用原价;2->限时购
    promotionTimeRange: [],
    editDetail: null,
    specificationsList: [],
    attributes: []
};

const newProductReducer = (state: typeof newProductInitData, action: IAction) => {
    switch (action.type) {
        case 'setSpecificationsList':
            return Object.assign({}, state, {specificationsList: action.value});
        case 'setAttributes':
            return Object.assign({}, state, {attributes: action.value});
        case 'reset':
            return Object.assign({}, newProductInitData, {
                category: undefined,
                skus: {
                    columns1: [],
                    columns2: [],
                    data: []
                },
            });
        case 'setName':
            return Object.assign({}, state, {name: action.value});
        case 'setCategory':
            return Object.assign({}, state, {category: action.value});
        case 'setMainImgList':
            return Object.assign({}, state, {mainImgList: action.value});
        case 'setMainVideoList':
            return Object.assign({}, state, {mainVideoList: action.value});
        case 'setProductDetailRichText':
            return Object.assign({}, state, {productDetailRichText: action.value});
        case 'setProductAttributeCategory':
            return Object.assign({}, state, {productAttributeCategory: action.value});
        case 'setSkusColumns1':
            state.skus.columns1 = action.value;
            return Object.assign({}, state);
        case 'setSkusColumns2':
            state.skus.columns2 = action.value;
            return Object.assign({}, state);
        case 'setSkusData':
            if (state.attributes.length > 0) {
                let attributesObj: any = {};
                state.attributes.forEach((res: any) => {
                    attributesObj[res.name] = res.input_list;
                });
                console.log(state.attributes);
                console.log(attributesObj);
                //去除多余项
                state.skus.data.forEach((res: any) => {
                    let obj = Object.assign({}, res);
                    if (obj.id) {
                        delete obj.id;
                    }
                    delete obj.isDefault;
                    delete obj.price;
                    delete obj.promotionPrice;
                    delete obj.stock;
                    for (let j in obj) {
                        if (obj.hasOwnProperty(j)) {
                            if (!attributesObj[j]) {
                                delete res[j];
                            }
                        }
                    }
                });
            }
            let array: Array<any> = [];
            action.value.forEach((value: any, index: number, arr: Array<any>) => {
                for (let i = 0; i < state.skus.data.length; i++) {
                    let allTheSame = true;
                    let obj = Object.assign({}, state.skus.data[i]);
                    if (obj.id) {
                        delete obj.id;
                    }
                    delete obj.isDefault;
                    delete obj.price;
                    delete obj.promotionPrice;
                    delete obj.stock;
                    for (let j in obj) {
                        if (obj.hasOwnProperty(j)) {
                            if (!value[j] || value[j] != obj[j]) {
                                allTheSame = false;
                            }
                        }
                    }
                    if (allTheSame) {
                        if (state.skus.data[i].id) {
                            arr[index].id = state.skus.data[i].id;
                        }
                        arr[index].isDefault = state.skus.data[i].isDefault;
                        arr[index].price = state.skus.data[i].price;
                        arr[index].promotionPrice = state.skus.data[i].promotionPrice;
                        arr[index].stock = state.skus.data[i].stock;
                        state.skus.data.splice(i, 1);
                        i--;
                    }
                }
            });
            state.skus.data.forEach((res: any) => {
                if (res.isDefault || res.price || res.promotionPrice || res.stock) {
                    action.value.push(res);
                }
            });
            state.skus.data = action.value;
            return Object.assign({}, state);
        case 'skuDefaultCheck':
            state.skus.data[action.value].isDefault = !state.skus.data[action.value].isDefault;
            state.skus.data[action.value] = Object.assign({}, state.skus.data[action.value]);
            state.skus.data = state.skus.data.concat();
            return Object.assign({}, state);
        case 'skuPriceInput':
            state.skus.data[action.value.index].price = action.value.price;
            state.skus.data[action.value.index] = Object.assign({}, state.skus.data[action.value.index]);
            state.skus.data = state.skus.data.concat();
            return Object.assign({}, state);
        case 'skuStockInput':
            state.skus.data[action.value.index].stock = action.value.stock;
            state.skus.data[action.value.index] = Object.assign({}, state.skus.data[action.value.index]);
            state.skus.data = state.skus.data.concat();
            return Object.assign({}, state);
        case 'skuPromotionPriceInput':
            state.skus.data[action.value.index].promotionPrice = action.value.promotionPrice;
            state.skus.data[action.value.index] = Object.assign({}, state.skus.data[action.value.index]);
            state.skus.data = state.skus.data.concat();
            return Object.assign({}, state);
        case 'deleteSkuByIndex':
            state.skus.data.splice(action.value, 1);
            state.skus.data = state.skus.data.concat();
            return Object.assign({}, state);
        case 'isNewCheck':
            return Object.assign({}, state, {isNew: !state.isNew});
        case 'isRecommendCheck':
            return Object.assign({}, state, {isRecommend: !state.isRecommend});
        case 'setPromotionType':
            return Object.assign({}, state, {promotionType: action.value});
        case 'setPromotionTimeRange':
            return Object.assign({}, state, {promotionTimeRange: action.value});
        case 'setAll':
            let imgList: Array<any> = [];
            action.value.pics && action.value.pics.forEach((res: any) => {
                imgList.push({
                    url: localStorage.getItem('divms_url') + res,
                    originalUrl: res
                });
            });
            let videoList: Array<any> = [];
            action.value.video &&
            videoList.push({
                url: localStorage.getItem('divms_url') + action.value.video,
                originalUrl: action.value.video
            });
            return Object.assign({}, state, {
                editDetail: action.value,
                name: action.value.name,
                category: action.value.product_category_id,
                mainImgList: imgList,
                mainVideoList: videoList,
                productDetailRichText: BraftEditor.createEditorState(action.value.description),
                productAttributeCategory: action.value.product_attribute_category_id,
                isNew: action.value.new_status === 2,
                isRecommend: action.value.recommend_status === 2,
                promotionType: action.value.promotion_type,
                promotionTimeRange: action.value.promotion_type === 2 ? [moment(action.value.promotion_start_time), moment(action.value.promotion_end_time)] : []
            });
        default:
            return Object.assign({}, state);
    }
};

const NewProduct = () => {
    const {publicData} = useContext(PublicContext);
    const {imgDomain} = publicData;
    const [newProductData, newProductDispatch] = useReducer(newProductReducer, newProductInitData);
    const {editDetail} = newProductData;
    const [currentStep, setCurrentStep] = useState(0);
    const [lastProductAttributeCategory, setLastProductAttributeCategory] = useState('');

    const history = useHistory();

    useEffect(() => {
        newProductDispatch({
            type: 'reset'
        });
        if (history.location.search && searchObj(history.location.search).id) {
            id = Number(searchObj(history.location.search).id);
            setProductDetail(id);
        }
    }, []);

    const setProductDetail = async (id: number) => {
        const res: any = await getProductDetail(id);
        console.log('编辑数据', res);
        newProductDispatch({
            type: 'setAll',
            value: res
        });
    };

    const next = async () => {
        console.log('商品数据', newProductData);
        switch (currentStep) {
            case 0:
                if (!newProductData.name) {
                    message.warning('请填写商品名称');
                    return;
                } else if (!newProductData.category) {
                    message.warning('请选择商品分类');
                    return;
                } else if (!newProductData.mainImgList || newProductData.mainImgList.length === 0) {
                    message.warning('请上传商品图片');
                    return;
                } else if (!newProductData.productDetailRichText || newProductData.productDetailRichText == '<p></p>') {
                    message.warning('请配置商品详情');
                    return;
                }
                setCurrentStep(currentStep + 1);
                break;
            case 1:
                if (!newProductData.productAttributeCategory) {
                    message.warning('请选择规格');
                    return;
                } else if (newProductData.skus.data.length === 0) {
                    message.warning('请添加规格');
                    return;
                } else {
                    let canNext = true;
                    newProductData.skus.data.forEach((res: any) => {
                        if (!res.price || (!res.stock && res.stock != 0)) {
                            canNext = false;
                        }
                    });
                    canNext ? setCurrentStep(currentStep + 1) : message.warning('请填写完整价格及库存');
                }
                break;
            case 2:
                if (newProductData.promotionType === 2) {
                    let canNext = true;
                    newProductData.skus.data.forEach((res: any) => {
                        if (!res.promotionPrice) {
                            canNext = false;
                        }
                    });
                    if (!canNext) {
                        message.warning('请填写完整促销价格');
                        return;
                    }
                    if (newProductData.promotionTimeRange.length !== 2) {
                        message.warning('请选择活动日期');
                        return;
                    }
                }

                let skus: Array<any> = [];
                newProductData.skus.data.forEach((res: any) => {
                    let obj = Object.assign({}, res);
                    if (obj.id) {
                        delete obj.id;
                    }
                    delete obj.isDefault;
                    delete obj.price;
                    delete obj.promotionPrice;
                    delete obj.stock;
                    let sp_data: Array<any> = [];
                    for (let i in obj) {
                        if (obj.hasOwnProperty(i)) {
                            sp_data.push({
                                key: i,
                                value: obj[i]
                            });
                        }
                    }
                    skus.push(Object.assign({
                        price: Number(res.price) * 100,
                        promotion_price: res.promotionPrice ? Number(res.promotionPrice)  * 100 : null,
                        stock: Number(res.stock),
                        default_sku: res.isDefault ? 1 : 2,
                        sp_data: sp_data
                    }, res.id ? {id: res.id} : {}));
                });
                let pics: Array<any> = [];
                newProductData.mainImgList.forEach((res: any) => {
                    if (res.response) {
                        pics.push(res.response.result.key);
                    } else if (res.originalUrl) {
                        pics.push(res.originalUrl);
                    }
                });
                let video: Array<any> = [];
                newProductData.mainVideoList.forEach((res: any) => {
                    if (res.response) {
                        video.push(res.response.result.key);
                    } else if (res.originalUrl) {
                        video.push(res.originalUrl);
                    }
                });
                let attribute: Array<any> = [];
                newProductData.attributes.forEach((res: any) => {
                    let inputList: Array<any> = [];
                    res.input_list.forEach((res: any) => {
                        if (res.checked) {
                            inputList.push(res.name);
                        }
                    });
                    attribute.push({
                        attribute_id: res.id,
                        name: res.name,
                        input_list: inputList
                    });
                });
                let data: any = Object.assign({
                    product_category_id: Number(newProductData.category.split('-')[0]),
                    product_attribute_category_id: newProductData.productAttributeCategory,
                    name: newProductData.name,
                    pics: pics,
                    video: video.join(','),
                    description: newProductData.productDetailRichText.toHTML(),
                    new_status: newProductData.isNew ? 2 : 1,
                    recommend_status: newProductData.isRecommend ? 2 : 1,
                    promotion_type: newProductData.promotionType,
                    promotion_start_time: newProductData.promotionTimeRange.length > 1 ? moment(newProductData.promotionTimeRange[0]).valueOf() : null,
                    promotion_end_time: newProductData.promotionTimeRange.length > 1 ? moment(newProductData.promotionTimeRange[1]).valueOf() : null,
                    skus: skus,
                    attribute: attribute
                }, editDetail ? {id: editDetail.id} : {});
                console.log('data', data);
                try {
                    await addProduct(data);
                    setCurrentStep(currentStep + 1);
                } catch (e) {

                }
                break;
            default:
        }
    };

    const goBack = () => {
        if (currentStep > 0 && currentStep < 3) {
            setCurrentStep(currentStep - 1);
        } else {
            history.goBack();
        }
    };

    const reset = () => {
        if (!editDetail) {
            id = null;
            newProductDispatch({
                type: 'reset'
            });
        }
        setLastProductAttributeCategory('');
        setCurrentStep(0);
    };

    return (
        <NewProductContext.Provider
            value={{newProductData, newProductDispatch, lastProductAttributeCategory, setLastProductAttributeCategory}}>
            <div className={'list_add_body_wrap'}>
                <div className={'list_add_body'}>
                    <div className={'step_wrap'}>
                        <Steps current={currentStep}>
                            <Steps.Step title="商品信息"/>
                            <Steps.Step title="商品属性"/>
                            <Steps.Step title="商品促销"/>
                            <Steps.Step title="完成发布"/>
                        </Steps>
                    </div>
                    {
                        currentStep === 0 ? <Step1/>
                            : currentStep === 1 ? <Step2/>
                                : currentStep === 2 ? <Step3/>
                                    : <Result
                                        status="success"
                                        title="发布成功!"
                                        subTitle=""
                                        extra={[
                                            <Button key="back" onClick={goBack}>
                                                返回列表
                                            </Button>,
                                            <Button type="primary" key="continue" onClick={reset}>继续发布</Button>,
                                        ]}
                                    />
                    }
                    {
                        currentStep !== 3 &&
                        <div className={'add_footer'}>
                            <Space>
                                <Button size={'middle'} onClick={goBack}>
                                    {currentStep === 0 ? '取消' : '上一步'}
                                </Button>
                                <Button type={'primary'} size={'middle'} onClick={next}>下一步</Button>
                            </Space>
                        </div>
                    }
                </div>
            </div>
        </NewProductContext.Provider>
    );
};
export default NewProduct;
