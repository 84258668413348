import {Button, Col, Input, Row, Space, Switch, Upload} from 'antd';
import React, {useContext} from "react";
import {SettingContext} from "./system";
import {API_URL} from "../../../utils/request";
import UploadImgButton from "../../../components/upload_Img_button/upload_Img_button";
import RegionSelect from "../../../components/region_select/regionSelect";
import Logo from '../../../assets/images/example_logo.png';
import Contact from '../../../assets/images/example_contact.png';

const {TextArea} = Input
const BaseOption = ()=>{
    const {settingData, settingDispatch,handlePreview,save} = useContext(SettingContext);
    const {
        name,
        logo,
        qrCode,
        wechatNumber,
        detailAdd,
        transPay,
        suggestion,
        province,
        city,
        region,
    } = settingData

    return(
        <div className='form_wrap' style={{paddingBottom:200}}>
            <div className={'title'} style={{marginBottom:20}}>
                基本信息
            </div>
            <div className={'setting_label'}><span>*</span>系统名称</div>
            <Input
                value={name}
                onChange={(e)=>settingDispatch({type:'setName',value:e.target.value})}
            />
            <div className={'setting_label'}><span>*</span>系统LOGO</div>
            <div className={'setting_logo'}>
                <div className={'setting_logo_upload'}>
                    <p>请上传高清无圆角长方形图片，支持PNG格式，大小不超过5MB。LOGO将在网页顶部左上角及APP内显示，请正确上传</p>
                    <Upload
                        name="picture"
                        accept=".png"
                        action={`${API_URL}/api/v1/file/upload`}
                        listType="picture-card"
                        fileList={logo}
                        onPreview={handlePreview}
                        onChange={res => {
                            const {fileList} = res;
                            settingDispatch({type:'setLogo',value:fileList})
                        }}
                    >
                        {logo.length < 1 && <UploadImgButton/>}
                    </Upload>
                </div>
                <div className={'setting_logo_demo'}>
                    <div className={'demo_title'}>示例</div>
                    <img src={Logo} alt="" style={{marginTop:24}} className={'pic'}/>
                </div>
            </div>
            <div className={'setting_label'}><span>*</span>联系我们-二维码</div>
            <div className={'setting_qrcode'}>
                <div className={'setting_qrcode_upload'}>
                    <p>请上传高清无圆角长方形图片，支持JPG、JPEG和PNG格式，大小不超过5MB。将在APP内联系我们中显示，请正确上传</p>
                    <Upload
                        name="picture"
                        accept=".png,.jpg,.jpeg"
                        action={`${API_URL}/api/v1/file/upload`}
                        listType="picture-card"
                        fileList={qrCode}
                        onPreview={handlePreview}
                        onChange={res => {
                            const {fileList} = res;
                            settingDispatch({type:'setQrCode',value:fileList})
                        }}
                    >
                        {qrCode.length < 1 && <UploadImgButton/>}
                    </Upload>
                </div>
                <div className={'setting_qrcode_demo'}>
                    <div className={'demo_title'}>示例</div>
                    <img src={Contact} alt="" style={{width:120,margin:'auto'}} className={'pic'}/>
                </div>
            </div>
            <div className={'setting_label'}><span>*</span>联系我们-微信号</div>
            <Input
                value={wechatNumber}
                onChange={(e)=>settingDispatch({type:'setWechatNumber',value:e.target.value})}
            />
            <div className={'setting_label'}><span>*</span>默认发货地址</div>
            <div className={'setting_address_wrap'}>
                <div style={{width:'50%',minWidth:'300px'}}>
                    <RegionSelect
                        selectWidth={100}
                        province={province}
                        city={city}
                        county={region}
                        provinceChange={(value:string)=>settingDispatch({type:'setProvince',value})}
                        cityChange={(value:string)=>settingDispatch({type:'setCity',value})}
                        countyChange={(value:string)=>settingDispatch({type:'setRegion',value})}
                    />
                </div>
                <div className={'setting_label'}><span>*</span>详细地址</div>
                <Input
                    value={detailAdd}
                   placeholder='详细地址'
                   onChange={(e)=>settingDispatch({type:'setDetailAdd',value:e.target.value})}
                />
            </div>
            <div className={'setting_label'}><span>*</span>运费</div>
            <Input
                type='Number'
                value={transPay}
                onChange={(e)=>settingDispatch({type:'setTransPay',value:e.target.value})}
            />
            <div className={'title'} style={{marginBottom:20}}>
                功能设置
            </div>
            <div className={'setting_label'}><span>*</span>评论功能</div>
            <Row>
                <Col span={2}>
                    <Switch
                        defaultChecked
                        onChange={(value)=>settingDispatch({type:'setCommentOpen',value})}
                    />
                </Col>
                <Col span={20}>
                    <p style={{fontSize:12,color:'#bab5b5',marginTop:8}}>*关闭后将无法发布和查看评论</p>
                </Col>
            </Row>
            <div className={'setting_label'}><span>*</span>意见反馈类型配置：</div>
            <TextArea
                placeholder={'一行一个'}
                value={suggestion}
                rows={6}
                onChange={(e)=>settingDispatch({type:'setSuggestion',value:e.target.value})}
            />
            {/*<Row style={{padding:20}}>*/}
            {/*    <Col span={2} offset={22}>*/}
            {/*        <Button type={"primary"} onClick={save}>保存</Button>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <div className={'add_footer'}>
                <Space>
                    <Button type={"primary"} onClick={save}>保存</Button>
                </Space>
            </div>
        </div>
    )
}
export default BaseOption
