import {Get, Post} from '../../utils/request';

/**
 *
 * 广告列表
 * @param req
 */
export const getAdvertising = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Get('/api/v1/bg/banner/list', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 *
 * 删除广告
 * @param req
 */
export const delAdvertising = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/bg/banner/delete', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 *
 * 新增/修改广告
 * @param req
 */
export const updateAdvertising = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/bg/banner/update', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
