import {Modal} from "antd";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import React from "react";

/**
 *
 * @param records
 * @param content
 * @param callback
 */
const showDeleteConfirm:any = (records: any,content:string,callback:any): void => {
    Modal.confirm({
        content,
        icon: <ExclamationCircleOutlined/>,
        centered: true,
        bodyStyle: {
            fontSize: 16,
        },
        okText: '是',
        okType: 'danger',
        cancelText: '否',
        onOk:()=>callback(records)
    });
};
export default showDeleteConfirm;
