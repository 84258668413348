import React, {useEffect, useReducer} from 'react'
import { useHistory } from 'react-router-dom';
import {orderDetail} from '../../../../service/order/list'
import UI from './UI'

export const OrderDetailContext = React.createContext<any>(null);
const map = new Map();
map.set(0,0)
map.set(10,1)
map.set(20,2)
map.set(30,3)
map.set(40,-1)
map.set(50,-1)
map.set(60,-1)
const orderInfo: any = {
    actionTable:[],
    detailInfo:{},
    currentStep:1,
};
const orderReducer = (state: typeof orderInfo, action: IAction) => {
    switch (action.type) {
        case 'setDetail':
            const {value: {status}} = action
            return Object.assign({},state, {
                detailInfo:action.value,
                currentStep:map.get(status)
            })
        default:
            return Object.assign({}, state);
    }
}
const OrderListDetail = () => {
    const history = useHistory()
    const [orderData,orderDispatch] = useReducer(orderReducer,orderInfo);
    const {detailInfo,actionTable} = orderData;
    useEffect(()=>{
        getInfo().then()
    },[])
    const getInfo = async ()=>{
        if(!history.location.search) return;
        const id = history.location.search.substring(4)
        const {result}:any = await orderDetail({id})
        console.log('编辑信息',result)
        orderDispatch({
            type:'setDetail',
            value:result
        })
    }
    return (
        <OrderDetailContext.Provider value={{orderData,orderDispatch}}>
            {
                Object.keys(detailInfo).length!==0&&
                <UI />
            }
        </OrderDetailContext.Provider>

    );
};
export default OrderListDetail;
