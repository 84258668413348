import React, {Fragment, useEffect, useState} from 'react';
import {Button, Form, Input, message, Modal, Radio, Select, Table} from 'antd';
import moment from 'moment';
import showDeleteConfirm from '../../../components/confirm_delete/confirm_delete';
import CustomPagination from '../../../components/custom_pagination/custom_pagination';
import {getNotice, delNotice, createNotice} from '../../../service/market/notice';
import {PlusOutlined} from '@ant-design/icons';

const {Option} = Select;
const {TextArea} = Input;
const PAGE_SIZE = 20;
const NoticeContent = () => {
    const [detailForm] = Form.useForm();
    const [isSkip, setIsSkip] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [visible, setVisible] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const columns = [
        {
            title: '推送时间',
            dataIndex: 'create_time',
            key: 'id',
            width: 180,
            render: (text: number) => moment.unix(text).format('YYYY-MM-DD')
        },
        {
            title: '消息类型',
            dataIndex: 'notice_type',
            key: 'id',
            width: 140,
            render: (text: any) => text === 9 ? '系统公告' : ''
        },
        {
            title: '接收用户',
            dataIndex: 'receive_id',
            key: 'id',
            width: 140,
            render: (text: any) => text === 0 ? '全体用户' : ''
        },
        {
            title: '消息内容',
            dataIndex: 'content',
            key: 'id'
        },
        {
            title: '操作',
            width: 140,
            render: (text: any, record: any) => (
                <a onClick={() => showDeleteConfirm(record, '是否删除该消息', delNoticeItem)}>删除</a>
            )
        }
    ];
    useEffect(() => {
        getList().then();
    }, [page]);
    useEffect(() => {
        if (isSkip === 1) {
            detailForm.setFieldsValue({
                link_type: null,
                link_url: null,
            });
        }
    }, [isSkip]);
    const getList = async () => {
        try {
            const {result: {count, data}}: any = await getNotice({
                page,
                size: PAGE_SIZE
            });
            setCount(count);
            setTableData(data);
        } catch (e) {
            console.log(e);
        }

    };
    const delNoticeItem = async (record: any) => {
        try {
            const {id} = record;
            await delNotice({id});
            message.success('删除成功');
            await getList();
        } catch (e) {
            message.error('删除失败');
            console.log(e);
        }
    };
    const handlerAdd = async () => {
        try {
            await detailForm.validateFields([]);
            const data = detailForm.getFieldValue([]);
            await createNotice(data);
            message.success('添加成功');
            detailForm.resetFields();
            setVisible(false);
            await getList();
        } catch (e) {
            message.error('添加失败');
            console.log(e);
        }
    };
    const cancel = () => {
        detailForm.resetFields();
        setVisible(false);
    };
    const headerStyle = {
        padding: '16px 0 ',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
    };
    const itemStyle = {
        borderBottom: '1px dashed #d7d7d7',
        marginBottom: '10px'
    };
    return (
        <div className="content_container">
            <div className={'main_wrap'}>
                <div className={'main_container'}>
                    <div style={headerStyle}>
                        <Button type={'primary'} icon={<PlusOutlined/>}
                                onClick={() => setVisible(true)}>新增消息</Button>
                    </div>
                    <Table dataSource={tableData}
                           columns={columns}
                           pagination={false}
                           scroll={{y: 680}}
                    />
                </div>
                <CustomPagination
                    currentPage={page}
                    total={count}
                    onChange={(page: number) => setPage(page)}
                    pageSize={PAGE_SIZE}
                />
                <Modal
                    title="新增消息"
                    visible={visible}
                    onOk={handlerAdd}
                    onCancel={cancel}
                    okText="确认"
                    cancelText="取消"
                    confirmLoading={confirmLoading}
                    centered={true}
                >
                    <Form
                        form={detailForm}
                        name="detailForm"
                        labelCol={{span: 6}}
                        labelAlign={'left'}
                        wrapperCol={{span: 18}}
                        colon={true}
                        initialValues={{
                            is_skip: 1
                        }}
                    >
                        <div style={itemStyle}>
                            <Form.Item
                                label="消息类型"
                                name="notice_type"
                                rules={[{required: true, message: '请输入消息类型'}]}
                            >
                                <Select>
                                    <Option value={9}>系统公告</Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <div style={itemStyle}>
                            <Form.Item
                                label="标题"
                                name="title"
                                rules={[{required: true, message: '请输入标题'}]}
                            >
                                <Input/>
                            </Form.Item>
                        </div>
                        <div style={itemStyle}>
                            <Form.Item
                                label="是否需要跳转"
                                name="is_skip"
                                rules={[{required: true, message: '请选择是否跳转'}]}
                            >
                                <Radio.Group value={isSkip} onChange={(e) => setIsSkip(e.target.value)}>
                                    <Radio value={1}>否</Radio>
                                    <Radio value={2}>是</Radio>
                                </Radio.Group>
                            </Form.Item>
                            {
                                isSkip === 2 && (
                                    <Fragment>
                                        <Form.Item
                                            label="跳转类型"
                                            name="link_type"
                                            rules={[{required: true, message: '请选择跳转类型'}]}
                                        >
                                            <Select>
                                                <Option value={2}>
                                                    HTTP
                                                </Option>
                                                <Option value={3}>
                                                    APP内
                                                </Option>
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            label="跳转地址"
                                            name="link_url"
                                            rules={[{required: true, message: '请填写跳转地址'}]}
                                        >
                                            <Input/>
                                        </Form.Item>
                                    </Fragment>
                                )
                            }
                        </div>
                        <Form.Item
                            label="消息内容"
                            name="content"
                            rules={[{required: true, message: '请输入消息内容'}]}
                            labelCol={{span: 24}}
                            wrapperCol={{span: 24}}
                        >
                            <TextArea rows={4}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </div>
    );
};
export default NoticeContent;
