import {Post,Get} from '../../utils/request'

/**
 * 获取协议
 * @param req
 */
export const getInfo = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Get('/api/v1/agreement/info', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};

/**
 * 更新协议
 * @param req
 */
export const updateInfo = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/agreement/update', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};

