import './category.less';
import {Button, message, Table, Space, Modal, Row, Col, TreeSelect, Divider} from 'antd';
import {Link} from 'react-router-dom';
import React, {useEffect, useRef, useState} from 'react';
import {DndProvider, DragSourceMonitor, DropTargetMonitor, useDrag, useDrop} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import TableHead from '../../../components/table_head/table_head';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import {DeleteCategory, GetCategory, TransferCategoryProduct} from '../../../service/public.apis';
import update from 'immutability-helper';
import {categoryDrag} from '../../../service/product.api';

const type = 'DraggableBodyRow';
let startRecord: any = null;
let productCategoryIdForm: number;

const DraggableBodyRow = ({record, index, moveRow, className, style, ...restProps}: any) => {
    const ref = useRef();
    const [{isOver, dropClassName}, drop] = useDrop({
        accept: type,
        collect: (monitor: DropTargetMonitor) => {
            return {
                isOver: monitor.isOver(),
                dropClassName: 'drop-over-upward',
            };
        },
        drop: async () => {
            moveRow && moveRow(startRecord, record);
        },
    });
    const [, drag] = useDrag({
        type,
        item: {index},
        collect: (monitor: DragSourceMonitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    drop(drag(ref));
    return (
        <tr
            ref={ref}
            className={`${className}${isOver ? dropClassName : ''}`}
            style={{cursor: 'move', ...style}}
            {...restProps}
        />
    );
};

const ProductClassification: React.FC = () => {
    const [tableData, setTableData] = useState<any>(null);
    const [transferVisible, setTransferVisible] = useState(false);
    const [treeSelectValue, setTreeSelectValue] = useState('');

    useEffect(() => {
        getCategory().then();
    }, []);

    useEffect(() => {
        console.log('tableData', tableData);
    }, [tableData]);

    const getCategory = async () => {
        try {
            let categoryList: any = await GetCategory();
            const processCategoryList = (req: Array<any>, arr: Array<any>) => {
                req.forEach((res: any, index) => {
                    let array = arr.concat();
                    array.push(index);
                    res.indexArray = array;
                    res.children = res.child;
                    res.key = `${res.id}-${res.level}`;
                    res.title = res.name;
                    res.value = `${res.id}-${res.level}`;
                    if ((res.level.toString().substr(1, 1) &&
                            res.level.toString().substr(1, 1) === res.level.toString().substr(0, 1)) ||
                        res.level == 1) {
                        res.disabled=false
                    } else {
                        res.disabled=true
                    }
                    if (res.children) {
                        processCategoryList(res.children, array.concat());
                    }
                });
            };
            processCategoryList(categoryList, []);
            setTableData(categoryList);
        } catch (e) {
            console.log(e);
        }
    };

    const components = {
        body: {
            row: DraggableBodyRow,
        },
    };

    const columns:any = [
        {
            title: '分类名称',
            dataIndex: 'name',
            key: 'name',
            render: (text: string, record: any) => (
                <>
                <Link to={{
                    pathname: '/product_list',
                    search: `kind=${record.id}`
                }}>{record.name}</Link> <span>[id:{record.id}]</span>
                </>
            ),
        },
        {
            title: '结构层级',
            dataIndex: 'level',
            key: 'level',
            width: 140,
            render: ((text: number) => (text.toString().substr(0, 1) === '1' ? '一级' :
                text.toString().substr(0, 1) === '2' ? '二级' :
                    text.toString().substr(0, 1) === '3' ? '三级' : '四级'))
        },
        {
            title: '商品数量',
            dataIndex: 'product_count',
            key: 'product_count',
            width: 140,
        },
        {
            title: '是否显示',
            dataIndex: 'show_status',
            key: 'show_status',
            width: 140,
            render: ((text: number) => (text === 2 ? '是' : '否'))
        },
        {
            title: '操作',
            key: 'action',
            width:300,
            fixed: 'right',
            render: (text: string, record: any) => (
                <Space size="small">
                    <Link to={{
                        pathname: '/edit_product_classification',
                        search: `data=${encodeURIComponent(JSON.stringify(record))}`
                    }}>编辑</Link>
                    <Divider type="vertical"/>
                    {((record.level.toString().substr(1, 1) &&
                            record.level.toString().substr(1, 1) === record.level.toString().substr(0, 1)) ||
                        record.level === 1) ?
                        <a onClick={() => {
                            productCategoryIdForm = record.id;
                            setTreeSelectValue('');
                            setTransferVisible(true);
                        }}>转移商品</a> :
                        <Link to={{
                            pathname: '/product_subcategory',
                            search: `parent_id=${record.id}`
                        }}>添加子类</Link>
                    }
                    <Divider type="vertical"/>
                    <Link to={{
                        pathname: '/product_list',
                        search: ``
                    }}>商品</Link>
                    <Divider type="vertical"/>
                    <a onClick={() => showDeleteConfirm(record)}>删除</a>
                </Space>
            ),

        },
    ];

    const moveRow = async (startRecord: any, endRecord: any) => {
        console.log('startRecord:', startRecord);
        console.log('endRecord:', endRecord);
        let startRecordPop = startRecord.indexArray.concat();
        let endRecordPop = endRecord.indexArray.concat();
        startRecordPop.pop();
        endRecordPop.pop();
        if (startRecord.indexArray.length === endRecord.indexArray.length) {
            if (startRecord.indexArray !== endRecord.indexArray) {
                let data = tableData.concat();
                switch (endRecord.indexArray.length) {
                    case 1:
                        data = update(data, {
                            $splice: [
                                [startRecord.indexArray[0], 1],
                                [endRecord.indexArray[0], 0, startRecord],
                            ],
                        });
                        break;
                    case 2:
                        data[startRecord.indexArray[0]].child = update(data[startRecord.indexArray[0]].child, {
                            $splice: [
                                [startRecord.indexArray[1], 1],
                                [endRecord.indexArray[1], 0, startRecord],
                            ]
                        });
                        break;
                    case 3:
                        data[startRecord.indexArray[0]].child[startRecord.indexArray[1]].child = update(data[startRecord.indexArray[0]].child[startRecord.indexArray[1]].child, {
                            $splice: [
                                [startRecord.indexArray[2], 1],
                                [endRecord.indexArray[2], 0, startRecord],
                            ]
                        });
                        break;
                    case 4:
                        data[startRecord.indexArray[0]].child[startRecord.indexArray[1]].child[startRecord.indexArray[2]].child = update(data[startRecord.indexArray[0]].child[startRecord.indexArray[1]].child[startRecord.indexArray[2]].child, {
                            $splice: [
                                [startRecord.indexArray[3], 1],
                                [endRecord.indexArray[3], 0, startRecord],
                            ]
                        });
                        break;
                    default:
                        return;
                }
                console.log(data);
                await categoryDrag(data)
                await getCategory()
            }
        } else {
            message.warning('不同层级不可排序');
        }
    };

    /**
     * 删除商品的确认框(Modal框)
     * @param records
     */
    const showDeleteConfirm = (record: {}): void => {
        Modal.confirm({
            content: '确认删除该分类吗?',
            icon: <ExclamationCircleOutlined/>,
            centered: true,
            bodyStyle: {
                fontSize: 16,
            },
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk: () => {
                delCategory(record);
            }
        });
    };
    /**
     * 确认框点击确认的回调
     * @param record
     */
    const delCategory = async (record: any) => {
        await DeleteCategory(record.id);
        await getCategory();
    };
    /**
     * 提交转移
     */
    const handlerTransfer = async () => {
        if (!productCategoryIdForm || !treeSelectValue.split('-')[0]) {
            return;
        }
        try {
            await TransferCategoryProduct(productCategoryIdForm, Number(treeSelectValue.split('-')[0]));
            message.success('商品转移成功');
        } catch (e) {
        }
        setTransferVisible(false);
    };
    /**
     * 选择转移分类
     */
    const transferSelect = async (e: any) => {
        let level = e.split('-')[1];
        if ((level.substr(1, 1) &&
                level.substr(1, 1) === level.substr(0, 1)) ||
            level == 1) {
            setTreeSelectValue(e);
        } else {
            message.warning('请选择末级分类');
        }
    };
    return (
        <div className='content_container'>
            <Modal
                title="转移商品"
                visible={transferVisible}
                onOk={handlerTransfer}
                onCancel={() => {
                    setTransferVisible(false);
                }}
                okText="确认"
                cancelText="取消"
                centered={true}
            >
                <Row>
                    <Col span={8}>转移至:</Col>
                    <Col span={12}>
                        <TreeSelect
                            style={{width: '100%'}}
                            value={treeSelectValue}
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            treeData={tableData}
                            placeholder="请选择"
                            treeDefaultExpandAll
                            onChange={(e) => {
                                transferSelect(e);
                            }}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: '18px'}}>
                    <Col span={12} offset={8}>
                        <span style={{fontSize: 10}}>*转移后，当前分类将删除，请谨慎操作</span>
                    </Col>
                </Row>
            </Modal>
            <div className={'main_wrap'}>
                <div className={'main_container'}>
                    <TableHead addType="新增分类" url="/new_product_classification"/>
                    <DndProvider backend={HTML5Backend}>
                        <Table
                            columns={columns}
                            dataSource={tableData}
                            pagination={false}
                            expandable={{
                                defaultExpandAllRows: true,
                                indentSize: 50,
                            }}
                            components={components}
                            onRow={(record, index): any => ({
                                record,
                                index,
                                moveRow,
                                onMouseEnter: () => {
                                    startRecord = record;
                                },
                            })}
                            scroll={{x:1000}}
                        />
                    </DndProvider>
                </div>
            </div>
        </div>
    );
};
export default ProductClassification;
