import '../new.less';
import {NewProductContext} from '../new';
import React, {useContext, useEffect} from 'react';
import {Button, Checkbox, Input, message, Popconfirm, Select, Table} from 'antd';
import {
    deleteSku,
    getSpecificationsAttributeList,
    getSpecificationsCategoryList
} from '../../../../../service/product.api';
import {IsDecimal, IsPositiveInteger, formatPrice} from '../../../../../utils';

const Step2 = () => {
    const {
        newProductData,
        newProductDispatch,
        lastProductAttributeCategory,
        setLastProductAttributeCategory
    } = useContext(NewProductContext);
    const {productAttributeCategory, skus, editDetail, specificationsList, attributes} = newProductData;

    useEffect(() => {
        if (editDetail && attributes.length == 0) {
            let data: Array<any> = [];
            editDetail.sku.forEach((res: any) => {
                let obj: any = {
                    id: res.id,
                    isDefault: res.default_sku === 1,
                    price: formatPrice(res.price),
                    promotionPrice: formatPrice(res.promotion_price),
                    stock: res.stock,
                };
                res.sp_data.forEach((res: any) => {
                    obj[res.key] = res.value;
                });
                data.push(obj);
            });
            newProductDispatch({
                type: 'setSkusData',
                value: data
            });
        }
        getSpecificationsList().then();
    }, []);

    useEffect(() => {
        if (editDetail) {
            refreshSkus();
        }
    }, [attributes]);

    useEffect(() => {
        if (productAttributeCategory && productAttributeCategory !== lastProductAttributeCategory) {
            setLastProductAttributeCategory(productAttributeCategory);
            getAttributeList().then();
        }
    }, [productAttributeCategory]);

    const getAttributeList = async () => {
        try {
            const res: any = await getSpecificationsAttributeList({product_attribute_category_id: productAttributeCategory});
            let array: Array<any> = [];
            res.result.data.forEach((res0: any) => {
                let inputList: Array<any> = [];
                res0.input_list.split(',').forEach((res: any) => {
                    let checked: boolean = false;
                    if (editDetail) {
                        editDetail.sku.forEach((result: any) => {
                            result.sp_data.forEach((result: any) => {
                                if (result.key == res0.name && result.value == res) {
                                    checked = true;
                                }
                            });
                        });
                    }
                    inputList.push({
                        name: res,
                        checked: checked
                    });
                });
                array.push({
                    id: res0.id,
                    name: res0.name,
                    input_list: inputList
                });
            });
            newProductDispatch({
                type: 'setAttributes',
                value: array
            });
        } catch (e) {

        }
    };

    const getSpecificationsList = async () => {
        try {
            const res: any = await getSpecificationsCategoryList({type: 1});
            newProductDispatch({
                type: 'setSpecificationsList',
                value: res.result.data
            });
        } catch (e) {

        }
    };

    const attributesCheck = (index1: number, index2: number) => {
        let list: Array<any> = attributes.concat();
        if (list[index1].input_list[index2].checked) {
            let checkedCount = 0;
            list[index1].input_list.forEach((res: any) => {
                if (res.checked) {
                    checkedCount++;
                }
            });
            if (checkedCount <= 1) {
                message.warning('每项规格至少保留一个选中状态');
                return;
            }
        }
        list[index1].input_list[index2].checked = !list[index1].input_list[index2].checked;
        newProductDispatch({
            type: 'setAttributes',
            value: list
        });
    };

    const pushNewAttribute = (value: string, index: number) => {
        let list: Array<any> = attributes.concat();
        let hasTheSame = false;
        list[index].input_list.forEach((res: any) => {
            if (res.name == value) {
                hasTheSame = true;
            }
        });
        if (!hasTheSame) {
            list[index].input_list.push({
                name: value,
                checked: false
            });
            newProductDispatch({
                type: 'setAttributes',
                value: list
            });
        }
    };

    const refreshSkus = () => {
        let list: Array<any> = attributes.concat();
        for (let i = 0; i < list.length; i++) {
            let checkedCount = 0;
            list[i].input_list.forEach((res: any) => {
                if (res.checked) {
                    checkedCount++;
                }
            });
            if (checkedCount === 0) {
                message.warning('每项规格至少保留一个选中状态');
                return;
            }
        }

        let columns: Array<any> = [];
        let data: Array<any> = [];
        attributes.forEach((item: any) => {
            let hasSku = false;
            if (data.length === 0) {
                item.input_list.forEach((value: any, index: number) => {
                    if (value.checked) {
                        hasSku = true;
                        data.push({
                            [item.name]: value.name,
                            isDefault: false,
                            price: '',
                            promotionPrice: '',
                            stock: ''
                        });
                    }
                });
            } else {
                let data2: Array<any> = [];
                item.input_list.forEach((value: any, index: number) => {
                    if (value.checked) {
                        hasSku = true;
                        let data1: Array<any> = [];
                        data.forEach((res: any, index: number) => {
                            data1.push(Object.assign({}, res, {[item.name]: value.name}));
                        });
                        data2 = [...data2, ...data1];
                    }
                });
                if (data2.length > 0) {
                    data = data2;
                }
            }
            hasSku && columns.push({
                title: item.name,
                dataIndex: item.name,
                key: item.id
            });
        });
        newProductDispatch({
            type: 'setSkusColumns1',
            value: [
                {
                    title: '默认商品',
                    dataIndex: 'isDefault',
                    key: 'isDefault',
                    render: (text: any, record: any) => {
                        return (
                            <Checkbox checked={text} onChange={() => {
                                newProductDispatch({
                                    type: 'skuDefaultCheck',
                                    value: skus.data.indexOf(record)
                                });
                            }}/>);
                    }
                },
                ...columns,
                ...[
                    {
                        title: '市场价格',
                        dataIndex: 'price',
                        key: 'price',
                        render: (text: any, record: any) => {
                            return (
                                <Input value={text} style={{width: 120}} prefix="￥" suffix="RMB" onInput={(e: any) => {
                                    newProductDispatch({
                                        type: 'skuPriceInput',
                                        value: {
                                            index: skus.data.indexOf(record),
                                            price: e.target.value
                                        }
                                    });
                                }} onBlur={(e: any) => {
                                    if (!IsDecimal(e.target.value) && e.target.value) {
                                        message.warning('请输入合规的正数（支持2位小数）');
                                        newProductDispatch({
                                            type: 'skuPriceInput',
                                            value: {
                                                index: skus.data.indexOf(record),
                                                stock: ''
                                            }
                                        });
                                    }
                                }}
                                />);
                        }
                    },
                    {
                        title: '库存',
                        dataIndex: 'stock',
                        key: 'stock',
                        render: (text: any, record: any) => {
                            return (<Input style={{width: 100}} value={text} suffix="件" onInput={(e: any) => {
                                newProductDispatch({
                                    type: 'skuStockInput',
                                    value: {
                                        index: skus.data.indexOf(record),
                                        stock: e.target.value
                                    }
                                });
                            }} onBlur={(e: any) => {
                                if (!IsPositiveInteger(e.target.value) && e.target.value) {
                                    message.warning('请输入非负整数');
                                    newProductDispatch({
                                        type: 'skuStockInput',
                                        value: {
                                            index: skus.data.indexOf(record),
                                            stock: ''
                                        }
                                    });
                                }
                            }}/>);
                        }
                    },
                    {
                        title: '操作',
                        dataIndex: 'action',
                        key: 'action',
                        render: (text: any, record: any) => {
                            return (
                                <Popconfirm
                                    title="确认删除?"
                                    onConfirm={async () => {
                                        if (record.id) {
                                            await deleteSku(record.id);
                                        }
                                        newProductDispatch({
                                            type: 'deleteSkuByIndex',
                                            value: skus.data.indexOf(record)
                                        });
                                    }}
                                    okText="确认"
                                    cancelText="取消"
                                >
                                    <Button type="primary" danger>删除</Button>
                                </Popconfirm>);
                        }
                    }
                ]]
        });
        newProductDispatch({
            type: 'setSkusColumns2',
            value: [
                ...columns,
                ...[
                    {
                        title: '市场价格',
                        dataIndex: 'price',
                        key: 'price'
                    },
                    {
                        title: '促销价格',
                        dataIndex: 'promotionPrice',
                        key: 'promotionPrice',
                        render: (text: any, record: any) => {
                            return (
                                <Input style={{width: 120}} value={text} prefix="￥" suffix="RMB" onInput={(e: any) => {
                                    newProductDispatch({
                                        type: 'skuPromotionPriceInput',
                                        value: {
                                            index: skus.data.indexOf(record),
                                            promotionPrice: e.target.value
                                        }
                                    });
                                }} onBlur={(e: any) => {
                                    if (!IsDecimal(e.target.value) && e.target.value) {
                                        message.warning('请输入合规的正数（支持2位小数）');
                                        newProductDispatch({
                                            type: 'skuPromotionPriceInput',
                                            value: {
                                                index: skus.data.indexOf(record),
                                                stock: ''
                                            }
                                        });
                                    }
                                }}/>);
                        }
                    }
                ]]
        });
        newProductDispatch({
            type: 'setSkusData',
            value: data
        });
    };

    return (
        <div className="form_wrap">
            <div className="info_title">属性规格</div>
            <div className="info_line"><span>*</span>选择规格</div>
            <div className="info_line">
                <Select defaultValue={productAttributeCategory} value={productAttributeCategory} placeholder="请选择"
                        style={{width: '100%'}}
                        onChange={(e: any) => {
                            if (skus.data.length > 0) {
                                message.warning('切换规格请删除当前所有商品');
                                return;
                            }
                            newProductDispatch({
                                type: 'setProductAttributeCategory',
                                value: e
                            });
                        }}>
                    {
                        specificationsList.map((res: any) => {
                            return (
                                <Select.Option key={res.id} value={res.id}>{res.name}</Select.Option>
                            );
                        })
                    }
                </Select>
            </div>
            {
                (attributes && attributes.length > 0) &&
                <>
                    <div className="info_line"><span>*</span>商品规格</div>
                    {
                        attributes.map((res: any, index1: number) => {
                            return (
                                <div key={res.id} className="attributes_block">
                                    <div className="info_line">{res.name}</div>
                                    <div className="info_line">
                                        {
                                            res.input_list.map((res: any, index2: number) => {
                                                return (
                                                    <Checkbox key={res.name} defaultChecked={res.checked}
                                                              checked={res.checked} onChange={() => {
                                                        attributesCheck(index1, index2);
                                                    }}>{res.name}</Checkbox>
                                                );
                                            })
                                        }
                                    </div>
                                    <div className="info_line">
                                        <div className={'add_specification'}>
                                            <Input
                                                style={{width: '400px'}}
                                                placeholder="请输入"
                                                suffix={
                                                    <span className={'add_text'} onClick={(e: any) => {
                                                        e.nativeEvent.path[2].childNodes[0].value &&
                                                        pushNewAttribute(e.nativeEvent.path[2].childNodes[0].value, index1);
                                                    }}>添加</span>
                                                }
                                            />
                                            <span className={'add_tips'}>
                                                <span style={{color: '#FF4D4F'}}>*</span>
                                                <span style={{marginLeft: '4px'}}>添加后手动刷新列表</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            );
                        })
                    }
                </>
            }
            <div className="info_line"><span>*</span>规格属性</div>
            <div className="info_line">
                <div style={{width: '100%'}}>
                    <Table dataSource={skus.data} columns={skus.columns1} pagination={false}/>
                </div>
            </div>
            <div className="info_line" style={{justifyContent: 'flex-end'}}><Button onClick={refreshSkus}>刷新列表</Button>
            </div>
        </div>
    );
};
export default Step2;
