import {Get, Post} from '../utils/request';
import _ from 'lodash';

export const GetSaleCount = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/statistical/sale/number');
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const GetSaleMoney = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/statistical/sale/money');
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const GetUserCount = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/statistical/user/count');
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const GetVisitCount = (req: { begin: string, end: string, platform: string }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/statistical/uv/count', req);
            res.result.forEach((res: any) => {
                res.value = Number(res.value);
            });
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const GetRangeSaleCount = (req: { begin: string, end: string, platform: string }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/statistical/sale/time', req);
            res.result.forEach((res: any) => {
                res.value = Number(res.value);
            });
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const GetRangeSaleMoney = (req: { begin: string, end: string, platform: string }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/statistical/sale/amount', req);
            res.result.forEach((res: any) => {
                res.value = Number(res.value);
            });
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const GetRangeCollectCount = (req: { begin: string, end: string, platform: string }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/statistical/collect/count', req);
            res.result.forEach((res: any) => {
                res.value = Number(res.value);
            });
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const GetChannelRatio = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/statistical/channel/prop');
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const GetHotWords = (req: { page: number, size: number, platform: string }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/search/list', req);
            let array: { key: string; keywords: string; sort: string; count: number }[] = [];
            res.result.list.forEach((res: any) => {
                array.push({
                    key: _.keys(res)[0],
                    keywords: _.keys(res)[0],
                    sort: _.values(res)[0].point,
                    count: _.values(res)[0].nums,
                });
            });
            resolve(_.assign(res.result, {array: array}));
        } catch (e) {
            //reject(e);
        }
    });
};

export const GetLeaderboards = (req: { page: number, size: number, week: number }) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/statistical/sale/rank', req);
            let array: { key: number; sort: number; name: string; sale: number, income: number }[] = [];
            res.result.list.forEach((res: any) => {
                array.push({
                    key: res.id,
                    sort: res.id,
                    name: res.name,
                    sale: res.num,
                    income: res.money
                });
            });
            resolve(_.assign(res.result, {array: array}));
        } catch (e) {
            //reject(e);
        }
    });
};

export const GetDunfengAccount = () => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/dunphone/account/info');
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const GetDunfengData = (t_range: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/dunphone/ad/info', {t_range});
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const LoginDunfeng = (dunphone_account: string, account_password: string) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Post('/api/v1/dunphone/login', {dunphone_account, account_password});
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};
