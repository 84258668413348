import {PlusOutlined} from '@ant-design/icons';
import React from 'react';


//图片上传按钮
const UploadImgButton = (props: { name?: string }) => {
    const {name} = props;
    return (

        <div style={{width:'100%'}}>
            <PlusOutlined/>
            <div style={{marginTop: 8,width:'100%',wordBreak:'break-word'}}>{name ? name : '上传'}</div>
        </div>
    );
};
export default UploadImgButton;
