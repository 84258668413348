import {Get, Post} from '../../utils/request';
/**
 *
 * 获取反馈列表
 * @param req
 */
export const getList = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/feedback/query', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 *
 * 删除反馈
 * @param req
 */
export const delFeedBack = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/admin/api/v1/feedback/query', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
