import './statistics_card.less';

const StatisticsCard = (props: { name: string, mainStatistics: string, leftName: string, leftStatistics: string, rightName: string, rightStatistics: string, icon: any }) => {
    const {name, mainStatistics, leftName, leftStatistics, rightName, rightStatistics, icon} = props;
    return (
        <div className="statistic_container">
            <div className="title">{name}</div>
            <div className="main_statistics" style={{backgroundImage: `url(${icon})`}}>{mainStatistics}</div>
            <div className="bottom_line">
                <div>
                    <span style={{marginRight: 8}}>{leftName}</span>
                    <span>{leftStatistics}</span>
                </div>
                <div style={{textAlign: 'right'}}>
                    <span>{rightName}</span>
                    <span style={{marginLeft: 8}}>{rightStatistics}</span>
                </div>
            </div>
        </div>
    );
};
export default StatisticsCard;
