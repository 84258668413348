import React, {useEffect, useState} from 'react';
import {Button, DatePicker, Input, Table} from 'antd';
import {Link} from 'react-router-dom';
import CustomPagination from '../../../components/custom_pagination/custom_pagination';
import moment, {Moment} from 'moment';
import {GetRefundList} from '../../../service/refund/refund.apis';
import {formatPrice} from '../../../utils';

const {Search} = Input;
const {RangePicker} = DatePicker;

const PAGE_SIZE = 10;
export const statusMap = new Map();
statusMap.set(1, '待处理');
statusMap.set(2, '待回寄');
statusMap.set(3, '待签收');
statusMap.set(4, '已退款');
statusMap.set(5, '拒绝受理');
statusMap.set(6, '拒绝签收');
const typeMap = new Map();
typeMap.set(40, '退货');
typeMap.set(50, '退款');
const RefundList = () => {
    const [searchContent, setSearchContent] = useState('');
    const [orderTime, setOrderTime] = useState<[Moment, Moment]>();
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);
    const [tableData, setTableData] = useState<Array<any>>([]);
    const columns: Array<any> = [
        {
            title: '订单编号',
            dataIndex: 'order_id',
            key: 'order_id',
            width: 160,
        },
        {
            title: '商品名称',
            dataIndex: 'product_name',
            key: 'product_name',
        },
        {
            title: '退款金额 （¥）',
            dataIndex: 'return_amount',
            key: 'return_amount',
            render : (text:any) => (formatPrice(text))
        },
        {
            title: '用户昵称',
            dataIndex: 'member_name',
            key: 'member_name'
        },
        {
            title: '申请时间',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 200,
            render: (text: any) => (moment.unix(text).format('YYYY-MM-DD HH:mm:ss'))
        },
        {
            title: '售后类型',
            dataIndex: 'return_type',
            key: 'return_type',
            width: 100,
            render: (text: any) => (typeMap.get(text))
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (text: any) => (statusMap.get(text))

        },
        {
            title: '操作',
            width: 100,
            dataIndex: 'id',
            key: 'id',
            render: (text: any) => (
                <Link to={{
                    pathname: '/refund_list_detail',
                    search: `id=${text}`
                }}>
                    <Button>查看</Button>
                </Link>
            )
        }
    ];

    useEffect(() => {
        setRefundList().then();
    }, [currentPage, searchContent, orderTime]);

    const onSearch = async (content: string) => {
        setSearchContent(content);
        setCurrentPage(1);
        // await setRefundList();
    };

    const setRefundList = async () => {
        const res:any=await GetRefundList({
            size: PAGE_SIZE,
            page: currentPage,
            start: orderTime?.[0] ? orderTime[0].unix() : 0,
            end: orderTime?.[1] ? orderTime[1].unix() : 0,
            kwd: searchContent
        })
        setTableData(res.data);
        setCount(res.count)
    };

    return (
        <div className="content_container">
            <div className={'main_wrap'}>
                <div className={'main_container'}>
                    <div style={{display: 'flex', alignItems: 'center', margin: '16px 0'}}>
                        <div style={{marginRight: '20px'}}>
                            <RangePicker
                                value={orderTime}
                                showTime
                                onChange={(data: any) => setOrderTime(data)}
                                style={{width: 260}}
                            />
                        </div>
                        <div className={'flex_1'}>
                            <Search
                                placeholder="输入订单编号、商品名称、用户昵称进行搜索"
                                onSearch={onSearch}
                                enterButton="搜索"
                                allowClear={true}
                            />
                        </div>
                    </div>
                    <div className={'table_wrap'}>
                        <Table dataSource={tableData} columns={columns} pagination={false} scroll={{y: 680, x: 1000}}/>
                    </div>
                </div>
                <CustomPagination
                    currentPage={currentPage}
                    total={count}
                    onChange={(page: number) => setCurrentPage(page)}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </div>
    );
};
export default RefundList;
