import React, {useEffect, useState} from 'react'
import {Button, Col, Input, message, Row, Space} from 'antd';
import OrderSelect from "./OrderSelect";
import {getOrderOptions,updateOrderOptions} from '../../../service/setting/order.api'
import './order.less'

const {TextArea} = Input;
const OrderSetting = () => {
  const [limitTime,setLimitTime] = useState<number>();
  const [limitTimeType,setLimitTimeType] = useState<number>(1);
  const [normalTime,setNormalTime] = useState<number>();
  const [normalTimeType,setNormalTimeType] = useState<number>(1);
  const [sendTime,setSendTime] = useState<number>();
  const [sendTimeType,setSendTimeType] = useState<number>(1);
  const [receiveTime,setReceiveTime] = useState<number>();
  const [receiveTimeType,setReceiveTimeType] = useState<number>(1);
  const [backMoneyReason,setBackMoneyReason] = useState<string>();
  const [backCommodity,setBackCommodity] = useState<string>();
  useEffect(()=>{
    getInfo().then()
  },[])
  const getInfo = async ()=>{
    try{
      const {result}:any = await getOrderOptions({})
      _setInfo(result)
    }catch (e){
      console.log(e)
    }
  }
  const save = async ()=>{
    try{
      const req = await updateOrderOptions([
        {name:'order_limit_timeout',value:String(limitTime)},
        {name:'order_normal_timeout',value:String(normalTime)},
        {name:'order_send_timeout',value:String(sendTime)},
        {name:'order_received_timeout',value:String(receiveTime)},
        {name:'order_refund_reason',value:String(backMoneyReason)},
        {name:'order_return_reason',value:String(backCommodity)},
        {name:'order_limit_timeout_type',value:String( limitTimeType)},
        {name:'order_normal_timeout_type',value:String(normalTimeType)},
        {name:'order_send_timeout_type',value:String(sendTimeType)},
        {name:'order_received_timeout_type',value:String(receiveTimeType)},
      ])
      console.log(req)
      message.success('保存成功')
    }catch (e){
      console.log(e)
    }
  }
  const _setInfo = (info:Array<any>)=>{
    console.log(info)
    info.forEach(item=>{
      const {name,value} = item
      switch (name){
        case "order_limit_timeout":
          setLimitTime(value)
          break;
        case "order_normal_timeout":
          setNormalTime(value)
          break;
        case "order_send_timeout":
          setSendTime(value)
          break;
        case "order_received_timeout":
          setReceiveTime(value)
          break;
        case "order_refund_reason":
          setBackMoneyReason(value)
          break;
        case "order_return_reason":
          setBackCommodity(value)
          break;
        case "order_limit_timeout_type":
          setLimitTimeType(Number(value))
          break;
        case "order_normal_timeout_type":
          setNormalTimeType(Number(value))
          break;
        case "order_send_timeout_type":
          setSendTimeType(Number(value))
          break;
        case "order_received_timeout_type":
          setReceiveTimeType(Number(value))
          break;
        default:
          return;
      }
    })
  }
  return(
      <div className='add_body_wrap'>
        <div className='add_body'>
          <div className='form_wrap order_wrap'>
            <div className='order_row'>
              <div className='before'>
                限时活动订单超过:
              </div>
              <Input
                  value={limitTime}
                  type={'number'}
                  addonAfter={
                   <OrderSelect
                       value={limitTimeType}
                       onChange={(value:number)=>setLimitTimeType(value)}
                   />
                  }
                  onChange={(e)=>setLimitTime(Number(e.target.value))}
              />
              <div className='after'>
                未付款，订单自动关闭
              </div>
            </div>
            <div className='order_row'>
              <div className='before'>
                正常订单超过:
              </div>
              <Input
                  value={normalTime}
                  type={'number'}
                  addonAfter={
                    <OrderSelect
                        value={normalTimeType}
                        onChange={(value:number)=>setNormalTimeType(value)}
                    />
                  }
                   onChange={(e)=>setNormalTime(Number(e.target.value))}
              />
              <div className='after'>
                未付款，订单自动关闭
              </div>
            </div>
            <div className='order_row'>
              <div className='before'>
                发货超过:
              </div>
              <Input
                  value={sendTime}
                  type={'number'}
                  addonAfter={
                    <OrderSelect
                        value={sendTimeType}
                        onChange={(value:number)=>setSendTimeType(value)}
                    />
                  }
                   onChange={(e)=>setSendTime(Number(e.target.value))}
              />
              <div className='after'>
                未签收，订单自动完成
              </div>
            </div>
            <div className='order_row'>
              <div className='before'>
                订单签收超过:
              </div>
              <Input
                  value={receiveTime}
                  type={'number'}
                  addonAfter={
                    <OrderSelect
                        value={receiveTimeType}
                        onChange={(value:number)=>setReceiveTimeType(value)}
                    />
                  }
                   onChange={(e)=>setReceiveTime(Number(e.target.value))}
              />
              <div className='after'>
                自动结束交易，不能申请售后
              </div>
            </div>
            <div className='order_row'>
              <div className='before' style={{width:'20%'}}>
                退款原因:
              </div>
              <TextArea
                  value={backMoneyReason}
                  onChange={(e)=>setBackMoneyReason(e.target.value)}
                  rows={6}
              />
            </div>
            <div className='order_row'>
              <div className='before' style={{width:'20%',alignItems:"flex-start"}}>
                退货原因:
              </div>
              <TextArea
                  value={backCommodity}
                  onChange={(e)=>setBackCommodity(e.target.value)}
                  rows={6}
              />
            </div>
          </div>
          {/*<Row style={{marginTop:16}}>*/}
          {/*  <Col span={2} offset={16}>*/}
          {/*    <Button type={"primary"} onClick={save}>保存</Button>*/}
          {/*  </Col>*/}
          {/*</Row>*/}
          <div className={'add_footer'}>
            <Space>
              <Button type={"primary"} onClick={save}>保存</Button>
            </Space>
          </div>
        </div>
      </div>
  )
}
export default OrderSetting
