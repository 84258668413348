import {Post,Get} from '../../utils/request'

/**
 *
 * 门店列表
 * @param req
 */
export const getList = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/company/address/query', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 *
 * 门店列表
 * @param req
 */
export const addShop = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/company/address/add', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 *
 * 更新门店地址
 * @param req
 */
export const updateAddress = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/company/address/update', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 *
 * 删除门店
 * @param req
 */
export const delShop = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/company/address/delete', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};


