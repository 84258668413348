import React, {useEffect, useState} from 'react';
import {Checkbox, Col, Form, Input, Modal, Row, message} from 'antd';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {formatPrice} from '../../../../utils';
import SendModal from '../components/send_modal';
import './order_item.less';

export const map = new Map();
map.set(-1, '全部状态');
map.set(0, '待支付');
map.set(10, '待发货');
map.set(20, '待收货');
map.set(30, '已签收');
map.set(40, '已退货');
map.set(50, '已退款');
map.set(60, '已关闭');
const OrderItem = (props: any) => {
    const {order, getOrderList} = props;
    const [transForm] = Form.useForm();
    const {id, created_at, status, total_amount, discount_amount, pay_amount, list, order_item} = order;
    const [sendVisible, setSendVisible] = useState(false);


    return (
        <div className={'order_item_wrap'}>
            <div className={'header'}>
                <div className={'info'}>
                    <div className={'item'}>
                        <span style={{marginRight: 10}}>订单编号:</span>{id}
                    </div>
                    <div className={'item'}>
                        <span style={{marginRight: 10}}>下单时间:</span>{moment.unix(created_at).format('YYYY-MM-DD')}
                    </div>
                    <div className={'item'}>
                        <span style={{marginRight: 10}}>订单状态:</span>{map.get(status)}
                    </div>
                </div>
                <div className={'action'}>

                    {(status === 10) && <span onClick={() => setSendVisible(true)}>发货</span>}
                    <Link to={{
                        pathname: '/order_list_detail',
                        search: `id=${id}`
                    }}>
                        订单详情
                    </Link>
                </div>
            </div>
            <div className={'body'}>
                <div className={'commodity'}>
                    {
                        order_item && order_item.map((item: any) => {
                            const {
                                product_pic,
                                product_name,
                                sp_data,
                                product_price,
                                product_quantity,
                                deleted_at,
                                status,
                                send_time
                            } = item;
                            return (
                                <div className={'detail'} key={item.id}>
                                    <img
                                        src={localStorage.getItem('divms_url') + product_pic}
                                        className={'picture'}
                                    />
                                    <div className="name">
                                        <div>
                                            {product_name}
                                        </div>
                                        <div>
                                            {sp_data && sp_data.map((sp: any, index: number) => (
                                                <span key={index}>{sp.value}{index !== sp_data.length - 1 && '、'}</span>
                                            ))}
                                        </div>
                                        {
                                            (status !== 0 && status !== 10 && status !== 60) &&
                                            <div>
                                                <span>发货时间：</span>
                                                <span>{moment.unix(send_time).format('YYYY-MM-DD HH:mm:ss')}</span>
                                            </div>
                                        }
                                    </div>
                                    <div className="text">¥{formatPrice(product_price)}</div>
                                    <div className="text">{product_quantity}</div>
                                    <div className="text">{map.get(status)}</div>
                                </div>
                            );
                        })
                    }
                </div>
                {
                    (order_item && order_item instanceof Array && order_item.length >= 1) &&
                    (
                        <>
                            <div className={'address_container'}>
                                <div className={'address'}>
                                    <div>
                                        <span style={{marginRight: 16}}>{order_item[0].receiver_name}</span>
                                        <span>{order_item[0].receiver_phone}</span>
                                    </div>
                                    <div>
                                        <span>{order_item[0].receiver_province && order_item[0].receiver_province + '/'}</span>
                                        <span>{order_item[0].receiver_city && order_item[0].receiver_city + '/'}</span>
                                        <span>{order_item[0].receiver_region && order_item[0].receiver_region}</span>
                                    </div>
                                    <div>{order_item[0].receiver_detail_address}</div>
                                </div>
                            </div>
                            <div className={'address_container'}>
                                <div className={'pay_info'}>
                                    <div><span style={{marginRight: 10}}>商品合计：</span>¥{formatPrice(total_amount)}</div>
                                    <div><span
                                        style={{marginRight: 10}}>优惠：</span>-¥{discount_amount}</div>
                                    <div><span style={{marginRight: 10}}>实际付款：</span>¥{formatPrice(pay_amount)}</div>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
            <SendModal
                sendVisible={sendVisible}
                setSendVisible={setSendVisible}
                list={order_item}
                getOrderList={getOrderList}
                orderId={id}
            />
        </div>
    );
};
export default OrderItem;
