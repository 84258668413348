import './menu.less';
import {PublicContext} from '../../context';
import {createHashHistory} from 'history';
import {Menu} from 'antd';
import {useContext, useEffect, useState} from 'react';
import Logo from '../../assets/images/logo.png';

const MainMenu = () => {
    const {publicData} = useContext(PublicContext);
    const [current, setCurrent] = useState<string>('');

    useEffect(() => {
        setCurrent(createHashHistory().location.pathname);
    }, []);

    return (
        <Menu mode="inline" theme={'dark'} selectedKeys={[current]} onClick={res => {
            setCurrent(res.key);
            createHashHistory().replace(res.key);
        }}>
            <Menu.Item disabled key={'menu_logo'} style={{cursor: 'default'}}>
                <img className="menu_logo" src={Logo} alt="" draggable={false}/>
            </Menu.Item>
            {
                publicData.menuRouter.map((res: any) => {
                    return (
                        res.component ?
                            (
                                (res.isMenu && publicData.authList.indexOf(res.authId) >= 0) &&
                                <Menu.Item key={res.route} icon={res.icon ? res.icon : null}>{res.name}</Menu.Item>
                            ) :
                            (
                                (res.isMenu && publicData.authList.indexOf(res.authId) >= 0) &&
                                <Menu.SubMenu key={res.name} title={res.name} icon={res.icon ? res.icon : null}>
                                    {
                                        res.children.map((res: any) => {
                                            return (
                                                res.component && publicData.authList.indexOf(res.authId) >= 0 ?
                                                    (
                                                        (res.isMenu && publicData.authList.indexOf(res.authId) >= 0) &&
                                                        <Menu.Item key={res.route}>{res.name}</Menu.Item>
                                                    ) :
                                                    (
                                                        (res.isMenu && publicData.authList.indexOf(res.authId) >= 0) &&
                                                        <Menu.ItemGroup title={res.name} key={res.name}>
                                                            {
                                                                res.children.map((res: any) => {
                                                                    return (
                                                                        (res.isMenu && publicData.authList.indexOf(res.authId) >= 0) &&
                                                                        <Menu.Item
                                                                            key={res.route}>{res.name}</Menu.Item>
                                                                    );
                                                                })
                                                            }
                                                        </Menu.ItemGroup>
                                                    )
                                            );
                                        })
                                    }
                                </Menu.SubMenu>
                            )
                    );
                })
            }
        </Menu>
    );
};
export default MainMenu;
