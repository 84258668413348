import React, {useState, useEffect} from 'react';
import {Table, Space, Modal, Button, Tabs, message, Divider} from 'antd';
import {ExclamationCircleOutlined} from '@ant-design/icons';
import TableHead from '../../../components/table_head/table_head';
import CustomPagination from '../../../components/custom_pagination/custom_pagination';
import {delProduct, getProduct, putProduct} from '../../../service/product.api';
import './list.less';
import {Link} from 'react-router-dom';
import {useHistory} from 'react-router-dom';
import {searchObj, formatPrice} from '../../../utils';

const ProductList = () => {
    const history = useHistory();
    //单页数据量
    const PAGE_SIZE = 20;
    //上/下架状态 1下架2上架
    const [isPut, setIsPut] = useState(2);
    // 分类
    const [kind, setKind] = useState(0);
    //搜索内容
    const [searchContent, setSearchContent] = useState('');
    //表格(商品)数据
    const [tableData, setTableData] = useState([]);
    //商品列表columns信息
    const columns: any = [
        {
            title: '商品ID',
            dataIndex: 'id',
            key: 'id',
            width: 200,
            render : (text: any, record: any) => (
                <Link to={`/edit_product?id=${record.id}`}>{record.id}</Link>
            ),
        },
        {
            title: '商品信息',
            dataIndex: 'name',
            key: 'name',
            render: (text: any, record: any) => (
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Link to={`/edit_product?id=${record.id}`}><img style={{width: '48px', height: '48px', marginRight: '18px'}}
                         src={localStorage.getItem('divms_url') + record.cover_pic} alt="" draggable={false}/></Link>
                    <div><Link to={`/edit_product?id=${record.id}`}>{text}</Link></div>
                </div>
            ),
        },
        {
            title: '价格（￥）',
            dataIndex: 'price',
            key: 'id',
            width: 140,
            render: (text: any, record: any) => (
                formatPrice(record.default_sku.price)
            ),
        },
        {
            title: '销量',
            dataIndex: 'sale',
            key: 'id',
            width: 140,
        },
        {
            title: '访问量',
            dataIndex: 'view',
            key: 'id',
            width: 140,
        },
        {
            title: '操作',
            key: 'action',
            width: 260,
            fixed: 'right',
            render: (text: any, record: any) => (
                <Space size={'small'}>
                    <Link to={`/edit_product?id=${record.id}`}>编辑</Link>
                    <Divider type="vertical"/>
                    <a onClick={() => {
                        Modal.confirm({
                            content: `确认${record.publish_status === 2 ? '下' : '上'}架该商品吗?`,
                            icon: <ExclamationCircleOutlined/>,
                            centered: true,
                            bodyStyle: {
                                fontSize: 16,
                            },
                            okText: '是',
                            okType: 'danger',
                            cancelText: '否',
                            onOk: async () => {
                                try {
                                    await putProduct({id: record.id, type: record.publish_status === 2 ? 1 : 2});
                                    await getTableData();
                                } catch (e) {

                                }
                            }
                        });
                    }}>{record.publish_status === 2 ? '下' : '上'}架
                    </a>
                    <Divider type="vertical"/>
                    <Link to={{
                        pathname: '/order_list',
                        search: `kwd=${record.name}`
                    }}>订单</Link>
                    <Divider type="vertical"/>
                    <a onClick={() => showDeleteConfirm(record)}>删除</a>
                </Space>
            ),
        },
    ];
    //当前页码
    const [currentPage, setCurrentPage] = useState(1);
    //总页数总数
    const [count, setCount] = useState(0);

    // //页面初次渲染完成后获取表格数据
    // useEffect(() => {
    //     if (currentPage === 1) getTableData().then();
    //     else setCurrentPage(1);
    // }, [searchContent, isPut]);
    useEffect(() => {
        if (history.location.search !== "") {
            let params = searchObj(history.location.search);
            if (params.kind) {
                setKind(parseInt(params.kind));
            }
        }
        getTableData().then();
    }, [currentPage, searchContent, isPut, kind]);

    /**
     * 获取表格数据
     */
    const getTableData = async () => {
        const params: {} = {
            type: isPut,
            kind: kind,
            kwd: searchContent,
            page: currentPage,
            size: PAGE_SIZE,
        };
        try {
            const req: any = await getProduct(params);
            const {result} = req;
            const {data, count} = result;
            setCount(count);
            data.forEach((res: any) => {
                res.key = res.id;
            });
            setTableData(data);
        } catch (e) {

        }
    };

    /**
     * 切换上架商品/下架商品
     */
    const changeFilter = (type: any) => {
        type = Number(type) === 1 ? 2 : 1;
        if (type === isPut) return;
        setCurrentPage(1);
        setSearchContent('');
        setIsPut(type);
    };
    /**
     * 用于处理搜索业务
     */
    const onSearch = (searchContent: string) => {
        setSearchContent(searchContent);
    };
    /**
     * 删除商品的确认框(Modal框)
     * @param records
     */
    const showDeleteConfirm = (records: {}): void => {
        Modal.confirm({
            content: '确认删除该商品吗?',
            icon: <ExclamationCircleOutlined/>,
            centered: true,
            bodyStyle: {
                fontSize: 16,
            },
            okText: '是',
            okType: 'danger',
            cancelText: '否',
            onOk: async () => {
                await delCommodity(records);
            }
        });
    };
    /**
     * 确认框点击确认的回调
     * @param record
     */
    const delCommodity = async (record: any) => {
        await delProduct({id: record.id});
        message.success('删除成功');
        await getTableData();
    };

    return (
        <div className="content_container">
            <Tabs defaultActiveKey="1" style={{padding: '0 40px', backgroundColor: '#FFFFFF', width: '100%'}}
                  onChange={changeFilter}>
                <Tabs.TabPane tab="上架" key="1"/>
                <Tabs.TabPane tab="下架" key="2"/>
            </Tabs>
            <div className={'main_wrap'}>
                <div className={'main_container'}>
                    <TableHead onSearch={onSearch} addType="新增商品" url="/new_product"/>
                    <div className={'table_wrap'}>
                        <Table dataSource={tableData} columns={columns} pagination={false} scroll={{y: 640, x: 1000}}/>
                    </div>
                </div>
                <CustomPagination
                    currentPage={currentPage}
                    total={count}
                    onChange={(page: number) => setCurrentPage(page)}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </div>
    );
};
export default ProductList;
