import {Get, Post} from '../../utils/request';
/**
 *
 * 获取用户列表
 * @param req
 */
export const getList = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/user/query', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 *
 * 注销用户
 * @param req
 */
export const logoutUser = (req: {}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/user/status', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
