import {Button, Col, Descriptions, Image, Input, message, Modal, Row, Select, Steps} from 'antd';
import React, {Fragment, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {AgreeRefund, AgreeSendBack, GetRefundDetail, RefuseRefund} from '../../../../service/refund/refund.apis';
import {PublicContext} from '../../../../context';
import {statusMap} from '../refund';
import moment from 'moment';
import {getList} from '../../../../service/setting/shop.api.tx';
import {formatPrice} from '../../../../utils';

const RefundListDetail = () => {
    const history = useHistory();
    const {publicData} = useContext(PublicContext);
    const {imgDomain} = publicData;
    const [detail, setDetail] = useState<any>(null);
    const [refuseVisible, setRefuseVisible] = useState<boolean>(false);
    const [sendBackVisible, setSendBackVisible] = useState<boolean>(false);
    const [refuseReason, setRefuseReason] = useState<string>('');
    const [shopList, setShopList] = useState<Array<any>>([]);
    const [shopId, setShopId] = useState<number | null>(null);

    useEffect(() => {
        setRefundDetail().then();
    }, []);

    const setRefundDetail = async () => {
        if (!history.location.search) return;
        const id = history.location.search.substring(4);
        setDetail(await GetRefundDetail(Number(id)));
        const {result: {data}}: any = await getList({
            page: 1,
            size: 100,
            kwd: ''
        });
        setShopList(data);
    };

    /**
     * 拒绝操作
     * @param step 1=拒绝申请  2=拒绝签收
     */
    const refuseStep = async (step: number) => {
        await RefuseRefund({
            id: detail.id,
            refuse_type: step,
            refuse_reason: refuseReason
        });
        message.success('操作成功');
        setRefuseVisible(false);
        setRefuseReason('');
        setDetail(await GetRefundDetail(detail.id));
    };

    const checkAgreeRefund = () => {
        Modal.confirm({
            content: '是否确定同意退款，同意后款项将原路退回至用户支付账户',
            icon: null,
            cancelText: '取消',
            okText: '确认',
            onOk: async () => {
                await agreeRefund();
                Modal.destroyAll();
            }
        });
    };

    const agreeRefund = async () => {
        await AgreeRefund(detail.id);
        message.success('操作成功');
        setDetail(await GetRefundDetail(detail.id));
    };

    const sendBack = async () => {
        if (shopId) {
            setSendBackVisible(false);
            await AgreeSendBack(detail.id, Number(shopId));
            message.success('操作成功');
            setDetail(await GetRefundDetail(detail.id));
            setShopId(null);
        } else {
            message.warning('请选择门店');
        }
    };

    return (
        <>
            {
                detail &&
                <div className={'add_body_wrap'}>
                    <div className={'add_body'} style={{paddingBottom: 24}}>
                        <div className={'order_detail_wrap'}>
                            <div className={'order_step_wrap'}>
                                {
                                    detail.return_type === 40 ?
                                        <Steps current={detail.status <= 3 ? detail.status - 1 : 3}>
                                            <Steps.Step title="待处理"/>
                                            <Steps.Step title="待回寄"/>
                                            <Steps.Step title="待签收"/>
                                            <Steps.Step
                                                title={detail.status <= 3 ? '已退款' : statusMap.get(detail.status)}/>
                                        </Steps> :
                                        <Steps current={detail.status === 1 ? 0 : 1}>
                                            <Steps.Step title="待处理"/>
                                            <Steps.Step
                                                title={detail.status === 1 ? '已退款' : statusMap.get(detail.status)}/>
                                        </Steps>
                                }
                            </div>
                            <Row style={{minWidth: 900, display: 'flex', justifyContent: 'flex-end'}}>
                                {
                                    detail.status === 1 ?
                                        <Fragment>
                                            <span style={{marginTop: 10, marginRight: 10}}>
                                                <Button onClick={() => {
                                                    setRefuseVisible(true);
                                                }}>拒绝退{detail.return_type === 40 ? '货' : '款'}</Button>
                                            </span>
                                            <span style={{marginTop: 10}}>
                                                {
                                                    detail.return_type === 40 ?
                                                        <Button
                                                            type={'primary'}
                                                            onClick={() => {
                                                                setSendBackVisible(true);
                                                            }}
                                                        >确认退货</Button> :
                                                        <Button
                                                            type={'primary'}
                                                            onClick={checkAgreeRefund}
                                                        >确认退款</Button>
                                                }
                                            </span>
                                        </Fragment> :
                                        detail.status === 3 ?
                                            <Fragment>
                                            <span style={{marginTop: 10, marginRight: 10}}>
                                                    <Button onClick={() => {
                                                        setRefuseVisible(true);
                                                    }}>拒绝收货</Button>
                                            </span>
                                                <span style={{marginTop: 10}}>
                                                <Button
                                                    type={'primary'}
                                                    onClick={checkAgreeRefund}
                                                >收货并退款</Button>
                                            </span>
                                            </Fragment> : null
                                }
                            </Row>
                            {
                                (detail.status === 5 || detail.status === 6) &&
                                <Descriptions title={`${statusMap.get(detail.status)}理由`}
                                              style={{
                                                  border: '1px solid #E8E8E8',
                                                  padding: '20px',
                                                  margin: '20px 0 10px 0'
                                              }}>
                                    <Descriptions.Item label="">{detail.refuse_reason}</Descriptions.Item>
                                </Descriptions>
                            }
                            {
                                (detail.status > 2 && detail.return_type === 40 && detail.status !== 5) &&
                                <Descriptions title="回寄物流"
                                              style={{
                                                  border: '1px solid #E8E8E8',
                                                  padding: '20px',
                                                  margin: '20px 0 10px 0'
                                              }}>
                                    <Descriptions.Item label="物流公司">{detail.delivery_company}</Descriptions.Item>
                                    <Descriptions.Item label="物流单号">{detail.delivery_sn}</Descriptions.Item>
                                </Descriptions>
                            }
                            {
                                (detail.status > 1 && detail.return_type === 40 && detail.status !== 5) &&
                                <Descriptions title="回寄门店"
                                              style={{
                                                  border: '1px solid #E8E8E8',
                                                  padding: '20px',
                                                  margin: '20px 0 10px 0'
                                              }}>
                                    <Descriptions.Item
                                        label="门店名称">{detail.company_address.address_name}</Descriptions.Item>
                                    <Descriptions.Item label="联系人">{detail.company_address.name}</Descriptions.Item>
                                    <Descriptions.Item label="手机号码">{detail.company_address.phone}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="所在地区">{detail.company_address.province + ' ' + detail.company_address.city}</Descriptions.Item>
                                    <Descriptions.Item
                                        label="详细地址">{detail.company_address.detail_address}</Descriptions.Item>
                                </Descriptions>
                            }
                            <Row>
                                <Col span={24}
                                     style={{marginTop: 10, textIndent: 20, fontSize: '16px', fontWeight: 'bold'}}>
                                    商品信息
                                </Col>
                                <Col span={24} className={'order_info'} style={{marginBottom: 16}}>
                                    <div className={'detail'}>
                                        <img
                                            src={imgDomain + detail.product_pic}
                                            className={'picture'}
                                        />
                                        <div className="name">
                                            <div>
                                                {detail.product_name}
                                            </div>
                                            <div>
                                                {detail.product_attr && detail.product_attr.map((sp: any, index: number) => (
                                                    <span>{sp.value}{index !== detail.product_attr.length - 1 && '、'}</span>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="text">¥{formatPrice(detail.product_price)}</div>
                                        <div className="text">{detail.product_count}</div>
                                    </div>
                                    <Row style={{padding: 10, color: 'rgba(0, 0, 0, 0.65)', textAlign: 'right'}}>
                                        <Col span={4} offset={16}>
                                            <p>商品合计</p>
                                            <p>运费</p>
                                            <p>优惠券</p>
                                            <p>限时促销</p>
                                            <p>实际付款</p>
                                        </Col>
                                        <Col span={4}>
                                            <p>¥{formatPrice(detail.order_amount)}</p>
                                            <p>{detail.delivery_amount === 0 ? '包邮' : `￥${detail.delivery_amount}`}</p>
                                            <p>- ¥{formatPrice(detail.coupon_amount)}</p>
                                            <p>- ¥{formatPrice(detail.discount_amount)}</p>
                                            <p>¥{formatPrice(detail.pay_amount)}</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Descriptions title="退款信息"
                                          style={{border: '1px solid #E8E8E8', padding: '20px', margin: '10px 0'}}>
                                <Descriptions.Item label="状态">{statusMap.get(detail.status)}</Descriptions.Item>
                                <Descriptions.Item
                                    label="申请时间">{moment.unix(detail.created_at).format('YYYY-MM-DD HH:mm:ss')}</Descriptions.Item>
                                <Descriptions.Item label="订单编号">{detail.order_id}</Descriptions.Item>
                                <Descriptions.Item label="联系人">{detail.return_name}</Descriptions.Item>
                                <Descriptions.Item label="联系电话">{detail.return_phone}</Descriptions.Item>
                                <Descriptions.Item label="退款原因">{detail.reason_type}</Descriptions.Item>
                                <Descriptions.Item label="问题描述">{detail.description}</Descriptions.Item>
                                <Descriptions.Item label="凭证图片">
                                    {
                                        detail.proof_pics.map((res: string) => {
                                            return (
                                                <React.Fragment key={res}>
                                                    <Image
                                                        src={imgDomain + res}
                                                        style={{height: 64, width: 64, margin: 2}}/>
                                                </React.Fragment>
                                            );
                                        })
                                    }
                                </Descriptions.Item>
                            </Descriptions>
                        </div>
                    </div>
                    <Modal
                        title="拒绝理由"
                        visible={refuseVisible}
                        onCancel={() => {
                            setRefuseVisible(false);
                            setRefuseReason('');
                        }}
                        onOk={async () => {
                            if (detail.status === 3 && detail.return_type === 40) {
                                await refuseStep(2);
                            } else {
                                await refuseStep(1);
                            }
                        }}
                    >
                        <Input.TextArea value={refuseReason} onChange={e => {
                            setRefuseReason(e.target.value);
                        }} placeholder="请输入" style={{height: 200}}/>
                    </Modal>
                    <Modal
                        title="请选择回寄门店"
                        visible={sendBackVisible}
                        onCancel={() => {
                            setSendBackVisible(false);
                            setShopId(null);
                        }}
                        onOk={sendBack}
                    >
                        <Select style={{width: '100%'}} value={shopId} onSelect={(e: any) => {
                            setShopId(e);
                        }}>
                            {
                                shopList.map(res => {
                                    return (
                                        <Select.Option key={res.id}>{res.name}</Select.Option>
                                    );
                                })
                            }
                        </Select>
                    </Modal>
                </div>
            }
        </>
    );
};
export default RefundListDetail;
