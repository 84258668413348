import {useContext, useEffect, useState} from 'react';
import {PublicContext} from '../../../context';
import img_back from '../../../assets/images/dunfeng/dunfeng_login_back.png';
import contact_qr from '../../../assets/images/dunfeng/contact_qr.png';
import {Button, Card, Form, Image, Input, message, Popover, Space} from 'antd';
import {createHashHistory} from 'history';
import {LoginDunfeng} from '../../../service/home';

const DunfengLogin = () => {
    const {publicData, publicDispatch} = useContext(PublicContext);
    const {loggedIn} = publicData;
    const [loggingIn, setLoggingIn] = useState<boolean>(false);
    const content = (
        <Image src={contact_qr} width={130} preview={false}/>
    );

    useEffect(() => {
        publicDispatch({
            type: 'setLoggedIn',
            value: false
        });
        return () => {
            publicDispatch({
                type: 'setLoggedIn',
                value: true
            });
        };
    }, [loggedIn]);

    const loginConfirm = async (e: any) => {
        await LoginDunfeng(e.account, e.pwd);
        message.success('登陆成功');
        createHashHistory().replace('/home');
    };

    const goHome = () => {
        createHashHistory().replace('/home');
    };
    return (
        <div className="login_container" style={{backgroundImage: `url(${img_back})`}}>
            <Space direction="vertical" align="center" size="large">
                <h1 className="login_title_text">欢迎使用T.Shop投放系统</h1>
                <Form
                    name="basic"
                    wrapperCol={{span: 24}}
                    initialValues={{remember: true}}
                    onFinish={loginConfirm}
                    autoComplete="off"
                >
                    <Card style={{width: 460, padding: '24px 42px 0 42px', borderRadius: '10px'}}>
                        <h2 className="login_box_title">账号登录<a onClick={goHome}>返回首页</a></h2>
                        <Form.Item
                            name="account"
                            rules={[{required: true, message: '请输入邮箱账户'}]}
                        >
                            <Input style={{height: '50px', borderRadius: '10px', backgroundColor: '#F8F8F8'}}
                                   placeholder="请输入邮箱账户"/>
                        </Form.Item>
                        <Form.Item
                            name="pwd"
                            rules={[{required: true, message: '请输入密码'}]}
                        >
                            <Input.Password style={{height: '50px', borderRadius: '10px', backgroundColor: '#F8F8F8'}}
                                            placeholder="请输入密码"/>
                        </Form.Item>
                        <Form.Item>
                            <Button style={{
                                height: '50px',
                                borderRadius: '10px',
                                background: 'linear-gradient(to right,#6955F9 0%,#3B87F3 100%)'
                            }} loading={loggingIn} type="primary"
                                    htmlType="submit" block danger>登录</Button>
                        </Form.Item>
                        <p className="register_contact">注册账号请
                            <Popover placement="top" content={content}>
                                <a>联系客服</a>
                            </Popover></p>
                    </Card>
                </Form>
            </Space>
        </div>

    );
};
export default DunfengLogin;
