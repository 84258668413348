import {Radio, Table} from 'antd';
import {Link} from 'react-router-dom';
import '../hot_words/hot_words.less';
import {useEffect, useState} from 'react';
import {GetLeaderboards} from '../../../../service/home';

const Leaderboards = () => {
    const [radioValue, setRadioValue] = useState<number>(1);
    const [data, setData] = useState<Array<any>>([]);

    useEffect(() => {
        getLeaderboards().then();
    }, [radioValue]);

    const getLeaderboards = async () => {
        const res: any = await GetLeaderboards({
            page: 1,
            size: 20,
            week: radioValue == 1 ? 7 : 8
        });
        setData(res.array);
    };

    const columns = [
        {
            title: '排名',
            dataIndex: 'sort',
            key: 'sort',
            width: 100
        },
        {
            title: '商品名称',
            dataIndex: 'name',
            key: 'name',
            render: (text: string) => {
                return (
                    <div style={{color: '#1890FF'}}>{text}</div>
                );
            }
        },
        {
            title: '销量',
            dataIndex: 'sale',
            key: 'sale',
            width: 140,
            render:(text:number) => (
                `${text}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
            )
        },
        {
            title: '收益',
            dataIndex: 'income',
            key: 'income',
            width: 140,
            render:(text:number) => (
                `${text}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)
            )
        },
    ];
    return (
        <div className="hot_words_container" style={{height: 480}}>
            <div className="tab_line">
                <div className="title">
                    <span>排行榜</span>
                    <Link to={`/leaderboards_list?type=2&radio=${radioValue}`}>{'更多>'}</Link>
                </div>
                <Radio.Group
                    onChange={e => {
                        setRadioValue(e.target.value);
                    }}
                    value={radioValue}
                >
                    <Radio.Button value={1}>周</Radio.Button>
                    <Radio.Button value={2}>总</Radio.Button>
                </Radio.Group>
            </div>
            <div className="table_box">
                <Table dataSource={data} columns={columns} pagination={false} size={'small'} scroll={{y: 375}}/>
            </div>
        </div>
    );
};
export default Leaderboards;
