import {Button, Cascader, Col, Divider, Input, message, Modal, Row, Switch, Tabs, Upload} from 'antd';
import React, {useEffect, useReducer, useState} from 'react'
import BaseOption from './baseOption'
import PayOption from "./PayOption";
import {getSys,saveSetting} from '../../../service/setting/system.api'
import {getBase64,handlerSettingParams} from "../../../utils";
import './system.less'


export const SettingContext = React.createContext<any>(null);
const initSetting:ISettingData = {
    isPreview:false,
    previewImg:'',
    tabType:'1',
    name:'',
    logo:[],
    qrCode:[],
    wechatNumber:'',
    province:'',
    city:'',
    region:'',
    detailAdd:'',
    transPay:0,
    commentOpen:true,
    suggestion:'',
    alipaySwitch:false,
    alipayId:'',
    alipaySecret:'',
    alipayCallBack:'',
    alipayAppCer:[],
    alipayCer:[],
    alipayRootCer:[],
    alipayPrivateKey:[],
    wechatSwitch:false,
    wechatId:'',
    wechatSecret:'',
    wechatAppId:'',
    wechatAPPSecret:'',
    wechatShopNum:'',
    wechatCerNum:'',
    wechatAPIv3:'',
    wechatCallBack:'',
    wechatP12:[],
    wechatCerPem:[],
    wechatKeyPem:[],
}
const settingReducer = (state:ISettingData, action: IAction)=>{
    switch (action.type) {
        case 'reset':
            return Object.assign({},state,initSetting)
        case 'setTabType':
            return Object.assign({},state,{tabType:action.value})
        case 'setName':
            return Object.assign({},state,{name:action.value})
        case 'setLogo':
            return Object.assign({},state, {logo:action.value})
        case 'setQrCode':
            return Object.assign({},state,{qrCode:action.value})
        case 'setWechatNumber':
            return Object.assign({},state,{wechatNumber:action.value})
        case 'setProvince':
            return Object.assign({},state,{province:action.value})
        case 'setCity':
            return Object.assign({},state,{city:action.value})
        case 'setRegion':
            return Object.assign({},state,{region:action.value})
        case 'setDetailAdd':
            return Object.assign({},state,{detailAdd:action.value})
        case 'setTransPay':
            return Object.assign({},state,{transPay:action.value})
        case 'setCommentOpen':
            return Object.assign({},state,{commentOpen:action.value})
        case 'setSuggestion':
            return Object.assign({},state,{suggestion:action.value})
        case 'setAlipaySwitch':
            return Object.assign({},state,{alipaySwitch:action.value})
        case 'setAlipayId':
            return Object.assign({},state,{alipayId:action.value})
        case 'setAlipaySecret':
            return Object.assign({},state,{alipaySecret:action.value})
        case 'setAlipayCallBack':
            return Object.assign({},state,{alipayCallBack:action.value})
        case 'setAlipayAppCer':
            return Object.assign({},state,{alipayAppCer:action.value})
        case 'setAlipayCer':
            return Object.assign({},state,{alipayCer:action.value})
        case 'setAlipayRootCer':
            return Object.assign({},state,{alipayRootCer:action.value})
        case 'setAlipayPrivateKey':
            return Object.assign({},state,{alipayPrivateKey:action.value})
        case 'setIsPreview':
            return Object.assign({},state, {isPreview:action.value})
        case 'setPreviewImg':
            return Object.assign({},state, {previewImg:action.value})
        case 'setWechatSwitch':
            return Object.assign({},state,{wechatSwitch:action.value})
        case 'setWechatId':
            return Object.assign({},state,{wechatId:action.value})
        case 'setWechatSecret':
            return Object.assign({},state,{wechatSecret:action.value})
        case 'setWechatAppId':
            return Object.assign({},state,{wechatAppId:action.value})
        case 'setWechatAPPSecret':
            return Object.assign({},state,{wechatAPPSecret:action.value})
        case 'setWechatShopNum':
            return Object.assign({},state,{wechatShopNum:action.value})
        case 'setWechatCerNum':
            return Object.assign({},state,{wechatCerNum:action.value})
        case 'setWechatAPIv3':
            return Object.assign({},state,{wechatAPIv3:action.value})
        case 'setWechatCallBack':
            return Object.assign({},state,{wechatCallBack:action.value})
        case 'setWechatP12':
            return Object.assign({},state,{wechatP12:action.value})
        case 'setWechatCerPem':
            return Object.assign({},state,{wechatCerPem:action.value})
        case 'setWechatKeyPem':
            return Object.assign({},state,{wechatKeyPem:action.value})
        case 'setAll':{
            const {value} = action;
            let obj:any = {};
            value.forEach((item:any)=>{
                const{name,value} = item;
                switch (name){
                    case "system_name":
                        obj.name = value;
                        break;
                    case "system_logo":
                        obj.logo=value?[{
                                url: localStorage.getItem('divms_url') + value,
                                originalUrl: value
                            }]:[]
                        break;
                    case "system_qr":
                        obj.qrCode=value?[{
                            url: localStorage.getItem('divms_url') + value,
                            originalUrl: value
                        }]:[];
                        break;
                    case "system_wx":
                        obj.wechatNumber = value
                        break;
                    case "system_province":
                        obj.province = value;
                        break;
                    case "system_city":
                        obj.city=value;
                        break;
                    case "system_region":
                        obj.region = value;
                        break;
                    case "system_detail":
                        obj.detailAdd = value;
                        break;
                    case "system_fee":
                        obj.transPay=value;
                        break;
                    case "system_comment":
                        obj.commentOpen = value==="1";
                        break;
                    case "system_feedback":
                        obj.suggestion = value;
                        break;
                    case "system_pay_alipay_switch":
                        obj.alipaySwitch=(value==='1');
                        break;
                    case "system_pay_alipay_appid":
                        obj.alipayId =value;
                        break;
                    case "system_pay_alipay_secret":
                        obj.alipaySecret = value;
                        break;
                    case "system_pay_alipay_callback":
                        obj.alipayCallBack = value;
                        break;
                    case "system_pay_alipay_app_cer":
                        obj.alipayAppCer = value?[{
                            url: localStorage.getItem('divms_url') + value,
                            originalUrl: value
                        }]:[];
                        break;
                    case "system_pay_alipay_ali_cer":
                        obj.alipayCer = value?[{
                            url: localStorage.getItem('divms_url') + value,
                            originalUrl: value
                        }]:[];
                        break;
                    case "system_pay_alipay_root_cer":
                        obj.alipayRootCer = value?[{
                            url: localStorage.getItem('divms_url') + value,
                            originalUrl: value
                        }]:[];
                        break;
                    case "system_pay_alipay_key":
                        obj.alipayPrivateKey = value? [{
                            url: localStorage.getItem('divms_url') + value,
                            originalUrl: value
                        }]:[];
                        break;
                    case "system_pay_wx_switch":
                        obj.wechatSwitch = value ==='1';
                        break;
                    case "system_pay_wx_server_appid":
                        obj.wechatId =value;
                        break;
                    case "system_pay_wx_server_secret":
                        obj.wechatSecret =value;
                        break;
                    case "system_pay_wx_app_appid":
                        obj.wechatAppId =value;
                        break;
                    case "system_pay_wx_app_secret":
                        obj.wechatAPPSecret =value;
                        break;
                    case "system_pay_wx_shop":
                        obj.wechatShopNum = value;
                        break;
                    case "system_pay_wx_number":
                        obj.wechatCerNum = value;
                        break;
                    case "system_pay_wx_apiv3":
                        obj.wechatAPIv3 = value;
                        break;
                    case "system_pay_wx_callback":
                        obj.wechatCallBack = value;
                        break;
                    case "system_pay_wx_apiclient_cert_p12":
                        obj.wechatP12 = value?[{
                            url: localStorage.getItem('divms_url') + value,
                            originalUrl: value
                        }]:[];
                        break;
                    case "system_pay_wx_apiclient_cert_pem":
                        obj.wechatCerPem = value?[{
                            url: localStorage.getItem('divms_url') + value,
                            originalUrl: value
                        }]:[];
                        break;
                    case "system_pay_wx_apiclient_key_pem":
                        obj.wechatKeyPem =value?[{
                            url: localStorage.getItem('divms_url') + value,
                            originalUrl: value
                        }]:[];
                        break;
                    default:break;
                }
            })
            console.log(obj)
            return Object.assign({},state,obj)
        }
        default:
            return Object.create(state);
    }
}
const SystemSetting = () => {
    const [settingData,settingDispatch] = useReducer(settingReducer,initSetting)
    const {isPreview,previewImg,tabType} = settingData;
    useEffect(()=>{
        getSetting().then()
    },[])

    const handlePreview = async (file: any) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        settingDispatch({
            type:'setIsPreview',
            value:true
        });
        settingDispatch({
            type:'setPreviewImg',
            value:file.url || file.preview
        })
    };
    const getSetting = async ()=>{
        try{
            const {result}:any = await getSys({})
            console.log(result)
            settingDispatch({
                type:'setAll',
                value:result
            })
        }catch (e){
            console.log(e)
        }
    }
    const save = async ()=>{
        const params = handlerSettingParams(settingData)
        console.log(params)
        try{
            await saveSetting(params)
            message.success('保存成功')
        }catch (e){
            console.log(e)
            message.error('保存失败')
        }
    }
    return(
        <div className='add_body_wrap'>
            <Tabs defaultActiveKey='1' style={{padding: '0 40px', backgroundColor: '#FFFFFF', width: '100%'}}
                  onChange={(value)=> settingDispatch({type: 'setTabType', value})}>
                <Tabs.TabPane tab="基本设置" key="1"/>
                <Tabs.TabPane tab="支付设置" key="2"/>
            </Tabs>
            <div className='add_body'>
                <SettingContext.Provider value={{settingData, settingDispatch,handlePreview,save}}>
                    {
                        tabType==='1'&&
                        <BaseOption/>
                    }
                    {
                        tabType==='2'&&
                            <PayOption/>
                    }
                </SettingContext.Provider>
            </div>
            <Modal
                visible={isPreview}
                title={'查看Logo'}
                footer={null}
                onCancel={()=>settingDispatch({type:'setIsPreview',value:false})}
            >
                <img alt="example" style={{ width: '100%' }} src={previewImg} />
            </Modal>
        </div>
    )
}
export default SystemSetting
