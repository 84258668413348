import {Get, Post} from '../../utils/request';

export const GetRefundList = (req: IGetRefundList) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Post('/api/v1/order/return/list', req);
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const GetRefundDetail = (id: number) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Get('/api/v1/order/return/detail', {id});
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const RefuseRefund = (req: IRefuseRefund) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Post('/api/v1/order/return/refuse/back', req);
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};

export const AgreeRefund = (id: number) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Post('/api/v1/order/return/agree/money', {id});
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};
export const AgreeSendBack = (id: number, company_address_id: number) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res: any = await Post('/api/v1/order/return/agree/back', {id, company_address_id});
            resolve(res.result);
        } catch (e) {
            //reject(e);
        }
    });
};
