import './home.less';
import React, {useEffect, useReducer} from 'react';
import StatisticsCard from './components/statistics_card/statistics_card';
import icon_01 from '../../assets/images/icon_chart_01.png';
import icon_02 from '../../assets/images/icon_chart_02.png';
import icon_03 from '../../assets/images/icon_chart_03.png';
import QuantityStatistics from './components/quantity_statistics/quantity_statistics';
import HotWords from './components/hot_words/hot_words';
import Leaderboards from './components/leaderboards/leaderboards';
import ChannelRatio from './components/channel_ratio/channel_ratio';
import {GetSaleCount, GetSaleMoney, GetUserCount} from '../../service/home';
// import DunfengAccount from './components/dunfeng_account/dunfeng_account';
// import DunfengData from './components/dunfeng_data/dunfeng_data';

const homeDataInit = {
    allSalesCount: {
        all: '',
        average: '',
        yesterday: ''
    },
    allSales: {
        all: '',
        average: '',
        yesterday: ''
    },
    users: {
        all: '',
        yesterday: '',
        week: ''
    }
};

const homeReducer = (state: typeof homeDataInit, action: IAction) => {
    switch (action.type) {
        case 'setAllTopData':
            return Object.assign({}, state, {
                allSalesCount: action.value.allSalesCount,
                allSales: action.value.allSales,
                users: action.value.users,
            });
        default:
            return Object.assign({}, state);
    }
};

const Home = () => {
    const [homeData, homeDispatch] = useReducer(homeReducer, homeDataInit);
    const {allSalesCount, allSales, users} = homeData;
    useEffect(() => {
        setTopData().then();
    }, []);

    const setTopData = async () => {
        const saleCountData: any = await GetSaleCount();
        const saleMoneyData: any = await GetSaleMoney();
        const userCountData: any = await GetUserCount();
        homeDispatch({
            type: 'setAllTopData',
            value: {
                allSalesCount: {
                    all: saleCountData.saleNum,
                    average: saleCountData.avgSaleNum,
                    yesterday: saleCountData.yesterdaySaleNum
                },
                allSales: {
                    all: saleMoneyData.saleNum,
                    average: saleMoneyData.avgSaleNum,
                    yesterday: saleMoneyData.yesterdaySaleNum
                },
                users: {
                    all: userCountData.memberNum,
                    yesterday: userCountData.yesterdayMemberNum,
                    week: userCountData.weekdayMemberNum
                }
            }
        });
    };

    return (
        <div className="home_container">
            <div className="flex_center">
                <div className="flex_1">
                    <StatisticsCard
                        icon={icon_01}
                        name={'总销售量'}
                        mainStatistics={`${allSalesCount.all}`}
                        leftName={'日均销售量'}
                        leftStatistics={`${allSalesCount.average}`}
                        rightName={'昨日销售量'}
                        rightStatistics={`${allSalesCount.yesterday}`}
                    />
                </div>
                <div style={{width: '24px'}}/>
                <div className="flex_1">
                    <StatisticsCard
                        icon={icon_02}
                        name={'销售额'}
                        mainStatistics={`${allSales.all}`}
                        leftName={'日均销售额'}
                        leftStatistics={`${allSales.average}`}
                        rightName={'昨日销售额'}
                        rightStatistics={`${allSales.yesterday}`}
                    />
                </div>
                <div style={{width: '24px'}}/>
                <div className="flex_1">
                    <StatisticsCard
                        icon={icon_03}
                        name={'累计用户'}
                        mainStatistics={`${users.all}`}
                        leftName={'昨日新增'}
                        leftStatistics={`${users.yesterday}`}
                        rightName={'7日新增'}
                        rightStatistics={`${users.week}`}
                    />
                </div>
            </div>
            {/* <div className="flex_center" style={{marginTop: 24}}>
                <div style={{width:360, overflow: 'hidden'}}>
                    <DunfengAccount/>
                </div>
                <div style={{width: '24px'}}/>
                <div style={{flex: 1, overflow: 'hidden'}}>
                    <DunfengData/>
                </div>
            </div> */}
            <div className="flex_center" style={{marginTop: 24}}>
                <div style={{flex: 3, overflow: 'hidden'}}>
                    <QuantityStatistics/>
                </div>
                <div style={{width: '24px'}}/>
                <div style={{flex: 2, overflow: 'hidden'}}>
                    <HotWords/>
                </div>
            </div>
            <div className="flex_center" style={{marginTop: 24, marginBottom: 24}}>
                <div style={{flex: 2, overflow: 'hidden'}}>
                    <ChannelRatio/>
                </div>
                <div style={{width: '24px'}}/>
                <div style={{flex: 3, overflow: 'hidden'}}>
                    <Leaderboards/>
                </div>
            </div>
        </div>
    );
};
export default Home;
