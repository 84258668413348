import {Button, Divider, Form, Input, message, Modal, Table} from 'antd';
import {Link} from 'react-router-dom';
import React, {useEffect, useState} from 'react';
import CustomPagination from '../../../components/custom_pagination/custom_pagination';
import {IUpdateRole} from "../../../interface/setting.interface";
import {deleteRoleApi, getRoleListApi, updateRole} from "../../../service/setting/authority_group.api";
import {addKey} from "../../../utils";
import moment from "moment";

const {Search} = Input;
const PAGE_SIZE = 10;
let id = 0;
const AuthorityGroup = () => {
    const [form] = Form.useForm();
    const [infoVisible, setInfoVisible] = useState<boolean>(false);
    const [searchContent, setSearchContent] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = useState(0);
    const [tableData, setTableData] = useState<Array<any>>([]);
    const columns = [
        {
            title: '角色名称',
            dataIndex: 'name',
            key: 'name'
        },
        {
            title: '成员',
            dataIndex: 'role_count',
            key: 'role_count'
        },
        {
            title: '备注',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: '添加时间',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (text: number) => moment.unix(text).format('YYYY-MM-DD hh:mm:ss')
        },
        {
            title: '操作',
            render: (text: any, record: any) => (
                <>
                    <a onClick={() => {
                        id = record.id;
                        form.setFieldsValue({
                            'name': record.name,
                            'remark': record.description
                        })
                        setInfoVisible(true);
                    }}>编辑</a>
                    <Divider type="vertical"/>
                    <Link to={`/authority_assort/${record.id}`}>权限</Link>
                    <Divider type="vertical"/>
                    <a onClick={()=>{
                        Modal.confirm({
                            content:'是否确认删除，删除后将无法恢复',
                            onOk:()=>{
                                deleteRoleApi(record.id).then(res=>{
                                    message.success('删除成功')
                                    getRoleList()
                                })
                            }
                        })
                    }}>删除</a>
                </>
            )
        }
    ];

    useEffect(() => {
        getRoleList()
    }, [searchContent, currentPage])

    const onSearch = async (content: string) => {
        setSearchContent(content);
        setCurrentPage(1);
        //await getOrderList();
    };

    const getRoleList = () => {
        getRoleListApi({
            page: currentPage,
            size: PAGE_SIZE,
            kwd: searchContent
        }).then((res: any) => {
            setTableData(addKey(res.result.data, 'id'))
            setCount(res.result.count)
        })
    }

    const createRole = (req: IUpdateRole) => {
        updateRole(req).then(res => {
            message.success(id === 0 ? '新建成功' : '修改成功')
            setInfoVisible(false);
            form.resetFields(['name', 'remark']);
            getRoleList()
        }).catch(err => {
            message.error(String(err))
        })
    }

    return (
        <div className="content_container">
            <div className={'main_wrap'}>
                <div className={'main_container'}>
                    <div style={{display: 'flex', alignItems: 'center', margin: '16px 0'}}>
                        <div style={{marginRight: '20px', width: 550}}>
                            <Search
                                placeholder="输入角色名称进行搜索"
                                onSearch={onSearch}
                                enterButton="搜索"
                                allowClear={true}
                            />
                        </div>
                        <div className={'flex_1'}>
                            <Button
                                type={'primary'}
                                style={{float: 'right'}}
                                onClick={() => {
                                    id = 0
                                    form.resetFields(['name', 'remark']);
                                    setInfoVisible(true);
                                }}
                            >添加角色</Button>
                        </div>
                    </div>
                    <div className={'table_wrap'}>
                        <Table dataSource={tableData} columns={columns} pagination={false} scroll={{y: 640, x: 1000}}/>
                    </div>
                </div>
                <CustomPagination
                    currentPage={currentPage}
                    total={count}
                    onChange={(page: number) => setCurrentPage(page)}
                    pageSize={PAGE_SIZE}
                />
            </div>
            <Modal
                title="角色信息"
                visible={infoVisible}
                onOk={() => {
                    form.submit();
                }}
                onCancel={() => {
                    setInfoVisible(false);
                    form.resetFields(['name', 'remark']);
                }}
            >
                <Form
                    form={form}
                    labelCol={{span: 6}}
                    wrapperCol={{span: 18}}
                    onFinish={e => {
                        createRole({
                            id: id,
                            name: e.name,
                            description: e.remark
                        })
                        form.resetFields(['name', 'remark']);
                    }}
                >
                    <Form.Item label="角色名称" name="name" rules={[{required: true, message: '请输入角色名称!'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="备注" name="remark">
                        <Input.TextArea/>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};
export default AuthorityGroup;
