import Breadcrumb from '../breadcrumb/breadcrumb';
import './header.css';
import {Avatar, Button, Popover, Space} from 'antd';
import {UserOutlined} from '@ant-design/icons';
import {PublicContext} from '../../context';
import {useContext} from 'react';
import {createHashHistory} from 'history';

const Header = () => {
    const {publicDispatch} = useContext(PublicContext);
    const content = (
        <Button onClick={() => {
            localStorage.removeItem('divms_login_token');
            publicDispatch({
                type: 'setImgDomain',
                value: ''
            });
            publicDispatch({
                type: 'setLoggedIn',
                value: false
            });
            createHashHistory().replace('/login');
        }}>退出登录</Button>
    );

    return (
        <div className="header_container">
            <Breadcrumb/>
            <div className="flex_1"/>
            <Space>
                <Popover placement="bottom" content={content}>
                    <Avatar style={{cursor: 'pointer'}} icon={<UserOutlined/>}/>
                </Popover>
                <div>管理员</div>
            </Space>
        </div>
    );
};
export default Header;
