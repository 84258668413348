import {Get, Post} from '../../utils/request';
/**
 *
 * 订单列表
 * @param req
 */
export const getList = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/order/query', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * 订单详情
 * @param req
 */
export const orderDetail = (req:{})=>{
    return new Promise(async (resolve, reject) => {
        try {
            const res = Get('/api/v1/order/info', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
}
/**
 * 发货
 */
export const sendShip = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/order/ship', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * 备注
 */
export const remark = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/order/note', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};
/**
 * 修改发货地址
 */
export const changeAddress = (req:{}) => {
    return new Promise(async (resolve, reject) => {
        try {
            const res = Post('/api/v1/order/address/update', req);
            resolve(res);
        } catch (e) {
            reject(e);
        }
    });
};

