import './channel_ratio.less';
import {Divider, Radio} from 'antd';
import {useEffect, useState} from 'react';
import {Pie, measureTextWidth} from '@ant-design/plots';
import {GetChannelRatio} from '../../../../service/home';

let radioMap = new Map();
radioMap.set(1, '访问量');
radioMap.set(2, '评论');
radioMap.set(3, '收藏');
const ChannelRatio = () => {
    const [radioValue, setRadioValue] = useState<number>(1);
    const [data, setData] = useState<Array<any>>([]);
    useEffect(() => {
        getChannelRatio().then();
    }, [radioValue]);

    const getChannelRatio = async () => {
        const res: any = await GetChannelRatio();
        switch (radioValue) {
            case 1:
                setData([
                    {
                        type: 'APP',
                        value: res.uv.app,
                        percent: res.uv.appUvPercent
                    },
                    {
                        type: '小程序',
                        value: res.uv.mini,
                        percent: res.uv.miniUvPercent,
                    }
                ]);
                break;
            case 2:
                setData([
                    {
                        type: 'APP',
                        value: res.comment.app,
                        percent: res.comment.appUvPercent
                    },
                    {
                        type: '小程序',
                        value: res.comment.mini,
                        percent: res.comment.miniUvPercent,
                    }
                ]);
                break;
            case 3:
                setData([
                    {
                        type: 'APP',
                        value: res.collect.app,
                        percent: res.collect.appUvPercent
                    },
                    {
                        type: '小程序',
                        value: res.collect.mini,
                        percent: res.collect.miniUvPercent,
                    }
                ]);
                break;
            default:
        }
    };

    function renderStatistic(containerWidth: number, text: string, style: { fontSize: number; }) {
        const {width: textWidth, height: textHeight} = measureTextWidth(text, style);
        const R = containerWidth / 2; // r^2 = (w / 2)^2 + (h - offsetY)^2

        let scale = 1;

        if (containerWidth < textWidth) {
            scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }

        const textStyleStr = `width:${containerWidth}px;`;
        return `<div style="${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    }

    const config: any = {
        appendPadding: 10,
        data,
        angleField: 'value',
        colorField: 'type',
        color: ['#36CBCB', '#FBD437'],
        radius: 1,
        innerRadius: 0.64,
        label: {
            type: 'inner',
            offset: '-50%',
            style: {
                textAlign: 'center',
            },
            autoRotate: false,
            content: '',
        },
        legend: false,
        statistic: {
            title: {
                offsetY: -4,
                style: {
                    fontSize: '14px',
                    color: 'rgba(0, 0, 0, 0.45)'
                },
                customHtml: (container: { getBoundingClientRect: () => { width: any; height: any; }; }, view: any, datum: { type: any; }) => {
                    const {width, height} = container.getBoundingClientRect();
                    const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
                    const text = datum ? datum.type : radioMap.get(radioValue);
                    return renderStatistic(d, text, {
                        fontSize: 28,
                    });
                },
            },
            content: {
                offsetY: 4,
                style: {
                    fontSize: '26px',
                    color: 'rgba(0, 0, 0, 0.85)'
                },
                customHtml: (container: { getBoundingClientRect: () => { width: any; }; }, view: any, datum: { value: any; }, data: any[]) => {
                    const {width} = container.getBoundingClientRect();
                    const text = datum ? `${datum.value}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`) : `${data.reduce((r, d) => r + d.value, 0)}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`);
                    return renderStatistic(width, text, {
                        fontSize: 32,
                    });
                },
            },
        },
        // 添加 中心统计文本 交互
        interactions: [
            {
                type: 'element-selected',
            },
            {
                type: 'element-active',
            },
            {
                type: 'pie-statistic-active',
            },
        ],
    };
    return (
        <div className="channel_ratio_container">
            <div className="tab_line">
                <div className="title">
                    <span>渠道占比</span>
                </div>
                <Radio.Group
                    onChange={e => {
                        setData([])
                        setRadioValue(e.target.value);
                    }}
                    value={radioValue}
                >
                    <Radio.Button value={1}>访问量</Radio.Button>
                    <Radio.Button value={2}>评论</Radio.Button>
                    <Radio.Button value={3}>收藏</Radio.Button>
                </Radio.Group>
            </div>
            <div className="chart_box">
                <div className="chart">
                    <Pie {...config} />
                </div>
                <div className="legend">
                    {
                        data.map(res => {
                            return (
                                <div className="legend_line" key={res.type}>
                                    <div style={{backgroundColor: res.type == 'APP' ? '#36CBCB' : '#FBD437'}}/>
                                    <span>{res.type}</span>
                                    <Divider type="vertical"/>
                                    <span>{res.percent}</span>
                                    <Divider type="vertical"/>
                                    <span>{`${res.value}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`)}</span>
                                </div>
                            );
                        })
                    }
                </div>
            </div>
        </div>
    );
};
export default ChannelRatio;
