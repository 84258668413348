import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from 'react'
import {Col, Form, Row, Select} from "antd";
import Regions from 'chinese_regions';

const {Option} = Select
const {province} = Regions;
const provinces = province;

function useDidUpdateEffect(fn:any, inputs:any) {
    const didMountRef = useRef(false);
    useEffect(() => {
        if (didMountRef.current) fn();
        else didMountRef.current = true;
    }, inputs);
}
const RegionSelect = React.memo((props:ISelectProps)=>{
    const {province,city,county,provinceChange,cityChange,countyChange,selectWidth} = props
    const [cities,setCities] = useState<Array<any>>();
    const [counties,setCounties] = useState<Array<any>>();

    useEffect(()=>{
        if(!province) return
        const {city} = Regions;
        const cities = city.filter(city => city.p_name === province);
        setCities(cities);
        return function (){
            setCities([])
            setCounties([])
        }
    },[province])
    useEffect(()=>{
        if(!city) return;
        const {county} = Regions;
        const counties = county.filter(county => county.c_name === city);
        setCounties(counties);
    },[city])
    return (
        <Row gutter={16}>
            <Col span={8}>
                <Select
                    style={selectWidth?{width:selectWidth}:{}}
                    value={province}
                    onChange={(province: string) => {
                        provinceChange(province)
                        const {city,county} = Regions;
                        const cities = city.filter(city => city.p_name === province);
                        if(!cities || cities.length === 0) return;
                        setCities(cities);
                        cityChange(cities[0].name)
                        const counties = county.filter(county=>county.c_name === cities[0].name)
                        if(!counties || counties.length === 0) return;
                        setCounties(counties)
                        countyChange(counties[0].name)
                    }}
                >
                    {
                        provinces && provinces.map(item =>
                            <Option value={item.name}>{item.name}</Option>
                        )
                    }
                </Select>
            </Col>
            <Col span={8}>
                <Select
                    style={selectWidth?{width:selectWidth}:{}}
                    value={city}
                    onChange={(city: string) => {
                        cityChange(city)
                        const {county} = Regions;
                        const counties = county.filter(county => county.c_name === city);
                        setCounties(counties);
                        if(counties && counties.length!==0) countyChange(counties[0].name)
                    }}
                >
                    {
                        cities && cities.map(item =>
                            <Option value={item.name}>{item.name}</Option>
                        )
                    }
                </Select>
            </Col>
            <Col span={8}>
                <Select
                    style={selectWidth?{width:selectWidth}:{}}
                    value={county}
                    onChange={(county: string) => countyChange(county)}
                >
                    {
                        counties && counties.map(item =>
                            <Option value={item.name}>{item.name}</Option>
                        )
                    }
                </Select>
            </Col>
        </Row>
    )
})
export default RegionSelect;
