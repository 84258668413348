/**
 * 获取图片流方法
 * @param file(上传的文件)
 */
import _ from "lodash";

export const getBase64 = function getBase64(file: File) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
};

export const searchObj = (search: string) => {
    return JSON.parse('{"'.concat(decodeURIComponent(search.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"'), '"}'));
};
/**
 * 判断优惠券的使用情况
 */
export const getCouponState = (start: Date, end: Date) => {
    const curDate = new Date();
    const startTime = new Date(start);
    const endTime = new Date(end);
    if (curDate >= startTime && curDate <= endTime) return '使用中';
    if (curDate < startTime) return '未开始';
    if (curDate > endTime) return '已结束';

};

const reg1 = /^[0-9]*[0-9][0-9]*$/;　　//正整数+0
const reg2 = /^\d+(\.([1-9]|\d[1-9]))?$/;　　//小数点2位

/**
 * 判断是否为非负整数
 * @param num
 * @constructor
 */
export const IsPositiveInteger = (num: any) => {
    return reg1.test(num);
};

/**
 * 判断是否为正2位小数
 * @param num
 * @constructor
 */
export const IsDecimal = (num: any) => {
    return reg2.test(num) && num > 0;
};

/**
 * 处理更新系统设置请求api的参数
 * @param settingData
 */
export const handlerSettingParams: any = (settingData: ISettingData) => {
    const {
        name,
        logo,
        qrCode,
        wechatNumber,
        province,
        city,
        region,
        detailAdd,
        transPay,
        commentOpen,
        suggestion,
        alipaySwitch,
        alipayId,
        alipaySecret,
        alipayCallBack,
        alipayAppCer,
        alipayCer,
        alipayRootCer,
        alipayPrivateKey,
        wechatSwitch,
        wechatId,
        wechatSecret,
        wechatAppId,
        wechatAPPSecret,
        wechatShopNum,
        wechatCerNum,
        wechatAPIv3,
        wechatCallBack,
        wechatP12,
        wechatCerPem,
        wechatKeyPem,
    } = settingData;
    // const arr = suggestion.split(/\n/);
    // let input_list = ""
    // arr.forEach((item:any)=>{
    //     input_list+=item + ","
    // })
    const data = [
        {name: 'system_name', value: name},
        {name: 'system_logo', value: logo.length === 0 ? '' : (logo[0].originalUrl || logo[0].response.result.key)},
        {name: 'system_qr', value: qrCode.length === 0 ? '' : qrCode[0].originalUrl || qrCode[0].response.result.key},
        {name: 'system_wx', value: wechatNumber},
        {name: 'system_province', value: province},
        {name: 'system_city', value: city},
        {name: 'system_region', value: region},
        {name: 'system_detail', value: detailAdd},
        {name: 'system_fee', value: transPay},
        {name: 'system_comment', value: commentOpen ? '1' : '2'},
        {name: 'system_feedback', value: suggestion},
        {name: 'system_pay_alipay_switch', value: alipaySwitch ? '1' : '2'},
        {name: 'system_pay_alipay_appid', value: alipayId},
        {name: 'system_pay_alipay_secret', value: alipaySecret},
        {name: 'system_pay_alipay_callback', value: alipayCallBack},
        {
            name: 'system_pay_alipay_app_cer',
            value: alipayAppCer.length === 0 ? '' : alipayAppCer[0].originalUrl || alipayAppCer[0].response.result.key
        },
        {
            name: 'system_pay_alipay_ali_cer',
            value: alipayCer.length === 0 ? '' : alipayCer[0].originalUrl || alipayCer[0].response.result.key
        },
        {
            name: 'system_pay_alipay_root_cer',
            value: alipayRootCer.length === 0 ? '' : alipayRootCer[0].originalUrl || alipayRootCer[0].response.result.key
        },
        {
            name: 'system_pay_alipay_key',
            value: alipayPrivateKey.length === 0 ? '' : alipayPrivateKey[0].originalUrl || alipayPrivateKey[0].response.result.key
        },
        {name: 'system_pay_wx_switch', value: wechatSwitch ? '1' : '2'},
        {name: 'system_pay_wx_server_appid', value: wechatId},
        {name: 'system_pay_wx_server_secret', value: wechatSecret},
        {name: 'system_pay_wx_app_appid', value: wechatAppId},
        {name: 'system_pay_wx_app_secret', value: wechatAPPSecret},
        {name: 'system_pay_wx_shop', value: wechatShopNum},
        {name: 'system_pay_wx_number', value: wechatCerNum},
        {name: 'system_pay_wx_apiv3', value: wechatAPIv3},
        {name: 'system_pay_wx_callback', value: wechatCallBack},
        {
            name: 'system_pay_wx_apiclient_cert_p12',
            value: wechatP12.length === 0 ? '' : wechatP12[0].originalUrl || wechatP12[0].response.result.key
        },
        {
            name: 'system_pay_wx_apiclient_cert_pem',
            value: wechatCerPem.length === 0 ? '' : wechatCerPem[0].originalUrl || wechatCerPem[0].response.result.key
        },
        {
            name: 'system_pay_wx_apiclient_key_pem',
            value: wechatKeyPem.length === 0 ? '' : wechatKeyPem[0].originalUrl || wechatKeyPem[0].response.result.key
        },
    ]
    return data;
}

/**
 * 不包含key的数组追加key
 * @param array
 * @param keyName
 */
export const addKey = (array: Array<any>, keyName: string) => {
    let arr: Array<any> = []
    array.forEach((res: any) => {
        arr.push(_.assign({key: res[keyName]}, res))
    })
    return arr
}
/**
 * 追加自定义键值
 * @param array
 * @param customName
 * @param keyName
 */
export const addCustomKey = (array: Array<any>, customName: string, keyName: string) => {
    let arr: Array<any> = []
    array.forEach((res: any) => {
        arr.push(_.assign({[customName]: res[keyName]}, res))
    })
    return arr
}

/**
 * 格式化价格
 * @param price
 */
export const formatPrice = (price: number) => {
    return _.floor(Number(price) / 100, 2);
};
  