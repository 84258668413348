import {Col, Row, Select, Input, Table, Button, message} from 'antd';
import React, {useEffect, useState} from 'react';
import moment from 'moment';
import showDeleteConfirm from '../../../components/confirm_delete/confirm_delete';
import CustomPagination from '../../../components/custom_pagination/custom_pagination';
import {getList, logoutUser} from '../../../service/member/list.api';

const {Option} = Select;
const {Search} = Input;
const PAGE_SIZE = 20;
const MemberList = () => {
    const [selectState, setSelectState] = useState(0);
    const [tableData, setTableData] = useState<Array<any>>();
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [searchContent, setSearchContent] = useState<string>();
    const columns:any = [
        {
            title: 'UID',
            dataIndex: 'id',
            key: 'id',
            width: 80,
            fixed: 'left'
        },
        {
            title: '用户昵称',
            dataIndex: 'nickname',
            key: 'id'
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'id',
            render: (text: number) => text === 0 ? '全部状态' : text === 1 ? '正常状态' : '已注销'
        },
        {
            title: '手机号',
            dataIndex: 'phone',
            key: 'id'
        },
        {
            title: '注册时间',
            dataIndex: 'created_at',
            key: 'id',
            render: (text: number) => moment.unix(text).format('YYYY-MM-DD')
        },
        {
            title: '操作',
            width: 120,
            fixed: 'right',
            render: (text: any, record: any) => (
                <a onClick={() => showDeleteConfirm(record, '是否确认注销，注销后将无法恢复', logout)}>注销</a>
            )
        },
    ];
    useEffect(() => {
        getMemberList().then();
    }, [page]);
    useEffect(() => {
        setPage(1);
        getMemberList().then();
    }, [searchContent]);
    const getMemberList = async () => {
        try {
            const {result: {count, data}}: any = await getList({
                page,
                size: PAGE_SIZE,
                status: selectState,
                kwd: searchContent
            });
            setTableData(data);
            setCount(count);
        } catch (e) {
            console.log(e);
        }

    };
    const onSearch = async (content: string) => {
        setSearchContent(content);
        await getMemberList();
    };
    const logout = async (record: any) => {
        const {id} = record;
        if (!id) return;
        try {
            await logoutUser({id});
            message.success('注销成功');
            await getMemberList();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div className="content_container">
            <div className={'main_wrap'}>
                <div className={'main_container'}>
                    <div style={{display: 'flex', alignItems: 'center', margin: '16px 0'}}>

                        <Select value={selectState} onChange={(value) => setSelectState(value)}
                                style={{width: '200px'}}>
                            <Option value={0}>全部状态</Option>
                            <Option value={1}>正常状态</Option>
                            <Option value={2}>已注销</Option>
                        </Select>
                        <div style={{width: '500px', marginLeft: '20px'}}>
                            <Search
                                placeholder="输入UID、用户昵称进行搜索"
                                onSearch={onSearch}
                                enterButton="搜索"
                                allowClear
                            />
                        </div>
                    </div>
                    <Table
                        dataSource={tableData}
                        columns={columns}
                        pagination={false}
                        scroll={{y: 680, x: 1000}}
                    />
                </div>
                <CustomPagination
                    currentPage={page}
                    total={count}
                    onChange={(page: number) => setPage(page)}
                    pageSize={PAGE_SIZE}
                />
            </div>
        </div>
    );
};
export default MemberList;
