import {API_URL} from '../../utils/request';
import BraftEditor, {EditorState} from 'braft-editor';
import 'braft-editor/dist/index.css';
import {ContentUtils} from 'braft-utils';
import {Button, Upload} from 'antd';
import React from 'react';

const controls: Array<any> = ['undo', 'redo', 'font-size', 'text-align', 'bold', 'italic', 'underline', 'text-color', 'separator'];

const RichTextEditor = (props: { value: EditorState, onchange: Function, contentStyle?: any }) => {
    const {onchange, value, contentStyle} = props;
    const uploadHandler = (param: any) => {
        if (!param.file.response) {
            return false;
        }
        onchange(ContentUtils.insertMedias(value, [{
            type: 'IMAGE',
            width: '100%',
            url: localStorage.getItem('divms_url') + param.file.response.result.key
        }]));
    };

    const extendControls: Array<any> = [
        {
            key: 'antd-uploader',
            type: 'component',
            component: (
                <Upload
                    name="picture"
                    accept="image/*"
                    action={`${API_URL}/api/v1/file/upload`}
                    showUploadList={false}
                    onChange={uploadHandler}
                >
                    <Button style={{height: '30px', margin: '8px 0', padding: '0 8px'}}>插入图片</Button>
                </Upload>
            )
        }
    ];
    return (
            <BraftEditor
                language={'zh'}
                contentStyle={contentStyle ? contentStyle : {height: 400, width: 740, border: '1px solid #e9e9e9'}}
                value={value}
                controls={controls}
                onChange={(e: EditorState) => {
                    onchange(e);
                }}
                imageControls={[
                    'align-left', // 设置图片居左
                    'align-center', // 设置图片居中
                    'align-right', // 设置图片居右
                    'size', // 设置图片尺寸
                    'remove' // 删除图片
                ]}
                extendControls={extendControls}
            />
    );
};
export default RichTextEditor;
